import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const ShopifyWelcome = () => {
  return (
    <>
      <h1>Manifold Token Gate</h1>
      <Stack spacing={2}>
        <Card>
          <CardActionArea href="https://www.youtube.com/watch?v=wGUmSbfWNBk" target="_blank">
            <CardMedia
              component="img"
              image="https://res.cloudinary.com/manifoldxyz/image/upload/v1644366937/Manifold_Tuts_Video_Header_Experience_zymetb.jpg"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                The Manifold Token Gate x Shopify User Experience
              </Typography>
              <Typography variant="body2" color="text.secondary">
                See how a customer will experience your Token Gated storefront
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea href="https://www.youtube.com/watch?v=8EELMWKM7WI" target="_blank">
            <CardMedia
              component="img"
              image="https://res.cloudinary.com/manifoldxyz/image/upload/v1644366937/Manifold_Tuts_Video_Header_lraodi.jpg"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Getting started
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Follow this tutorial to set up a Token Gated Campaign
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Stack>
      <br />
      <hr />
      Visit the{' '}
      <a href="https://developer.manifoldxyz.dev" target="_blank" rel="noreferrer">
        Developer Portal
      </a>{' '}
      for more configuration options and to set up your website with an account connection button.
    </>
  );
};
