import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useState } from 'react';

interface NewAppProps {
  cognitoSession: CognitoUserSession | null;
}

export const NewApp = ({ cognitoSession }: NewAppProps): JSX.Element => {
  const newAppBackend = 'https://kygy4d4nc3.execute-api.us-east-1.amazonaws.com/prod/app/new';
  const [invalidURI, setInvalidURI] = useState(false);
  const [grantTypeSelector, setGrantTypeSelector] = useState('signature');
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [appName, setAppName] = useState('');
  const [description, setDescription] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const [grantType, setGrantType] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const onGrantTypeChange = (e: any) => {
    const { value } = e.target;
    setGrantTypeSelector(value);
  };

  const handleInputValue = (e: any) => {
    const { value } = e.target;

    const valid = value.startsWith('https://') || value.startsWith('http://');
    setInvalidURI(!valid);
  };

  const newAppSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const appName = data.get('appName')!.toString();
    const appDescription = data.get('appDescription')!.toString();
    const redirectUri = data.get('redirectUri')!.toString();
    // const tokenByContractScope = data.get('tokenByContractScope') ?  true : false
    // const tokensOfOwnerScope = data.get('tokensOfOwnerScope') ?  true : false
    const grantType = data.get('grantTypeSelect')!.toString();

    // let scopes = []
    // if (tokenByContractScope) {
    //   scopes.push('tokens_of_contract')
    // }

    // if (tokensOfOwnerScope) {
    //   scopes.push('tokens_of_owner')
    // }

    const resp = await fetch(newAppBackend, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${cognitoSession?.getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        appName,
        description: appDescription,
        redirectUri,
        grantType,
        // scopes: scopes
      }),
    });

    const respJson = await resp.json();

    setLoading(false);
    setDone(true);
    setAppName(appName);
    setDescription(appDescription);
    setRedirectUri(redirectUri);
    setGrantType(grantType);
    setClientSecret(respJson.clientSecret);
    setClientId(respJson.clientId);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
      }}
      noValidate
      autoComplete="off"
      onSubmit={newAppSubmit}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{
          marginBottom: 3,
        }}
      >
        Create New App
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {!done && (
            <TextField required id="appName" name="appName" label="App Name" placeholder="My App" />
          )}
          {done && (
            <Typography
              component="h1"
              variant="h5"
              sx={{
                marginBottom: 3,
              }}
            >
              App Name: {appName}
            </Typography>
          )}
          {!done && (
            <TextField
              required
              id="appDescription"
              name="appDescription"
              label="Description"
              placeholder="Description"
              multiline
            />
          )}
          {done && (
            <Typography
              component="h1"
              variant="h5"
              sx={{
                marginBottom: 3,
              }}
            >
              Description: {description}
            </Typography>
          )}
          {!done && (
            <TextField
              required
              id="redirectUri"
              name="redirectUri"
              label="Redirect URI"
              placeholder="https://yourapp.com"
              error={invalidURI}
              onBlur={handleInputValue}
              onChange={handleInputValue}
              helperText="Must start with https:// or http://"
            />
          )}
          {done && (
            <Typography
              component="h1"
              variant="h5"
              sx={{
                marginBottom: 3,
              }}
            >
              Redirect URI: {redirectUri}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {/* <Typography component="h6" variant="h6">
              Access Scopes
          </Typography>
          <FormControlLabel
            control={<Checkbox value="tokenByContractScope" color="primary" />}
            label="Tokens by Contract"
            id="tokenByContractScope"
            name="tokenByContractScope"
          />
          <FormControlLabel
            control={<Checkbox value="tokensOfOwnerScope" color="primary" />}
            label="Tokens of Owner"
            id="tokensOfOwnerScope"
            name="tokensOfOwnerScope"
          /> */}
          {!done && (
            <>
              <Typography component="h6" variant="h6">
                Grant Type
              </Typography>
              <FormControl
                fullWidth
                sx={{
                  marginTop: 3,
                }}
              >
                <InputLabel id="grant-type-select-label">Grant Type</InputLabel>
                <Select
                  labelId="grant-type-select-label"
                  id="grant-type-select"
                  value={grantTypeSelector}
                  name="grantTypeSelect"
                  label="Grant Type"
                  onChange={onGrantTypeChange}
                >
                  <MenuItem value={'token'}>Token Grant</MenuItem>
                  <MenuItem value={'code'}>Authorization Code Grant</MenuItem>
                  <MenuItem value={'signature'}>Signature Grant</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          {done && (
            <Typography component="h6" variant="h6">
              Grant Type: {grantType}
            </Typography>
          )}
          {(done && grantType === 'code') ||
            (grantType === 'signature' && (
              <Typography component="h6" variant="h6">
                Client Secret: {clientSecret} (only shown once, please write it down.)
              </Typography>
            ))}
          {done && (
            <Typography component="h6" variant="h6">
              Client ID: {clientId}
            </Typography>
          )}
        </Grid>
      </Grid>
      {loading && !done && <CircularProgress />}
      {!loading && !done && (
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Create
        </Button>
      )}
      {!loading && done && (
        <Typography
          component="h1"
          variant="h5"
          sx={{
            marginBottom: 3,
          }}
        >
          Created!
        </Typography>
      )}
    </Box>
  );
};
