import { Icon, IconProps, SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import { ReactComponent as ManifoldBlack } from '../../assets/manifold-logograph-black-padding.svg';
import { ReactComponent as ManifoldWhite } from '../../assets/manifold-logograph-white-padding.svg';

export const ManifoldLightIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 150 150">
    <ManifoldBlack />
  </SvgIcon>
);

export const ManifoldDarkIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 150 150">
    <ManifoldWhite />
  </SvgIcon>
);
