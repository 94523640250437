import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { CSSObject, styled, SvgIcon, Theme } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { SetStateAction, useCallback } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { useCognito } from '../../hooks/useCognito';
import { DrawerHeader } from './DrawerHeader';
import { ManifoldLightIcon } from './ManifoldIcon';

export const NAVIGATION_DRAWER_WIDTH = 240;

// list item

interface NavigationListItemProps {
  Icon: typeof SvgIcon;
  key?: string;
  navigationPath: string;
  navigationOptions?: NavigateOptions;
  text: string;
}

const NavigationListItem = (
  props: NavigationListItemProps & { open: boolean; selected: boolean }
): JSX.Element => {
  const navigate = useNavigate();
  const { Icon, navigationPath, navigationOptions, open, text, selected } = props;
  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
        onClick={() => {
          navigate(navigationPath, navigationOptions);
        }}
        selected={selected}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

const NAVIGATION_LIST_ITEMS: NavigationListItemProps[] = [
  // NOTE: dashboard -> for now -- navigate to apps
  { Icon: HomeOutlinedIcon, navigationPath: '/apps', text: 'Dashboard' },
  {
    Icon: SettingsOutlinedIcon,
    key: 'settings',
    navigationPath: '/settings',
    text: 'Account Settings',
  },
  {
    Icon: PhotoCameraOutlinedIcon,
    key: 'snapshot',
    navigationPath: '/snapshot',
    text: 'Snapshot Tools',
  },
  { Icon: DescriptionOutlinedIcon, navigationPath: '/campaigns', text: 'Campaigns' },
];

// navigation drawer styling + component
/**
 * Returns the closed drawer width according to the theme.
 * @param theme Theme object
 * @param compliment whether we should be calculating the inverse (100 % - width) or not.
 * @returns CSS object that can be added to mixins/styles
 */
export const leaveClosedDrawerWidthMixin = (theme: Theme, compliment = false): CSSObject => ({
  width:
    `calc(` +
    (compliment ? '(100% - ' : '') +
    `${theme.spacing(7)} + 1px)` +
    (compliment ? ')' : ''),
  [theme.breakpoints.up('sm')]: {
    width:
      `calc(` +
      (compliment ? '(100% - ' : '') +
      `${theme.spacing(8)} + 1px)` +
      (compliment ? ')' : ''),
  },
});

const openedMixin = (theme: Theme): CSSObject => ({
  width: NAVIGATION_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  ...leaveClosedDrawerWidthMixin(theme),
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: NAVIGATION_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

interface NavigationDrawerProps extends MuiDrawerProps {
  open: boolean;
  setOpen: (value: SetStateAction<boolean>) => void;
}

export const NavigationDrawer = ({ open, setOpen }: NavigationDrawerProps): JSX.Element => {
  const { cognitoSession } = useCognito();
  const location = useLocation();

  const toggleDrawerOpen = useCallback(() => {
    setOpen((currentOpen) => !currentOpen);
  }, [setOpen]);

  return (
    <Drawer variant="permanent" anchor="left" open={open}>
      <DrawerHeader>
        <IconButton
          onClick={toggleDrawerOpen}
          sx={{
            minWidth: 0,
            mr: open ? 2 : 'auto',
            justifyContent: 'center',
            // NOTE: the margins/paddings are set like this to achieve the correct hover state
            ml: 1.5,
            pl: 1,
          }}
        >
          <ManifoldLightIcon />
        </IconButton>
        <Typography noWrap component="div" sx={{ opacity: open ? 1 : 0, pr: open ? 2.5 : 0 }}>
          Manifold Dev Portal
        </Typography>
      </DrawerHeader>
      <Divider />
      <List>
        {cognitoSession ? (
          NAVIGATION_LIST_ITEMS.map((listItemProps) => (
            <NavigationListItem
              key={listItemProps.key || listItemProps.text.toLowerCase()}
              {...listItemProps}
              open={open}
              selected={location.pathname === listItemProps.navigationPath}
            />
          ))
        ) : (
          <NavigationListItem
            Icon={LockOutlinedIcon}
            navigationPath="/login"
            text="Log In"
            open={open}
            selected={location.pathname === '/login'}
          />
        )}
      </List>
    </Drawer>
  );
};
