import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AttributeMultiSelectDropdown = ({
  nftAttributes,
  selectedAttributeMulti,
  setSelectedAttributeMulti,
}: any) => {
  const handleChange = (event: SelectChangeEvent<typeof selectedAttributeMulti>) => {
    // console.log('ms handlechange() event: ', event)
    // console.log('personName state before: ', personName)
    // console.log('selectedAttributeMutli state before: ', selectedAttributeMulti)

    const {
      target: { value },
    } = event;
    // console.log('value: ', value)
    setSelectedAttributeMulti(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="multi-select-attributes-label">Attributes</InputLabel>
        <Select
          labelId="multi-select-attributes-label"
          id="multi-select-attributes"
          multiple
          value={
            Array.isArray(selectedAttributeMulti) && selectedAttributeMulti.length > 0
              ? selectedAttributeMulti
              : []
          }
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-attributes" label="Attributes" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: any) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {nftAttributes
            ? nftAttributes.map((nftAttribute: any) => (
                <MenuItem
                  key={nftAttribute.traitType + nftAttribute.value}
                  value={nftAttribute.traitType + ' :: ' + nftAttribute.value}
                >
                  {nftAttribute.traitType + ' :: ' + nftAttribute.value}
                </MenuItem>
              ))
            : []}
        </Select>
      </FormControl>
    </div>
  );
};
