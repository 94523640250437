import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatUsername } from '../../utils/formatUsername';
import { Copyright } from '../common/Copyright';
import { ConfirmSignup } from './ConfirmSignup';

const theme = createTheme();
interface SignupProps {
  userPool: CognitoUserPool;
}

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const SignUp = ({ userPool }: SignupProps) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [userName, setUserName] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email')!.toString();
    const password = data.get('password')!.toString();
    const username = formatUsername(email);
    const preferredName = data.get('firstName')!.toString();

    const attributeList = [];

    const dataEmail = {
      Name: 'email',
      Value: username,
    };

    const dataPreferredName = {
      Name: 'preferred_username',
      Value: preferredName,
    };

    attributeList.push(new CognitoUserAttribute(dataEmail));
    attributeList.push(new CognitoUserAttribute(dataPreferredName));

    userPool.signUp(username, password, attributeList, [], function (err, result) {
      if (err) {
        if (err.name === 'UsernameExistsException') {
          setEmailAlreadyExists(true);
        }

        return;
      }
      const cognitoUser = result!.user;
      setShowConfirm(true);
      setUserName(cognitoUser.getUsername());
    });
  };

  const handleInputValue = (e: any) => {
    const { value } = e.target;

    const valid = value.match(passwordRegex);
    setPasswordValid(valid?.length);
  };

  if (showConfirm) {
    return <ConfirmSignup userPool={userPool} userName={userName} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Preferred Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={emailAlreadyExists}
                  helperText={
                    emailAlreadyExists ? 'An account with the given email already exists.' : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  required
                  fullWidth
                  error={!passwordValid}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  helperText="Password must be 8+ chars and contain 1 of each: uppercase, lowercase, number, symbol"
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};
