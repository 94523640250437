import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface ReadOnlyRowProps {
  whitelistedToken: any;
  handleEditTokenClick: Function;
  handleDeleteTokenClick: Function;
  index: number;
  currentTokenIndex: number | undefined;
  deleteTokenInProgress: boolean;
}

export const ReadOnlyRow = ({
  whitelistedToken,
  handleEditTokenClick,
  index,
  handleDeleteTokenClick,
  currentTokenIndex,
  deleteTokenInProgress,
}: ReadOnlyRowProps) => {
  return (
    <TableRow
      key={whitelistedToken.tokenAddress + whitelistedToken.selectedTokens}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {whitelistedToken.network}
      </TableCell>
      <TableCell component="th" scope="row">
        {whitelistedToken.tokenAddress}
      </TableCell>
      <TableCell>{whitelistedToken.criteria}</TableCell>
      <TableCell>
        {deleteTokenInProgress && currentTokenIndex === index ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <Button onClick={() => handleDeleteTokenClick(index)}>Delete</Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};
