import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

interface EditableRowProps {
  index: number;
}

export const EditableRow = ({ index }: EditableRowProps) => {
  return (
    <TableRow
      key={index}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        <TextField required fullWidth placeholder="network" label="network" name="network" />
      </TableCell>
      <TableCell component="th" scope="row">
        <TextField
          required
          fullWidth
          placeholder="contract address"
          label="Contract Address"
          name="contractAddress"
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <TextField
          required
          fullWidth
          placeholder="selected tokens"
          label="Selected Tokens"
          name="selectedTokens"
        />
      </TableCell>
      <TableCell>
        <Button>Cancel</Button>
        <Button>Save</Button>
      </TableCell>
    </TableRow>
  );
};
