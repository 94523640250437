import { createContext, useContext, useEffect, useState } from 'react';
import { useQueryParams } from './useQueryParams';

const NetworkContext = createContext<string>('');

export const NetworkProvider: React.FC = ({ children }) => {
  const [network, setNetwork] = useState('');
  const query = useQueryParams();

  const networkInQuery = query.get('network');

  useEffect(() => {
    networkInQuery && setNetwork(networkInQuery);
  }, [networkInQuery]);

  return <NetworkContext.Provider value={network}>{children}</NetworkContext.Provider>;
};

export const useNetwork = () => {
  return useContext(NetworkContext);
};
