import CircularProgress from '@mui/material/CircularProgress';
import React, { PropsWithChildren, useEffect, useState } from 'react';

interface LoadingWrapperProps {
  /** whether the component should display the load state or not */
  loading: boolean;
  /** defaults to 500. if provided, any value under 100 is floored to 100. */
  minimumLoadMs?: number;
  /** component to display when in progress. defaults to the circular progress */
  progressComponent?: JSX.Element;
}

/**
 * Ensures that the progress bar appears for a minimum amount of time before
 * rendering the children components.
 */
export const LoadingWrapper: React.FunctionComponent<LoadingWrapperProps> = ({
  loading,
  minimumLoadMs,
  progressComponent,
  children,
}: PropsWithChildren<LoadingWrapperProps>): JSX.Element => {
  const waitAtLeast = minimumLoadMs != null ? (minimumLoadMs > 100 ? minimumLoadMs : 100) : 500;
  const [minimumLoadState, setMinimumLoadState] = useState(waitAtLeast !== 0);

  useEffect(() => {
    let canceled = false;
    if (waitAtLeast) {
      setTimeout(
        () => {
          if (!canceled) {
            setMinimumLoadState(false);
          }
        },
        // load for at least 0.1 s
        waitAtLeast
      );
    }
    return () => {
      canceled = true;
    };
  }, [waitAtLeast]);

  if (minimumLoadState || loading) {
    return <>{progressComponent ?? <CircularProgress />}</>;
  }
  return <>{children}</>;
};
