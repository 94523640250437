import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useDashboardCardStyles } from '../../constants/styles';

export const NewAppCard = (): JSX.Element => {
  const navigate = useNavigate();
  const classes = useDashboardCardStyles();
  const onClick = () => {
    navigate('/apps/create');
  };

  return (
    <Card variant="outlined" classes={classes}>
      <CardContent>
        <Typography variant="h5" color="text.secondary" gutterBottom>
          New App
        </Typography>
        <Typography variant="body2">
          Authenticate a user's wallet and gain access to details about that user via Manifold's
          User APIs.
        </Typography>
        <br />
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onClick}>
          Create App
        </Button>
      </CardActions>
    </Card>
  );
};
