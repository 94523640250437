import BluetoothIcon from '@mui/icons-material/Bluetooth';
import WifiIcon from '@mui/icons-material/Wifi';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import * as React from 'react';

interface ListRowProps {
  priceRuleId: string;
  handleDeletePriceRuleClick: Function;
  // index: num
}

export const ListRow = ({ priceRuleId, handleDeletePriceRuleClick }: ListRowProps) => {
  //   const [checked, setChecked] = React.useState(['wifi']);

  //   const handleToggle = (value: string) => () => {
  //     const currentIndex = checked.indexOf(value);
  //     const newChecked = [...checked];

  //     if (currentIndex === -1) {
  //       newChecked.push(value);
  //     } else {
  //       newChecked.splice(currentIndex, 1);
  //     }

  //     setChecked(newChecked);
  //   };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      subheader={<ListSubheader>Existing Price Rule Id</ListSubheader>}
    >
      <ListItem>
        {/* <ListItemIcon>
          <WifiIcon />
        </ListItemIcon> */}
        <ListItemText id="price-rule-id" primary="Price Rule Id" />
        <ListItemText id="price-rule-id">{priceRuleId}</ListItemText>
        <Button onClick={() => handleDeletePriceRuleClick()}>Delete</Button>
      </ListItem>
    </List>
  );
};
