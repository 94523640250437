import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTiledCardStyles } from '../../constants/styles';
import { Campaign } from '../../utils/interfaces/campaign';

interface CampaignCardProps {
  campaign: Campaign;
  handleDelete: (campaignId: number) => void | Promise<void>;
  handleView: (campaignId: number) => void;
}

export const CampaignCard = ({
  campaign,
  handleDelete,
  handleView,
}: CampaignCardProps): JSX.Element => {
  const classes = useTiledCardStyles();

  return (
    <Card variant="outlined" className={classes.cardRoot}>
      <CardActionArea onClick={() => handleView(campaign.id)}>
        <CardContent>
          <Typography variant="h5" color="text.secondary" gutterBottom>
            {campaign.name}
          </Typography>
          <br />
          <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 'light' }} gutterBottom>
            {campaign.id}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActionsRoot}>
        <Button size="small" onClick={() => handleView(campaign.id)}>
          View
        </Button>
        <IconButton
          size="small"
          aria-label="delete app"
          component="span"
          onClick={() => handleDelete(campaign.id)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};
