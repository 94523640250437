import { useEffect, useState } from 'react';
import { CampaignAPI } from '../utils/CampaignAPI';
import { useCognito } from './useCognito';
import { useNetwork } from './useNetwork';

export const useCampaignAPI = () => {
  const { cognitoSession } = useCognito();
  const network = useNetwork();
  const jwtToken = cognitoSession?.getIdToken().getJwtToken();
  const [campaignAPI, setCampaignAPI] = useState<CampaignAPI>(new CampaignAPI(network, jwtToken));

  useEffect(() => {
    setCampaignAPI(new CampaignAPI(network, jwtToken));
  }, [network, jwtToken]);

  return { campaignAPI };
};
