import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';

interface SettingsProps {
  cognitoSession: CognitoUserSession | null;
  userPool: CognitoUserPool;
}

export const Settings = ({ cognitoSession, userPool }: SettingsProps) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [done, setDone] = useState(false);

  const handleInputValue = (e: any) => {
    const { value } = e.target;
    switch (e.target.name) {
      case 'name':
        setUsername(value);
        break;
      case 'email':
        setEmail(value);
        break;
      default:
        console.log('Should not be here');
        break;
    }
  };

  const saveInfo = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      const data = new FormData(event.currentTarget);

      const username = data.get('name')!.toString();
      const email = data.get('email')!.toString();

      const attributeList: CognitoUserAttribute[] = [];

      const dataEmail = {
        Name: 'email',
        Value: email,
      };

      const dataUserName = {
        Name: 'preferred_username',
        Value: username,
      };

      attributeList.push(new CognitoUserAttribute(dataEmail));
      attributeList.push(new CognitoUserAttribute(dataUserName));

      cognitoUser.getSession((err: any, session: any) => {
        cognitoUser.updateAttributes(attributeList, function (err: any, result: any) {
          if (err) {
            console.log(err);
            return;
          }
          setDone(true);
          setLoading(false);
          setEditing(false);
        });
      });
    }
  };

  useEffect(() => {
    if (cognitoSession) {
      setUsername(cognitoSession.getIdToken().payload.preferred_username);
      setEmail(cognitoSession.getIdToken().payload.email);
    }
  }, [cognitoSession]);
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
      }}
      noValidate
      autoComplete="off"
      onSubmit={saveInfo}
    >
      <Typography
        component="h1"
        variant="h3"
        sx={{
          marginBottom: 3,
        }}
      >
        Account Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              marginBottom: 3,
            }}
          >
            Account and Login Info
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            sx={{
              marginBottom: 3,
            }}
          >
            Name:{' '}
            {editing && (
              <TextField
                required
                id="name"
                name="name"
                value={username}
                placeholder={username}
                onBlur={handleInputValue}
                onChange={handleInputValue}
              />
            )}{' '}
            {!editing && username}
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            sx={{
              marginBottom: 3,
            }}
          >
            Email:{' '}
            {editing && (
              <TextField
                required
                id="email"
                name="email"
                value={email}
                placeholder={email}
                onBlur={handleInputValue}
                onChange={handleInputValue}
              />
            )}{' '}
            {!editing && email}
          </Typography>
          {!loading && (
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{ background: 'black' }}
              onClick={() => setEditing(!editing)}
            >
              Edit
            </Button>
          )}
          {loading && !done && <CircularProgress />}
          {!loading && (
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2, ml: 2 }}
              type="submit"
              style={{ background: 'black' }}
            >
              Save
            </Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color="text.secondary" align="center">
            <Link
              color="inherit"
              href="https://manifoldxyz.notion.site/Privacy-Policy-bfc497773ea048d08dfa96644cb48adc"
            >
              Privacy Policy
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
