export type PostOptions = Omit<RequestInit, 'body'> & {
  body?: any;
};

export class BaseAPI {
  constructor(readonly jwtToken?: string) {}

  async getHeaders() {
    const headers: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (this.jwtToken) {
      headers['Authorization'] = `Bearer ${this.jwtToken}`;
    }

    return headers;
  }

  async fetchRaw(url: string, options: RequestInit = {}) {
    const requestOptions = {
      method: 'GET',
      headers: await this.getHeaders(),
      ...options,
    };

    const response = await fetch(url, requestOptions);

    if (response.status >= 400 && response.status <= 500) {
      return await this.handleErrorResponse(response);
    }

    return response;
  }

  async fetch(url: string, options: RequestInit = {}) {
    const requestOptions = {
      method: 'GET',
      headers: await this.getHeaders(),
      ...options,
    };

    const response = await fetch(url, requestOptions);

    if (response.status >= 400 && response.status <= 500) {
      return await this.handleErrorResponse(response);
    }

    return await response.json();
  }

  async put(url: string, options: PostOptions = {}) {
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: await this.getHeaders(),
      ...options,
      body: options.body ? JSON.stringify(options.body) : undefined,
    };

    const response = await fetch(url, requestOptions);

    if (response.status >= 400 && response.status <= 500) {
      return await this.handleErrorResponse(response);
    }

    return await response.json();
  }

  async post(url: string, options: PostOptions = {}) {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: await this.getHeaders(),
      ...options,
      body: options.body ? JSON.stringify(options.body) : undefined,
    };

    const response = await fetch(url, requestOptions);

    if (response.status >= 400 && response.status <= 500) {
      return await this.handleErrorResponse(response);
    }

    return await response.json();
  }

  async delete(url: string, options: RequestInit = {}) {
    const headers = await this.getHeaders();
    delete headers['Content-Type'];

    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers,
      ...options,
    };

    const response = await fetch(url, requestOptions);

    if (response.status >= 400 && response.status <= 500) {
      return await this.handleErrorResponse(response);
    }

    return await response.json();
  }

  async handleErrorResponse(response: Response) {
    if (response.headers.get('content-type') === 'application/json') {
      const { message } = await response.json();

      throw new Error(message);
    } else {
      throw new Error(`Received status "${response.status}" from server`);
    }
  }
}
