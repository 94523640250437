import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Settings } from './components/account/Settings';
import { NewFeature } from './components/app/NewFeature';
import { YourApps } from './components/app/YourApps';
import { AllCampaigns } from './components/campaigns/AllCampaigns';
import { Reset } from './components/login/Reset';
import { SignUp } from './components/login/SignUp';
import { ShopifyAuthenticate } from './components/shopify/ShopifyAuthenticate';
import { ShopifyComplete } from './components/shopify/ShopifyComplete';
import { SnapshotGenerator } from './components/tools/SnapshotGenerator';
import { useCognito } from './hooks/useCognito';
import { DeveloperPortalPage } from './pages/DeveloperPortalPage';
import { LoginPage } from './pages/LoginPage';

function App() {
  const { cognitoSession, userPool } = useCognito();

  return (
    <Routes>
      <Route path="/" element={<DeveloperPortalPage />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="apps/*" element={<YourApps cognitoSession={cognitoSession} />} />
        <Route path="campaigns/*" element={<AllCampaigns cognitoSession={cognitoSession} />} />
        <Route path="request" element={<NewFeature cognitoSession={cognitoSession} />} />
        <Route
          path="settings"
          element={<Settings cognitoSession={cognitoSession} userPool={userPool} />}
        />
        <Route path="snapshot" element={<SnapshotGenerator />} />
        <Route path="register" element={<SignUp userPool={userPool} />} />
        <Route path="reset" element={<Reset />} />
      </Route>
      <Route path="/shopify/:appName/complete" element={<ShopifyComplete userPool={userPool} />} />
      <Route path="/shopify/:appName/authenticate" element={<ShopifyAuthenticate />} />
    </Routes>
  );
}

export default App;
