import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useDashboardCardStyles } from '../../constants/styles';

export const NewFeatureCard = (): JSX.Element => {
  const navigate = useNavigate();
  const classes = useDashboardCardStyles();

  const onClick = () => {
    navigate('/request');
  };

  return (
    <Card variant="outlined" classes={classes}>
      <CardContent>
        <Typography variant="h5" color="text.secondary" gutterBottom>
          New Feature Request
        </Typography>
        <Typography variant="body2">New Feature or Bug Report?</Typography>
        <br />
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onClick}>
          Click Here
        </Button>
      </CardActions>
    </Card>
  );
};
