import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

interface EditableProductRowProps {
  index: number;
  campaignProduct: any;
  editProductFormData: any;
  handleEditProductFormChange: any;
  handleEditProductFormSubmit: any;
  handleCancelProductClick: any;
}

export const EditableProductRow = ({
  index,
  campaignProduct,
  editProductFormData,
  handleEditProductFormChange,
  handleEditProductFormSubmit,
  handleCancelProductClick,
}: EditableProductRowProps) => {
  return (
    <TableRow
      key={campaignProduct.id}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell>
        <img
          style={{ maxWidth: 100, maxHeight: 100 }}
          src={campaignProduct.productData?.image?.src}
          alt="pr"
        />
      </TableCell>
      <TableCell>{campaignProduct.productData.vendor}</TableCell>
      <TableCell>{campaignProduct.productData.title}</TableCell>
      <TableCell>{campaignProduct.productData.id}</TableCell>
      <TableCell component="th" scope="row">
        <TextField
          required
          fullWidth
          placeholder="discount %"
          label="Discount Percent"
          name="discountPercent"
          value={editProductFormData.discountPercent}
          onChange={handleEditProductFormChange}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <TextField
          required
          fullWidth
          placeholder="discount amount"
          label="Discount Amount"
          name="discountAmount"
          value={editProductFormData.discountAmount}
          onChange={handleEditProductFormChange}
        />
      </TableCell>
      {/* <TableCell>{campaignProduct.limit}</TableCell> */}
      <TableCell>
        <TextField
          required
          fullWidth
          placeholder="redemption limit"
          label="Redemption Limit"
          name="redemptionLimit"
          value={editProductFormData.redemptionLimit}
          onChange={handleEditProductFormChange}
        />
      </TableCell>
      <TableCell>
        <TextField
          required
          fullWidth
          placeholder="total inventory"
          label="Total Inventory"
          name="totalInventory"
          value={editProductFormData.totalInventory}
          onChange={handleEditProductFormChange}
        />
      </TableCell>
      <TableCell>{campaignProduct.consumed}</TableCell>
      <TableCell>
        <Button onClick={handleCancelProductClick}>Cancel</Button>
        <Button onClick={handleEditProductFormSubmit}>Save</Button>
      </TableCell>
    </TableRow>
  );
};
