import DescriptionIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useState } from 'react';
import { YourApps } from '../app/YourApps';
import { AllCampaigns } from '../campaigns/AllCampaigns';
import { ShopifyWelcome } from './ShopifyWelcome';

interface ShopifyEmbedProps {
  cognitoSession: CognitoUserSession | null;
  preferredName: string;
  shop: string;
}

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  ...(true && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const ShopifyEmbedv2 = ({ cognitoSession }: ShopifyEmbedProps) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [showApplications, setShowApplications] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showCampaignView, setShowCampaignView] = useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={true}
        style={{ zIndex: 0 }}
      >
        <List>
          <ListItemButton
            onClick={() => {
              setShowApplications(false);
              setShowCampaigns(false);
              setShowWelcome(true);
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Welcome" />
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              setShowWelcome(false);
              if (showCampaigns) {
                setShowCampaignView(false);
              } else {
                setShowApplications(false);
                setShowCampaigns(true);
              }
            }}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Campaigns" />
          </ListItemButton>

          <ListItem
            button
            key={'Developer Dashboard'}
            onClick={() => window.open('https://developer.manifoldxyz.dev')}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={'Account Settings'} />
          </ListItem>
        </List>
      </Drawer>
      <Main>
        {showWelcome && <ShopifyWelcome />}
        {showCampaigns && <AllCampaigns cognitoSession={cognitoSession} />}
        {showApplications && <YourApps cognitoSession={cognitoSession}></YourApps>}
      </Main>
    </Box>
  );
};
