import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

interface EditAppProps {
  cognitoSession: CognitoUserSession | null;
}

export const EditApp = ({ cognitoSession }: EditAppProps) => {
  const getAppBackend = 'https://kygy4d4nc3.execute-api.us-east-1.amazonaws.com/prod/app';
  const [appName, setAppName] = useState('');
  const [description, setDescription] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  // const [scopes, setScopes] = useState<string[]>([''])
  const [grantType, setGrantType] = useState('');

  const { appId } = useParams();

  const updateImage = async (event: any) => {
    const file = event.target.files[0];

    const resp = await fetch(`${getAppBackend}/${appId}/image?type=${file.type}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cognitoSession?.getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const respJson = await resp.json();

    const uploadURL = respJson.url;
    await fetch(uploadURL, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': event.target.files[0].type,
      },
    });
  };

  const regenerateClientSecret = async () => {
    const resp = await fetch(`${getAppBackend}/${appId}/secret`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cognitoSession?.getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const respJson = await resp.json();

    setClientSecret(respJson.clientSecret);
  };

  const settingsUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const appName = data.get('appName')!.toString();
    const appDescription = data.get('appDescription')!.toString();
    const redirectUri = data.get('redirectUri')!.toString();
    // const tokenByContractScope = data.get('tokenByContractScope') ?  true : false
    // const tokensOfOwnerScope = data.get('tokensOfOwnerScope') ?  true : false

    // let scopes = []
    // if (tokenByContractScope) {
    //   scopes.push('tokens_of_contract')
    // }

    // if (tokensOfOwnerScope) {
    //   scopes.push('tokens_of_owner')
    // }

    const resp = await fetch(`${getAppBackend}/${appId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${cognitoSession?.getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        appName,
        description: appDescription,
        redirectUri,
        // scopes: scopes
      }),
    });

    await resp.json();
  };

  const handleInputValue = (e: any) => {
    const { value } = e.target;
    switch (e.target.name) {
      case 'appName':
        setAppName(value);
        break;
      case 'appDescription':
        setDescription(value);
        break;
      case 'redirectUri':
        setRedirectUri(value);
        break;
      default:
        console.log('Should not be here');
        break;
    }
  };

  useEffect(() => {
    let mounted = true;
    const wrapper = async () => {
      if (!cognitoSession || !appId) {
        return;
      }
      const resp = await fetch(`${getAppBackend}/${appId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${cognitoSession.getIdToken().getJwtToken()}`,
        },
      });

      const resJson = await resp.json();
      if (mounted) {
        setAppName(resJson.appName);
        setDescription(resJson.description);
        setRedirectUri(resJson.redirectUri);
        setClientId(resJson.clientId);
        // setScopes(resJson.scopes)
        setGrantType(resJson.grantType);
      }
    };

    wrapper();
    return () => {
      mounted = false;
    };
  }, [appId, cognitoSession]);

  if (!appId) {
    return <Navigate to="/apps" replace />;
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
      }}
      noValidate
      autoComplete="off"
      onSubmit={settingsUpdate}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{
          marginBottom: 3,
        }}
      >
        App Settings for {appName}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography component="h6" variant="body1">
            App Name:
          </Typography>
          <TextField
            required
            id="appName"
            name="appName"
            value={appName}
            onBlur={handleInputValue}
            onChange={handleInputValue}
          />
          <Typography component="h6" variant="body1">
            App Description:
          </Typography>
          <TextField
            required
            id="appDescription"
            name="appDescription"
            value={description}
            multiline
            onBlur={handleInputValue}
            onChange={handleInputValue}
          />
          <Typography component="h6" variant="body1">
            Redirect URI:
          </Typography>
          <TextField
            required
            id="redirectUri"
            name="redirectUri"
            value={redirectUri}
            onBlur={handleInputValue}
            onChange={handleInputValue}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <Typography component="h6" variant="h6">
              Access Scopes
          </Typography>
          <FormControlLabel
            control={<Checkbox value="tokenByContractScope" color="primary" checked={scopes.includes('tokens_of_contract')} />}
            label="Tokens by Contract"
            id="tokenByContractScope"
            name="tokenByContractScope"
          />
          <FormControlLabel
            control={<Checkbox value="tokensOfOwnerScope" color="primary" checked={scopes.includes('tokens_of_owner')} />}
            label="Tokens of Owner"
            id="tokensOfOwnerScope"
            name="tokensOfOwnerScope"
          /> */}
          <Typography component="h6" variant="h6" sx={{ marginTop: 3 }}>
            Grant Type:{' '}
            {grantType === 'code'
              ? 'Authorization Code Grant'
              : grantType === 'signature'
              ? 'Signature Grant'
              : 'Token Grant'}
          </Typography>
          {grantType === 'code' && (
            <Typography component="h6" variant="h6" sx={{ marginTop: 3 }}>
              Client Secret:
              {!clientSecret && (
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2, marginLeft: 2 }}
                  onClick={regenerateClientSecret}
                >
                  Generate
                </Button>
              )}
              {clientSecret && (
                <Typography component="h6" variant="body1">
                  {clientSecret}
                </Typography>
              )}
            </Typography>
          )}
          <Typography component="h6" variant="body1">
            ClientID: {clientId}
          </Typography>
          <Button variant="contained" component="label">
            Update Image
            <input type="file" onChange={updateImage} />
          </Button>
        </Grid>
      </Grid>
      <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
        Update
      </Button>
    </Box>
  );
};
