import { useCallback } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { SignIn } from '../components/login/SignIn';
import { useCognito } from '../hooks/useCognito';
import { RedirectedState } from '../utils/interfaces/routing';

export const LoginPage = (): JSX.Element | null => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cognitoSession } = useCognito();

  // get from state
  const from = (location.state as RedirectedState)?.from;
  const redirectPath = from ? `${from.pathname}${from.search}${from.hash}` : '/';

  const postSignIn = useCallback(() => {
    navigate(redirectPath, { replace: true });
  }, [navigate, redirectPath]);

  if (cognitoSession) {
    return <Navigate to={redirectPath} replace={true} />;
  }

  return <SignIn postSignInCallback={postSignIn} />;
};
