import { BigNumber } from '@ethersproject/bignumber';
import HelpIcon from '@mui/icons-material/Help';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import keccak256 from 'keccak256';
import { MerkleTree } from 'merkletreejs';
import React, { Fragment, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Navigate, useParams } from 'react-router-dom';
import { unzip } from 'zlib';
import { getStorefrontsForNetwork } from '@manifoldxyz/shared-storefront-contracts';
import { useCampaignAPI } from '../../hooks/useCampaignAPI';
import { useQueryParams } from '../../hooks/useQueryParams';
import {
  Campaign,
  CampaignAction,
  campaignActionToString,
  CampaignQuestion,
  CampaignShopifyRule,
  CampaignShopifyRuleRestrictionType,
  CodeOption,
  Question,
  Redemption,
  ShopifyPriceRule,
  ShopifyProduct,
  Token,
  TokenFilteredByAttributes,
  TokenFilteredById,
  TokenFilteredByIdRange,
  TokenWithNoFilter,
} from '../../utils/interfaces/campaign';
import { AttributeMultiSelectDropdown } from './campaignForm/AttributeMultiSelectDropdown';
import { EditableProductRow } from './campaignForm/EditableProductRow';
import { EditableRow } from './campaignForm/EditableRow';
import { ListRow } from './campaignForm/ListRow';
import { NetworkSelectDropdown } from './campaignForm/NetworkSelectDropdown';
import { ReadOnlyProductRow } from './campaignForm/ReadOnlyProductRow';
import { ReadOnlyRow } from './campaignForm/ReadOnlyRow';

const theme = createTheme();

interface CampaignViewProps {
  cognitoSession: CognitoUserSession | null;
}

export const CampaignView = ({ cognitoSession }: CampaignViewProps) => {
  const { id } = useParams();
  if (!id || id.trim().length === 0) {
    return <Navigate to="/campaigns" />;
  }

  const query = useQueryParams();
  const isAdmin = query.get('admin');
  const campaignId = +id;

  const [campaign, setCampaign] = useState<Campaign>();
  // const [, setRedemptions] = useState<Redemption[]>([]);

  const [snapshotTime, setSnapshotTime] = useState('');
  const [snapshotUnixTimestamp, setSnapshotUnixTimestamp] = useState<number | null | undefined>(
    undefined
  );
  const [snapshotCutoffBlockNumbers, setSnapshotCutoffBlockNumbers] = useState<
    { [key: string]: number } | undefined
  >(undefined);
  const [status, setStatus] = useState('');
  const [isSnapshot, setIsSnapshot] = useState(false);

  const [whitelistedTokens, setWhitelistedTokens] = useState<any[]>([]);
  const [rules, setRules] = useState<any[]>([]);
  const [campaignQuestions, setCampaignQuestions] = useState<Question[]>([]);
  const [campaignCodeOptions, setCampaignCodeOptions] = useState<CodeOption[]>([]);
  const [campaignRedemptions, setCampaignRedemptions] = useState<Redemption[]>([]);
  const [campaignShops, setCampaignShops] = useState<string[]>([]);
  const [shop, setShop] = useState('');
  const [shop_rule, setShop_rule] = useState('');
  const [productsInShop, setProductsInShop] = useState<string[]>([]);
  const [priceRulesOnShop, setPriceRulesOnShop] = useState<any[]>([]);
  const [perWalletLimit, setPerWalletLimit] = useState();
  const [updatedPerWalletLimit, setUpdatedPerWalletLimit] = useState();

  const [shopProductNameToAdd, setShopProductNameToAdd] = useState('');
  const [shopProductIdToAdd_rule, setShopProductIdToAdd_rule] = useState('');
  const [shopProductIdToAdd, setShopProductIdToAdd] = useState<number | null>(null);
  const [productDiscountPercent, setProductDiscountPercent] = useState('');
  const [productDiscountPercent_rule, setProductDiscountPercent_rule] = useState('');
  const [productDiscountAmount, setProductDiscountAmount] = useState('');
  const [productDiscountAmount_rule, setProductDiscountAmount_rule] = useState('');
  const [priceRuleId, setPriceRuleId] = useState('');
  const [priceRuleDescription, setPriceRuleDescription] = useState(
    'Congrats! Redeem this token to get X% off an item'
  );

  const [productRedemptionLimit, setProductRedemptionLimit] = useState('');
  const [productInventory, setProductInventory] = useState('');
  const [campaignProducts, setCampaignProducts] = useState<any[]>([]);

  const [network, setNetwork] = useState(1);
  const [tokenAddress, setTokenAddress] = useState('');
  const [tokenAddress_rule, setTokenAddress_rule] = useState('');
  const [whichTokens, setWhichTokens] = useState('');
  const [whichTokens_rule, setWhichTokens_rule] = useState('');
  const [isCappedInventory, setIsCappedInventory] = useState(false);
  const [isRestrictedRule, setIsRestrictedRule] = useState(false);
  const [whichQuestion, setWhichQuestion] = useState('');
  const [tokenErrorMessage, setTokenErrorMessage] = useState('');
  const [tokenSuccessMessage, setTokenSuccessMessage] = useState('');
  const [ruleSuccessMessage, setRuleSuccessMessage] = useState('');
  const [productSuccessMessage, setProductSuccessMessage] = useState('');

  const [createTokenInProgress, setCreateTokenInProgress] = useState(false);

  const [addRuleInProgress, setAddRuleInProgress] = useState(false);
  const [addProductInProgress, setAddProductInProgress] = useState(false);
  const [deleteRuleInProgress, setDeleteRuleInProgress] = useState(false);
  const [deleteProductInProgress, setDeleteProductInProgress] = useState(false);
  const [deleteTokenInProgress, setDeleteTokenInProgress] = useState(false);

  const [questionErrorMessage, setQuestionErrorMessage] = useState('');
  const [questionSuccessMessage, setQuestionSuccessMessage] = useState('');
  const [createQuestionInProgress, setCreateQuestionInProgress] = useState(false);

  const [codeOptionDescription, setCodeOptionDescription] = useState('');
  const [codeOptionLimit, setCodeOptionLimit] = useState('');
  const [codeOptionErrorMessage, setCodeOptionErrorMessage] = useState('');
  const [codeOptionSuccessMessage, setCodeOptionSuccessMessage] = useState('');
  const [createCodeOptionInProgress, setCreateCodeOptionProgress] = useState(false);

  const [shopifyProductErrorMessage, setShopifyProductErrorMessage] = useState('');
  const [ruleErrorMessage, setRuleErrorMessage] = useState('');
  const [shopifyPriceRuleErrorMessage, setShopifyPriceRuleErrorMessage] = useState('');
  const [freeFormQuestion, setFreeFormQuestion] = useState('');
  const [snapshotErrorMessage, setSnapshotErrorMessage] = useState<string[]>([]);
  const [showDiscountWarning, setShowDiscountWarning] = useState(false);
  const [showDiscountWarning_rule, setShowDiscountWarning_rule] = useState(false);
  const [pollQuestions, setPollQuestions] = useState<any[]>([
    {
      label: 'Option 1',
      value: '',
    },
    {
      label: 'Option 2',
      value: '',
    },
  ]);

  const [publicPollQuestions, setPublicPollQuestions] = useState<any[]>([
    {
      label: 'Option 1',
      value: '',
    },
    {
      label: 'Option 2',
      value: '',
    },
  ]);

  const [tokenIndividual, setTokenIndividual] = useState<string | undefined>();
  const [tokenIndividual_rule, setTokenIndividual_rule] = useState<string | undefined>();
  const [minToken, setMinToken] = useState<string | undefined>();
  const [minToken_rule, setMinToken_rule] = useState<string | undefined>();
  const [maxToken, setMaxToken] = useState<string | undefined>();
  const [maxToken_rule, setMaxToken_rule] = useState<string | undefined>();
  const [currentRuleIndex, setCurrentRuleIndex] = useState<number | undefined>();
  const [currentProductIndex, setCurrentProductIndex] = useState<number | undefined>();
  const [currentTokenIndex, setCurrentTokenIndex] = useState<number | undefined>();

  const [toUpdate, setToUpdate] = useState(-1);

  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  const [showEditStartTime, setShowEditStartTime] = useState(false);
  const [showEditEndTime, setShowEditEndTime] = useState(false);

  const [editTokenId, setEditTokenId] = useState<number | null>(null);
  const [editProductTableId, setEditProductTableId] = useState<number | null>(null);

  const [editProductFormData, setEditProductFormData] = useState({
    productId: '',
    discountPercent: null,
    discountAmount: null,
    redemptionLimit: null,
    totalInventory: null,
  });

  const [redemptionTableColumnHeaders, setRedemptionTableColumnHeaders] = useState<string[]>([]);
  const [csvRedemptions, setCsvRedemptions] = useState<(string | number)[][]>([]);
  const [showCSVDownload, setShowCSVDownload] = useState(false);
  const [snapshotData, setSnapshotData] = useState<any[]>([]);

  const [nftAttributes, setNFTAttributes] = useState<any[]>();
  const [nftAttributes_rule, setNFTAttributes_rule] = useState<any[]>();
  const [selectedAttribute, setSelectedAttribute] = useState<any>();
  const [selectedAttributeMulti, setSelectedAttributeMulti] = useState<string[]>([]);
  const [selectedAttributeMulti_rule, setSelectedAttributeMulti_rule] = useState<string[]>([]);
  const [campaignPriceRuleId, setCampaignPriceRuleId] = useState('');
  const [merkleRoot, setMerkleRoot] = useState('');

  const [pollQuestionText, setPollQuestionText] = useState('Choose one!');
  const [publicPollQuestionText, setPublicPollQuestionText] = useState('Choose one!');

  const { campaignAPI } = useCampaignAPI();

  // --- Shopify Product Handlers

  // src: rstackhouse
  //https://stackoverflow.com/questions/58675993/typescript-react-select-onchange-handler-type-error
  const handleShopChange = (event: SelectChangeEvent<unknown>) => {
    //@ts-ignore
    setShop(event.target.value);
  };
  const handleShopChange_rule = (event: SelectChangeEvent<unknown>) => {
    //@ts-ignore
    setShop_rule(event.target.value);
  };

  const handlePriceRuleChange = (event: SelectChangeEvent<unknown>) => {
    //@ts-ignore
    setPriceRuleId(event.target.value);
  };

  const handleProductChange = async (event: SelectChangeEvent<unknown>) => {
    //@ts-ignore
    setShopProductNameToAdd(event.target.value);

    //@ts-ignore
    const index = await productsInShop.findIndex((product) => product.title === event.target.value);
    //@ts-ignore
    const productToAdd = productsInShop[index];
    //@ts-ignore
    setShopProductIdToAdd(productToAdd.id);
  };
  const handleProductChange_rule = async (event: SelectChangeEvent<unknown>) => {
    //@ts-ignore
    setShopProductIdToAdd_rule(event.target.value);
  };

  const handleProductDiscountPercentChange = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setProductDiscountPercent(event.currentTarget.value);
    setProductDiscountAmount('');
    // @ts-ignore
    if (event.currentTarget.value === 0) {
      setShowDiscountWarning(true);
    } else {
      setShowDiscountWarning(false);
    }
  };

  const handleProductDiscountPercentChange_rule = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setProductDiscountPercent_rule(event.currentTarget.value);
    setProductDiscountAmount_rule('');
    // @ts-ignore
    if (event.currentTarget.value === 0) {
      setShowDiscountWarning_rule(true);
    } else {
      setShowDiscountWarning_rule(false);
    }
  };

  const handleProductDiscountAmountChange = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setProductDiscountAmount(event.currentTarget.value);
    setProductDiscountPercent('');
    // @ts-ignore
    if (event.currentTarget.value === 0) {
      setShowDiscountWarning(true);
    } else {
      setShowDiscountWarning(false);
    }
  };

  const handleProductDiscountAmountChange_rule = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setProductDiscountAmount_rule(event.currentTarget.value);
    setProductDiscountPercent_rule('');
    // @ts-ignore
    if (event.currentTarget.value === 0) {
      setShowDiscountWarning_rule(true);
    } else {
      setShowDiscountWarning_rule(false);
    }
  };

  const handlePriceRuleDescriptionChange = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setPriceRuleDescription(event.currentTarget.value);
  };

  const handleProductRedemptionLimitChange = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setProductRedemptionLimit(event.currentTarget.value);
  };

  const handleProductInventory = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setProductInventory(event.currentTarget.value);
  };

  const handleCodeOptionDescriptionChange = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setCodeOptionDescription(event.currentTarget.value);
  };

  const handleCodeOptionLimitChange = (event: React.SyntheticEvent) => {
    // @ts-ignore
    if (event.currentTarget.value.match(/^\d*$/)) {
      // @ts-ignore
      setCodeOptionLimit(event.currentTarget.value);
    }
  };

  const perWalletLimitHandler = (event: any) => {
    console.log('%%% handler %%%: ', event.currentTarget.value);
    // @ts-ignore
    setUpdatedPerWalletLimit(parseInt(event.currentTarget.value));
  };

  const savePerWalletLimit = async () => {
    await campaignAPI.updateCampaign({ campaignId, redemptionsPerAddress: updatedPerWalletLimit });
    setToUpdate(Math.floor(Math.random() * 1000000000000));
  };

  const startTimeHandler = (event: any) => {
    setStartTime(event.getTime());
  };

  const endTimeHandler = (event: any) => {
    setEndTime(event.getTime());
  };

  const saveOrEditStartTime = async (_event: any) => {
    if (showEditStartTime) {
      await campaignAPI.updateCampaign({ campaignId, startDate: startTime });
      setShowEditStartTime(false);
    } else {
      setShowEditStartTime(true);
    }
  };

  const saveOrEditEndTime = async (_event: any) => {
    if (showEditEndTime) {
      await campaignAPI.updateCampaign({ campaignId, endDate: endTime });
      setShowEditEndTime(false);
    } else {
      setShowEditEndTime(true);
    }
  };

  const refreshTokenMetadata = async () => {
    await campaignAPI.refreshTokenMetadata(network, tokenAddress);
    alert('Triggered refresh!');
  };

  const endCampaign = async () => {
    setEndTime(Date.now() + 60000);
    await saveOrEditEndTime({});
  };

  /**
   * Add a shopify product to the campaign.
   */
  const addProductToCampaign = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    setShopifyProductErrorMessage('');
    setProductSuccessMessage('');
    setAddProductInProgress(true);

    // Make a request to create a token for each campaign token (full token address, range, single ID) they have whitelisted
    // Create the Token object to submit
    const productToSubmit: Partial<ShopifyProduct> = {};
    if (shop) {
      productToSubmit['shop'] = shop;
    }
    if (shopProductIdToAdd) {
      productToSubmit['productId'] = shopProductIdToAdd;
    }
    if (!isNaN(parseInt(productDiscountPercent))) {
      productToSubmit['discountPercent'] = parseInt(productDiscountPercent);
    }
    if (!isNaN(parseInt(productDiscountAmount))) {
      productToSubmit['discountAmount'] = parseInt(productDiscountAmount);
    }
    if (productRedemptionLimit) {
      productToSubmit['limit'] = parseInt(productRedemptionLimit);
    }

    if (productInventory) productToSubmit['total'] = parseInt(productInventory);
    else productToSubmit['total'] = null;

    try {
      await campaignAPI.addShopifyProduct(campaignId, productToSubmit);
      setShopifyProductErrorMessage('');
      setProductSuccessMessage('Successfully linked a shopify product.');
    } catch (e: any) {
      setShopifyProductErrorMessage(e.message);
    }
    // Clear the form after successful validate and submit
    setShopProductIdToAdd(null);
    setProductDiscountPercent('');
    setProductDiscountAmount('');
    setShopProductNameToAdd('');
    setProductRedemptionLimit('');
    setProductInventory('');
    setToUpdate(Math.floor(Math.random() * 1000000000000));
    setAddProductInProgress(false);
    return;
  };

  const _isValidRule = async () => {
    const productDiscounAmountInt = !isNaN(parseInt(productDiscountAmount_rule))
      ? parseInt(productDiscountAmount_rule)
      : undefined;
    const productDiscountPercentInt = !isNaN(parseInt(productDiscountPercent_rule))
      ? parseInt(productDiscountAmount_rule)
      : undefined;
    if (
      !shopProductIdToAdd_rule ||
      (productDiscounAmountInt === undefined && productDiscountPercentInt === undefined)
    )
      return false;
    if (
      productDiscountPercentInt !== undefined &&
      (productDiscountPercentInt < 0 || productDiscountPercentInt > 100)
    )
      return false;

    if (isRestrictedRule) {
      if (!tokenAddress_rule || !whichTokens_rule) return false;
      // if (whichTokens_rule === 'allTokens') return false
      if (whichTokens_rule === 'rangeTokens') {
        if (!minToken_rule || !maxToken_rule) return false;
        if (BigNumber.from(minToken_rule).gt(BigNumber.from(maxToken_rule))) return false;
      }
      if (whichTokens_rule === 'selectTokens' && !tokenIndividual_rule) return false;
      if (whichTokens_rule === 'attributeTokens' && !selectedAttributeMulti_rule.length)
        return false;
    }

    return true;
  };

  /**
   * Add a whitelisted token to the campaign.
   */
  const addRuleToProductOnCampaign = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    // Start the spinning wheel
    setAddRuleInProgress(true);
    setRuleSuccessMessage('');
    setRuleErrorMessage('');

    // Validate the state fields
    const isValid = await _isValidRule();

    if (!isValid || !shopProductIdToAdd_rule) {
      setRuleErrorMessage('Invalid input. Pleas fix form error(s)');
      setAddRuleInProgress(false);
      return;
    }

    // Create the rule based on the inputs
    const ruleToSubmit: Partial<CampaignShopifyRule> = {};

    if (!isRestrictedRule) {
      ruleToSubmit['restrictionType'] = CampaignShopifyRuleRestrictionType.UNRESTRICTED;
      ruleToSubmit['restriction'] = undefined;
    } else {
      ruleToSubmit['discountPercent'] = !isNaN(parseInt(productDiscountPercent_rule))
        ? parseInt(productDiscountPercent_rule)
        : undefined;
      ruleToSubmit['discountAmount'] = !isNaN(parseInt(productDiscountAmount_rule))
        ? parseInt(productDiscountAmount_rule)
        : undefined;
      if (whichTokens_rule === 'allTokens') {
        ruleToSubmit['restrictionType'] = CampaignShopifyRuleRestrictionType.TOKEN;
        ruleToSubmit['restriction'] = { network: network, tokenAddress: tokenAddress_rule };
      } else if (whichTokens_rule === 'attributeTokens') {
        const attributeEntries = [];
        for (const selectedAttr of selectedAttributeMulti_rule) {
          const kvPair = selectedAttr.split(' :: ');
          const traitType = kvPair[0];
          const traitValue = kvPair[1];
          attributeEntries.push({ traitType: traitType, value: traitValue });
        }

        ruleToSubmit['restrictionType'] = CampaignShopifyRuleRestrictionType.TOKEN_ATTRIBUTE;
        ruleToSubmit['restriction'] = {
          network: network,
          tokenAddress: tokenAddress_rule,
          attributeEntries: attributeEntries,
        };
      } else if (whichTokens_rule === 'rangeTokens') {
        ruleToSubmit['restrictionType'] = CampaignShopifyRuleRestrictionType.TOKEN_RANGE;
        ruleToSubmit['restriction'] = {
          network: network,
          tokenAddress: tokenAddress_rule,
          minTokenId: minToken_rule,
          maxTokenId: maxToken_rule,
        };
      }
      // else if (whichTokens_rule === 'selectTokens') {}
      else {
        setRuleErrorMessage('Invalid checkbox selected.');
        setAddRuleInProgress(false);
        return;
      }
    }

    try {
      await campaignAPI.addCampaignRule(campaignId, shopProductIdToAdd_rule, ruleToSubmit);
      setRuleErrorMessage('');
      setRuleSuccessMessage(
        'Successfully added a rule to a product. Rules table will update automatically in a few seconds.'
      );
    } catch (e: any) {
      setRuleErrorMessage(e.message);
    }

    // Clear the form after submitting
    setShop_rule('');
    setShopProductIdToAdd_rule('');
    setIsRestrictedRule(false);
    setProductDiscountAmount_rule('');
    setProductDiscountPercent_rule('');
    setTokenAddress_rule('');
    setWhichTokens_rule('');
    setTokenIndividual_rule('');
    setMinToken_rule('');
    setMaxToken_rule('');
    setMinToken_rule('');
    setShopProductIdToAdd(null);
    setSelectedAttributeMulti_rule([]);
    setToUpdate(Math.floor(Math.random() * 1000000000000));
    setAddRuleInProgress(false);
    setShowDiscountWarning(false);
    return;
  };

  /**
   * Add a shopify prriceRuleId campaign.
   */
  const addPriceRuleId = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    setShopifyPriceRuleErrorMessage('');

    // Error check before making the request
    if (!shop || !priceRuleId || !priceRuleDescription) {
      setShopifyPriceRuleErrorMessage(
        'Please fill in missing information. Shop, priceRuleId, and Redemption message must all be set'
      );
      return;
    }

    // Make a request to create a token for each campaign token (full token address, range, single ID) they have whitelisted
    // Create the Token object to submit
    const priceRuleToSubmit: Partial<ShopifyPriceRule> = {
      shop: shop,
      priceRuleId: priceRuleId,
      description: priceRuleDescription,
    };

    try {
      await campaignAPI.addShopifyPriceRule(campaignId, priceRuleToSubmit);
      setShopifyPriceRuleErrorMessage('');
    } catch (e: any) {
      setShopifyPriceRuleErrorMessage(e.message);
    }

    // Clear the form after successful validate and submit
    setShop('');
    setPriceRuleId('');
    setPriceRuleDescription('');
    setToUpdate(Math.floor(Math.random() * 1000000000000));
    return;
  };

  /** Get existing states of the product row when you press edit */
  const handleEditProductClick = (
    event: React.SyntheticEvent,
    product: any,
    productTableId: number
  ) => {
    event.preventDefault();
    setEditProductTableId(productTableId);

    const productFormValues = {
      productId: product.productId,
      discountPercent: product.discountPercent,
      discountAmount: product.discountAmount,
      redemptionLimit: product.limit,
      totalInventory: product.total,
    };
    setEditProductFormData(productFormValues);
  };

  const handleCancelProductClick = (_event: React.SyntheticEvent) => {
    setEditProductTableId(null);
  };

  /** When you type in a new discountPercent value upate the state */
  const handleEditProductFormChange = (event: React.SyntheticEvent) => {
    event.preventDefault();
    //@ts-ignore
    const fieldName = event.target.getAttribute('name');

    const newFormData = { ...editProductFormData };
    //@ts-ignore
    newFormData[fieldName] = event.target.value;
    setEditProductFormData(newFormData);
  };

  /** Make the request to edit a product */
  const handleEditProductFormSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    const editedProduct = {
      productId: parseInt(editProductFormData.productId),
      discountPercent: editProductFormData.discountPercent
        ? editProductFormData.discountPercent
        : undefined,
      discountAmount: editProductFormData.discountAmount
        ? editProductFormData.discountAmount
        : undefined,
      limit: editProductFormData.redemptionLimit,
      total: editProductFormData.totalInventory || null,
    };

    try {
      await campaignAPI.updateShopifyProduct(campaignId, editedProduct.productId, editedProduct);
      setShopifyProductErrorMessage('');
      setProductSuccessMessage('Successfully updated a shopify product.');
    } catch (e: any) {
      setShopifyProductErrorMessage(e.message);
    }

    // Clear the form after successful validate and submit
    setEditProductTableId(null);
    setToUpdate(Math.floor(Math.random() * 1000000000000));
  };

  const handleDeleteProductClick = async (productTableId: number) => {
    const remainingProducts = [...campaignProducts];
    const productRowToDelete = remainingProducts[productTableId];
    const productId = productRowToDelete.productId;

    setShopifyProductErrorMessage('');
    setCurrentProductIndex(productTableId);
    setDeleteProductInProgress(true);

    try {
      await campaignAPI.deleteShopifyProduct(campaignId, parseInt(productId));
      setToUpdate(Math.floor(Math.random() * 1000000000000));
      setShopifyProductErrorMessage('');
    } catch (e: any) {
      setShopifyProductErrorMessage(e.message);
    }
    setDeleteProductInProgress(false);
    setCurrentProductIndex(undefined);
  };

  const handleDeleteRuleClick = async (ruleTableId: number) => {
    setRuleErrorMessage('');
    setRuleSuccessMessage('');
    const remainingRules = [...rules];
    const ruleRowToDelete = remainingRules[ruleTableId];
    const ruleId = ruleRowToDelete.id;
    if (!ruleId) {
      setRuleErrorMessage('No ruleId so cannot delete');
      return;
    }
    setCurrentRuleIndex(ruleTableId);

    try {
      await campaignAPI.deleteCampaignRule(campaignId, ruleRowToDelete.productId, ruleId);
      setRuleErrorMessage('');
      setRuleSuccessMessage(
        'Successfully deleted a rule from a product. Rules table will update automatically in a few seconds.'
      );
      setToUpdate(Math.floor(Math.random() * 1000000000000));
    } catch (e: any) {
      setRuleErrorMessage('Something went wrong. Please try again.');
    }

    setDeleteRuleInProgress(false);
    return;
  };

  const handleDeletePriceRuleClick = async () => {
    await campaignAPI.deleteShopifyPriceRule(campaignId);
    setToUpdate(Math.floor(Math.random() * 1000000000000));
  };

  // --- Whitelist Token Handlers
  const handleEditTokenClick = (
    event: React.SyntheticEvent,
    token: Token,
    tokenTableId: number
  ) => {
    event.preventDefault();
    setEditTokenId(tokenTableId);
  };

  const handleDeleteTokenClick = async (tokenTableId: number) => {
    const remainingTokens = [...whitelistedTokens];
    // Find the correct token row in the table. TokenRowToDelete created in parseTokenData().
    const tokenRowToDelete = remainingTokens[tokenTableId];

    // Extra state variables for spinning wheel on delete
    setTokenErrorMessage('');
    setCurrentTokenIndex(tokenTableId);
    setDeleteTokenInProgress(true);

    try {
      await campaignAPI.deleteTokenFromAllowlist(campaignId, tokenRowToDelete);
      setTokenSuccessMessage(
        'Successfully deleted a whitelisted token. The table will update automatically in a few seconds.'
      );
      setToUpdate(Math.floor(Math.random() * 1000000000000));
    } catch (e: any) {
      console.log(`delete token error:`, e);
    }
    setCurrentTokenIndex(undefined);
    setDeleteTokenInProgress(false);
  };

  const tokenAddressChangeHandler = (event: React.SyntheticEvent) => {
    // @ts-ignore
    const tokenAddress = event.currentTarget.value.trim().toLowerCase();
    const storefrontContractAddresses = getStorefrontsForNetwork(network).map((x) => x.address);
    if (!isAdmin && tokenAddress in storefrontContractAddresses) {
      setTokenErrorMessage('Cannot use the shared storefront address');
      setTokenAddress('');
    } else {
      setTokenAddress(tokenAddress);
      if (tokenErrorMessage === 'Cannot use the shared storefront address')
        setTokenErrorMessage('');
    }
  };

  const tokenAddressChangeHandler_rule = (event: React.SyntheticEvent) => {
    // @ts-ignore
    const tokenAddress = event.currentTarget.value.toLowerCase();
    const storefrontContractAddresses = getStorefrontsForNetwork(network).map((x) => x.address);
    if (!isAdmin && tokenAddress in storefrontContractAddresses) {
      setRuleErrorMessage('Cannot use the shared storefront address');
    } else {
      setTokenAddress_rule(tokenAddress);
      if (ruleErrorMessage === 'Cannot use the shared storefront address') setRuleErrorMessage('');
    }
  };

  const freeFormQuestionHandler = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setFreeFormQuestion(event.currentTarget.value);
  };

  const addPollOption = () => {
    const newPollQuestions = pollQuestions.concat([
      {
        label: `Option ${pollQuestions.length + 1}`,
        value: '',
      },
    ]);
    setPollQuestions(newPollQuestions);
  };

  const addPublicPollOption = () => {
    const newPollQuestions = publicPollQuestions.concat([
      {
        label: `Option ${publicPollQuestions.length + 1}`,
        value: '',
      },
    ]);
    setPublicPollQuestions(newPollQuestions);
  };

  const pollQuestionTextHandler = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setPollQuestionText(event.target.value);
  };

  const publicPollQuestionTextHandler = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setPublicPollQuestionText(event.target.value);
  };

  const pollQuestionHandler = (event: React.SyntheticEvent) => {
    const newItem = {
      // @ts-ignore
      label: `Option ${event.currentTarget.name}`,
      // @ts-ignore
      value: event.currentTarget.value,
    };

    // @ts-ignore
    const arrayBefore = pollQuestions.slice(0, parseInt(event.currentTarget.name) - 1);
    // @ts-ignore
    const arrayAfter = pollQuestions.slice(parseInt(event.currentTarget.name));

    console.log(arrayBefore);
    console.log(arrayAfter);

    const newArray = arrayBefore.concat([newItem]).concat(arrayAfter);

    console.log(newArray);
    setPollQuestions(newArray);
  };

  const publicPollQuestionHandler = (event: React.SyntheticEvent) => {
    const newItem = {
      // @ts-ignore
      label: `Option ${event.currentTarget.name}`,
      // @ts-ignore
      value: event.currentTarget.value,
    };

    // @ts-ignore
    const arrayBefore = publicPollQuestions.slice(0, parseInt(event.currentTarget.name) - 1);
    // @ts-ignore
    const arrayAfter = publicPollQuestions.slice(parseInt(event.currentTarget.name));

    console.log(arrayBefore);
    console.log(arrayAfter);

    const newArray = arrayBefore.concat([newItem]).concat(arrayAfter);

    console.log(newArray);
    setPublicPollQuestions(newArray);
  };

  const whichTokensSelector = async (event: React.SyntheticEvent) => {
    // First Error Check
    // @ts-ignore
    if (!tokenAddress) {
      setWhichTokens('');
      setTokenErrorMessage('Please add a contract address first');
      return;
    } else if (tokenAddress.length !== 42) {
      setWhichTokens('');
      setTokenErrorMessage('Please enter a valid contract address.');
      return;
    }

    // @ts-ignore
    setWhichTokens(event.currentTarget.value);
    setTokenErrorMessage('');

    // @ts-ignore
    if (event.currentTarget.value === 'attributeTokens') {
      try {
        const attributes = await campaignAPI.fetchTokenNFTAttributes(network, tokenAddress);
        if (attributes.length === 0) {
          setTokenErrorMessage(
            'No attributes were found on that contract. Please double check the address and make sure it is not on a shared storefront contract.'
          );
        } else {
          setNFTAttributes(attributes);
          setTokenErrorMessage('');
        }
      } catch (e: any) {
        console.log('ERROR trying to get attributes for that contract.');
        setTokenErrorMessage(
          'Something went wrong trying to get the attributes on that contract. Please refresh the page and try again.'
        );
      }
    }
  };
  const whichTokensSelector_rule = async (event: React.SyntheticEvent) => {
    // First Error Check
    // @ts-ignore
    if (!tokenAddress_rule) {
      setWhichTokens_rule('');
      setRuleErrorMessage('Please add a contract address first');
      return;
    } else if (tokenAddress_rule.length !== 42) {
      setWhichTokens_rule('');
      setRuleErrorMessage('Please enter a valid contract address.');
      return;
    }

    // @ts-ignore
    setWhichTokens_rule(event.currentTarget.value);
    setRuleErrorMessage('');

    // @ts-ignore
    if (event.currentTarget.value === 'attributeTokens') {
      try {
        const attributes = await campaignAPI.fetchTokenNFTAttributes(network, tokenAddress_rule);
        if (attributes.length === 0) {
          setRuleErrorMessage(
            'No attributes were found on that contract. Please double check the address and make sure it is not on a shared storefront contract.'
          );
        } else {
          try {
            setNFTAttributes_rule(attributes);
            setRuleErrorMessage('');
          } catch (error) {
            console.log('error setNFTAttributes: ', error);
          }
        }
      } catch (e: any) {
        setRuleErrorMessage(
          'Something went wrong trying to get the attributes on that contract. Please refresh the page and try again.'
        );
      }
    }
  };

  const cappedInventorySelector = async (_event: React.SyntheticEvent) => {
    setIsCappedInventory(!isCappedInventory);
    setProductInventory('');
  };

  const restrictedRuleSelector = async (_event: React.SyntheticEvent) => {
    setIsRestrictedRule(!isRestrictedRule);
    setTokenAddress_rule('');
    setWhichTokens_rule('');
  };

  const setIndividualToken = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setTokenIndividual(event.currentTarget.value);
  };
  const setIndividualToken_rule = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setTokenIndividual_rule(event.currentTarget.value);
  };

  const setTokenMin = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setMinToken(event.currentTarget.value);
  };
  const setTokenMin_rule = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setMinToken_rule(event.currentTarget.value);
  };

  const setTokenMax = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setMaxToken(event.currentTarget.value);
  };
  const setTokenMax_rule = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setMaxToken_rule(event.currentTarget.value);
  };

  /**
   * Helper Function that helps to build the whitelist token table
   */
  const parseTokenData = async (tokenData: any) => {
    if (!tokenData) return [];
    const tokenRows = [];
    for await (const whitelistTokenType of Object.entries(tokenData)) {
      //@ts-ignore
      if (whitelistTokenType[1].length > 0) {
        //@ts-ignore
        for await (const whitelistedTokenItem of whitelistTokenType[1]) {
          if (whitelistTokenType[0] === 'tokens') {
            //@ts-ignore
            tokenRows.push({
              network: whitelistedTokenItem.network,
              tokenAddress: whitelistedTokenItem.tokenAddress,
              criteria: 'all',
              filterType: 'no_filter',
            });
          } else if (whitelistTokenType[0] === 'tokenRanges') {
            //@ts-ignore
            tokenRows.push({
              network: whitelistedTokenItem.network,
              tokenAddress: whitelistedTokenItem.tokenAddress,
              criteria: `${whitelistedTokenItem.minTokenId} - ${whitelistedTokenItem.maxTokenId}`,
              filterType: 'id_range',
              minTokenId: `${whitelistedTokenItem.minTokenId}`,
              maxTokenId: `${whitelistedTokenItem.maxTokenId}`,
            });
          } else if (whitelistTokenType[0] === 'tokenIds') {
            //@ts-ignore
            tokenRows.push({
              network: whitelistedTokenItem.network,
              tokenAddress: whitelistedTokenItem.tokenAddress,
              criteria: `${whitelistedTokenItem.tokenId}`,
              filterType: 'id',
              tokenId: `${whitelistedTokenItem.tokenId}`,
            });
          } else if (whitelistTokenType[0] === 'tokenAttributes') {
            let currentCriteria = '';
            const attributeEntriesOnToken = whitelistedTokenItem.attributeEntries;
            // Loop through tokenWithAttributeEntrys associated with the token
            for (let i = 0; i < attributeEntriesOnToken.length; i++) {
              const attributeEntry = attributeEntriesOnToken[i];
              currentCriteria =
                currentCriteria + attributeEntry.traitType + ' :: ' + attributeEntry.value;
              // add a comma and space for readability except for the last entry
              if (i < attributeEntriesOnToken.length - 1) {
                currentCriteria += ', ';
              }
            }
            //@ts-ignore
            tokenRows.push({
              network: whitelistedTokenItem.network,
              tokenAddress: whitelistedTokenItem.tokenAddress,
              criteria: currentCriteria,
              filterType: 'attributes',
              id: whitelistedTokenItem.id,
            });
          }
        }
      }
    }
    setWhitelistedTokens(tokenRows);
  };

  interface ProductIdToRulesMap {
    productId: number;
    rules: any[];
  }
  /**
   * Helper Function that helps to build the whitelist token table
   */
  const parseRules = async (productIdToRulesMap: ProductIdToRulesMap) => {
    if (!productIdToRulesMap) return [];
    const rulesRows = [];
    let row: any = {};
    for (const [productId, rules] of Object.entries(productIdToRulesMap)) {
      if (!rules.length) continue;
      for (const rule of rules) {
        if (rule.restrictionType === CampaignShopifyRuleRestrictionType.UNRESTRICTED) {
          row = {
            id: rule.id,
            productId: productId,
            contract: 'null',
            restriction: 'None',
            discountPercent: rule.discountPercent,
          };
        } else if (rule.restrictionType === CampaignShopifyRuleRestrictionType.TOKEN) {
          row = {
            id: rule.id,
            productId: productId,
            contract: rule.restriction.tokenAddress,
            restriction: 'Contract',
            discountPercent: rule.discountPercent,
          };
        } else if (rule.restrictionType === CampaignShopifyRuleRestrictionType.TOKEN_ATTRIBUTE) {
          // Parse attribute Entries into a string
          let attributesString = '';
          for (const [i, attribute] of rule.restriction.attributeEntries.entries()) {
            if (i === 0) attributesString += `${attribute.traitType} - ${attribute.value}`;
            else attributesString += ` , ${attribute.traitType} - ${attribute.value}`;
          }
          row = {
            id: rule.id,
            productId: productId,
            contract: rule.restriction.tokenAddress,
            restriction: 'Attribute(s)',
            restrictionInfo: attributesString,
            discountPercent: rule.discountPercent,
          };
        } else if ((rule.restrictionType = CampaignShopifyRuleRestrictionType.TOKEN_RANGE)) {
          row = {
            id: rule.id,
            productId: productId,
            contract: rule.restriction.tokenAddress,
            restriction: 'Token Range',
            restrictionInfo: `${rule.restriction.minTokenId} - ${rule.restriction.maxTokenId}`,
            discountPercent: rule.discountPercent,
          };
        }
        rulesRows.push(row);
      }
    }
    setRules(rulesRows);
  };

  /**
   * Add a whitelisted token to the campaign.
   */
  const addTokenToCampaign = async (
    // event: React.FormEvent<HTMLFormElement>
    event: React.SyntheticEvent
  ) => {
    event.preventDefault();
    setTokenErrorMessage('');
    setTokenSuccessMessage('');

    if (!tokenAddress) {
      setTokenErrorMessage('Please add a contract address before submitting');
      return;
    } else {
      if (!whichTokens) {
        setTokenErrorMessage(
          'Please select a checkbox for how you want to whitelist the contract address'
        );
        return;
      }
      const storefrontContractAddresses = getStorefrontsForNetwork(network).map((x) => x.address);
      if (!isAdmin && tokenAddress in storefrontContractAddresses) {
        setTokenErrorMessage('Cannot use shared storefront address. ');
        return;
      }
    }

    setCreateTokenInProgress(true);
    // Make a request to create a token for each campaign token (full token address, range, single ID) they have whitelisted
    // Create the Token object to submit

    // If attribute specific tokens, then we make different call
    try {
      if (whichTokens === 'attributeTokens') {
        const tokenToSubmit: TokenFilteredByAttributes = {
          network: network,
          tokenAddress: tokenAddress,
          filterType: 'attributes',
          attributeEntries: [],
        };

        // Loop through the attributes in the attribute multi-select.
        for (const currentAttribute of selectedAttributeMulti) {
          // For each attribute Split the string to remove ' :: ' and get the
          const currentKeyValuePair = currentAttribute.split(' :: ');
          const currentTraitType = currentKeyValuePair[0];
          const currentTraitValue = currentKeyValuePair[1];
          tokenToSubmit.attributeEntries!.push({
            traitType: currentTraitType,
            value: currentTraitValue,
          });
        }

        // Create the "campaignTokenWithAtttribute" first
        await campaignAPI.addNewTokenFilteredByAttributesToAllowlist(campaignId, tokenToSubmit);
      } else if (whichTokens === 'allTokens') {
        const tokenToSubmit: TokenWithNoFilter = {
          network: network,
          tokenAddress,
          filterType: 'no_filter',
        };
        await campaignAPI.addNewTokenToAllowlist(campaignId, tokenToSubmit);
      } else if (whichTokens === 'rangeTokens') {
        const tokenToSubmit: TokenFilteredByIdRange = {
          network: network,
          tokenAddress,
          filterType: 'id_range',
          minTokenId: minToken!,
          maxTokenId: maxToken!,
        };
        await campaignAPI.addNewTokenToAllowlist(campaignId, tokenToSubmit);
      } else if (whichTokens === 'selectTokens') {
        const tokenToSubmit: TokenFilteredById = {
          network: network,
          tokenAddress,
          filterType: 'id',
          tokenId: tokenIndividual!,
        };
        await campaignAPI.addNewTokenToAllowlist(campaignId, tokenToSubmit);
      } else {
        throw new Error('Unknown filter type');
      }
      setTokenErrorMessage('');
      setTokenSuccessMessage('Successfully added a token to campaign');
    } catch (e: any) {
      console.log(
        'ERROR creating a new token. Make sure there is no new row in the Whitelisted Tokens table and try again.'
      );
      setTokenErrorMessage(e.message);
      setTokenSuccessMessage('');
    }

    // Clear the form after submitting
    setTokenAddress('');
    setWhichTokens('');
    setToUpdate(Math.floor(Math.random() * 1000000000000));
    setCreateTokenInProgress(false);
    setSelectedAttribute([]);
    setSelectedAttributeMulti([]);
    return;
  };

  // --- Question Handlers
  const whichQuestionSelector = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setWhichQuestion(event.currentTarget.value);
  };

  const addRequiredQuestion = async (event: React.SyntheticEvent) => {
    await addQuestionToCampaign(event, true);
  };

  const addOptionalQuestion = async (event: React.SyntheticEvent) => {
    await addQuestionToCampaign(event, false);
  };
  /**
   * Add a question to the campaign.
   */
  const addQuestionToCampaign = async (event: React.SyntheticEvent, required: boolean) => {
    event.preventDefault();
    setCreateQuestionInProgress(true);

    if (whichQuestion) {
      const questionToSubmit: Partial<Question> = {
        index: campaignQuestions.length + 1,
        required: required,
      };

      switch (whichQuestion) {
        case 'address':
          questionToSubmit['type'] = CampaignQuestion.ADDRESS;
          questionToSubmit['text'] = 'What is your address?';
          break;
        case 'twitter':
          questionToSubmit['type'] = CampaignQuestion.TWITTER;
          questionToSubmit['text'] = 'What is your Twitter @?';
          break;
        case 'phoneNumber':
          questionToSubmit['type'] = CampaignQuestion.PHONE_NUMBER;
          questionToSubmit['text'] = 'What is your phone Number?';
          break;
        case 'name':
          questionToSubmit['type'] = CampaignQuestion.NAME;
          questionToSubmit['text'] = 'What is your name?';
          break;
        case 'email':
          questionToSubmit['type'] = CampaignQuestion.EMAIL;
          questionToSubmit['text'] = 'What is your email?';
          break;
        case 'freeform':
          questionToSubmit['type'] = CampaignQuestion.CUSTOM;
          questionToSubmit['text'] = freeFormQuestion;
          break;
        case 'poll':
          questionToSubmit['type'] = CampaignQuestion.POLL;
          questionToSubmit['text'] = pollQuestionText;
          questionToSubmit['options'] = JSON.stringify(
            pollQuestions.map((pollq: any) => pollq.value)
          );
          break;
        case 'publicPoll':
          questionToSubmit['type'] = CampaignQuestion.PUBLIC_POLL;
          questionToSubmit['text'] = publicPollQuestionText;
          questionToSubmit['options'] = JSON.stringify(
            publicPollQuestions.map((pollq: any) => pollq.value)
          );
          break;
        case 'allowlist':
          questionToSubmit['type'] = CampaignQuestion.ALLOWLIST;
          questionToSubmit['text'] = `Sign up for allowlist?`;
          break;
        default:
          console.log('invalid question type');
          break;
      }

      try {
        setQuestionErrorMessage('');
        setQuestionSuccessMessage('Successfully added a question to campaign');
        await campaignAPI.addQuestion(campaign!.id, questionToSubmit);
      } catch (e: any) {
        setQuestionErrorMessage(e.message);
        setQuestionSuccessMessage('');
      }

      setToUpdate(Math.floor(Math.random() * 1000000000000));
      setWhichQuestion('');
      setCreateQuestionInProgress(false);
      return;
    }
  };

  const addCodeOption = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setCreateCodeOptionProgress(true);

    try {
      setCodeOptionErrorMessage('');
      setCodeOptionSuccessMessage('Successfully added an option to campaign');
      await campaignAPI.addCodeOption(
        campaign!.id,
        codeOptionDescription,
        codeOptionLimit ? parseInt(codeOptionLimit) : undefined
      );
    } catch (e: any) {
      setCodeOptionErrorMessage(e.message);
      setCodeOptionSuccessMessage('');
    }

    // Clear the form after submitting and refresh the page
    setCreateCodeOptionProgress(false);
    setCodeOptionDescription('');
    setCodeOptionLimit('');
    setToUpdate(Math.floor(Math.random() * 1000000000000));
    return;
  };

  const curDate = Date.now();

  const createRedemptionsCSV = (
    redemptions: Redemption[],
    redemptionColumnHeaders: string[],
    campaignAction: CampaignAction
  ) => {
    const table = getRedemptionSubmissionRows(redemptions, campaignAction);
    table.unshift(redemptionColumnHeaders);

    setCsvRedemptions(table);
  };

  const getRedemptionSubmissionRows = (
    redemptions: Redemption[],
    campaignAction: CampaignAction | undefined
  ): (string | number)[][] => {
    if (!campaignAction) return [[]];
    let index = 1;
    const rows: (string | number)[][] = [];
    for (const redemption of redemptions) {
      const newRow: (string | number)[] = [];
      newRow.push(index);
      newRow.push(redemption.network);
      newRow.push(redemption.tokenId);
      newRow.push(redemption.contractAddress);
      newRow.push(redemption.ownerAddress);
      newRow.push(redemption.redeemedAt ? redemption.redeemedAt : '');
      // Check if there are submissions for that redemption
      if (redemption.submissions?.length) {
        // For a redemptions of type CODE_WITH_QUESTIONS the redemption.submissions will be an array of submissions that looks like
        // [ {questionId: 1, answer: 'answer1'}, {questionId: 2, answer: 'answer2'}, ... , { scanned: true, scanTime: 1669247633750} ]
        // it has a length of n and has n-1 questions and the nth element shows the scan status
        if (campaignAction == CampaignAction.CODE_WITH_QUESTIONS) {
          // Loop through redemptions.submissions for the CODE_WITH_QUESTIONS type campaign
          for (let i = 0; i < redemption.submissions.length; i++) {
            const submission = redemption.submissions[i];
            // If the submission is the last element in the array, it is the scan status so add that
            if (i == redemption.submissions.length - 1) {
              newRow.push(submission.scanned ? 'true' : 'false');
              newRow.push(
                submission.scanTime
                  ? new Date(submission.scanTime).toISOString()
                  : 'not scanned yet'
              );
            } else {
              // otherwise the submission is an answer to a question so add that
              newRow.push(submission.answer);
            }
          }
        }

        for (const submission of redemption.submissions) {
          if ([CampaignAction.QUESTION].includes(campaignAction)) {
            newRow.push(submission.answer);
          } else if (campaignAction === CampaignAction.CODE) {
            newRow.push(submission.scanned);
            newRow.push(submission.scanDate);
            newRow.push(submission.optionId);
          } else if (
            [CampaignAction.SHOPIFY_DISCOUNT, CampaignAction.SHOPIFY_URL].includes(campaignAction)
          ) {
            newRow.push(submission.productId);
            newRow.push(submission.variantId);
            newRow.push(submission.completed?.toString() ?? '');
          }
        }
      }
      // add the newRow to the table
      rows.push(newRow);
      index += 1;
    }
    return rows;
  };

  useEffect(() => {
    let mounted = true;
    const wrapper = async () => {
      // Cannot retrieve if no cognito session
      if (!cognitoSession) return;

      // Get Campaign
      const campaign = await campaignAPI.fetchCampaign(campaignId);
      if (!campaign) {
        console.error(`Received empty value for campaign with id ${campaignId}: ${campaign}`);
        return;
      }

      // avoid updating state if component is unmounted
      if (mounted) {
        setCampaign(campaign);

        if (campaign.endDate && campaign.endDate < curDate) {
          setStatus('completed');
        } else if (campaign.startDate && campaign.startDate > curDate) {
          setStatus('scheduled');
        } else {
          setStatus('active');
        }

        const startDate = new Date(campaign.startDate || 0);
        setStartTime(startDate.getTime());
        if (campaign.startDate === 0) {
          setIsSnapshot(true);
        }

        let endDate;
        if (campaign.endDate) {
          endDate = new Date(campaign.endDate).getTime();
        }
        setEndTime(endDate);

        // @ts-ignore
        setPerWalletLimit(campaign.redemptionsPerAddress);

        // @ts-ignore
        setUpdatedPerWalletLimit(campaign.redemptionsPerAddress);

        if (campaign.snapshot) {
          setSnapshotTime(new Date(campaign?.snapshotData?.scheduledAt || 0).toString());
          setSnapshotUnixTimestamp(campaign?.snapshotData?.scheduledAt || 0);
          setSnapshotCutoffBlockNumbers(campaign?.snapshotData?.cutoffBlockNumbers);
        }

        const whitelistedTokens = campaign.tokenData;
        parseTokenData(whitelistedTokens); // helper function sets state

        // === Get Redemptions for the Campaign ===
        let redemptions: any[] = [];
        try {
          redemptions = await campaignAPI.fetchCampaignRedemptions(campaignId);
          setCampaignRedemptions(redemptions);
        } catch (e) {
          console.log('🍑 failed requesting redemptions: ', e);
        }

        // === Get Questions for the Campaign if Questionnaire Campaign ===
        const redemptionColumnHeaders = [
          'Index',
          'Network',
          'TokenId',
          'Contract Address',
          'Owner Address',
          'Redeemed At',
        ];

        if (
          [CampaignAction.QUESTION, CampaignAction.CODE_WITH_QUESTIONS].includes(campaign.action)
        ) {
          const questions = campaign.questions!;
          setCampaignQuestions(questions);
          for (const question of questions) {
            switch (question.type) {
              case CampaignQuestion.ADDRESS:
                redemptionColumnHeaders.push('Address');
                break;
              case CampaignQuestion.TWITTER:
                redemptionColumnHeaders.push('Twitter');
                break;
              case CampaignQuestion.PHONE_NUMBER:
                redemptionColumnHeaders.push('Phone #');
                break;
              case CampaignQuestion.NAME:
                redemptionColumnHeaders.push('Name');
                break;
              case CampaignQuestion.EMAIL:
                redemptionColumnHeaders.push('Email');
                break;
              case CampaignQuestion.POLL:
                redemptionColumnHeaders.push('Poll');
                break;
              case CampaignQuestion.PUBLIC_POLL:
                redemptionColumnHeaders.push('Public Poll');
                break;
              case CampaignQuestion.ALLOWLIST:
                redemptionColumnHeaders.push('AllowList');
                break;
              case CampaignQuestion.CUSTOM:
                redemptionColumnHeaders.push(question.text);
                break;
              default:
                console.log('invalid question type');
                break;
            }
          }
        }
        if ([CampaignAction.CODE].includes(campaign.action)) {
          const options = campaign.options!;
          setCampaignCodeOptions(options);
          redemptionColumnHeaders.push('Scanned');
          redemptionColumnHeaders.push('Scan Date');
          redemptionColumnHeaders.push('Option ID');
        } else if (campaign.action === CampaignAction.CODE_WITH_QUESTIONS) {
          redemptionColumnHeaders.push('Scanned');
          redemptionColumnHeaders.push('Scan Date');
        } else if (
          [CampaignAction.SHOPIFY_URL, CampaignAction.SHOPIFY_DELAYED].includes(campaign.action)
        ) {
          redemptionColumnHeaders.push('Product Id');
          redemptionColumnHeaders.push('Variant Id');
          redemptionColumnHeaders.push('Completed');
          setRedemptionTableColumnHeaders(redemptionColumnHeaders);
        }

        setRedemptionTableColumnHeaders(redemptionColumnHeaders);
        // Now set redemption table data since we know all the column headers and data
        if (redemptions.length > 0)
          await createRedemptionsCSV(redemptions, redemptionColumnHeaders, campaign.action);

        // ==== If Campaign is a Shopify URL get the shops and campaign products====
        if (
          [
            CampaignAction.SHOPIFY_URL,
            CampaignAction.SHOPIFY_DELAYED,
            CampaignAction.SHOPIFY_DISCOUNT,
          ].includes(campaign.action)
        ) {
          // Get shops
          const shops = await campaignAPI.fetchShopifyShops();
          setCampaignShops(shops);
        }
        if (
          [CampaignAction.SHOPIFY_URL, CampaignAction.SHOPIFY_DELAYED].includes(campaign.action)
        ) {
          // Get campaign products
          const products = await campaignAPI.fetchCampaignShopifyProducts(campaignId);
          setCampaignProducts(products);

          // get Rules on products
          const rules = await campaignAPI.fetchCampaignShopifyRules(campaignId);
          parseRules(rules as unknown as ProductIdToRulesMap);
        }
        if (campaign.action === CampaignAction.SHOPIFY_DISCOUNT) {
          // Get campaign price rule
          try {
            const priceRule = campaign.priceRule!;
            if (Object.keys(priceRule).length !== 0) {
              setCampaignPriceRuleId(priceRule.priceRuleId);
              setShop(priceRule.shop);
            }
          } catch (e) {
            console.log('Errror fetching price rule:  ', e);
          }
        }

        // === If Snapshot then retrieve snapshot data
        let getCampaignSnapshotResponse: any;
        if (campaign.snapshot === true) {
          try {
            // Try to fetch snapshot data
            getCampaignSnapshotResponse = await campaignAPI.fetchSnapshot(campaignId);

            const enc = new TextDecoder('utf-8');
            const data = await getCampaignSnapshotResponse.arrayBuffer();
            const dataJSON = enc.decode(data);

            const yo = Buffer.from(dataJSON, 'base64');

            unzip(yo, (err, data) => {
              if (err) {
                console.log('error unziping: ', err);
                setSnapshotErrorMessage([
                  'Snapshot Not Yet Available',
                  'Make sure to add Whitelisted Tokens below before snapshot date. If this step is complete your snapshot is still processing. \
                Snapshots can take up to 30 minutes after snapshot date to comlete. Once complete you will see a link to the final Snapshot CSV here.',
                ]);
                return;
              }
              const jsonString = Buffer.from(data).toString('utf8');

              const parsedData = JSON.parse(jsonString); // After first parse typeof parsedData === string
              setShowCSVDownload(true);
              setSnapshotData(parsedData);

              const allAddresses = parsedData.map((row: any) => row.ownerAddress);

              const merkleTreeLocal = new MerkleTree(allAddresses, keccak256, {
                hashLeaves: true,
                sortPairs: true,
              });

              setMerkleRoot(merkleTreeLocal.getHexRoot());
            });
          } catch (e) {
            setSnapshotErrorMessage([
              'Snapshot Not Yet Available:',
              'Make sure to add Whitelisted Tokens below before snapshot date. If this step is complete your snapshot is still processing. \
            Snapshots can take up to 30 minutes after snapshot date to comlete. Once complete you will see a link to the final Snapshot CSV here.',
            ]);
          }
        }
      }
    };
    wrapper();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [toUpdate, cognitoSession]);

  /** Retrieve Shopify Products on a store only if a store only if the store is set */
  useEffect(() => {
    let mounted = true;
    const wrapper = async () => {
      const shopBase = shop ? shop : shop_rule;
      if (campaign?.action === CampaignAction.SHOPIFY_DISCOUNT) {
        const priceRules = await campaignAPI.fetchShopifyPriceRules(shopBase);
        if (mounted) {
          setPriceRulesOnShop(priceRules.map((priceRule: { id: any }) => priceRule.id));
        }
      } else {
        // SHOP_URL
        try {
          const products = await campaignAPI.fetchShopifyProducts(shopBase);
          // console.log('👾 products.length: ', products.length);
          if (mounted) {
            setProductsInShop(products);
          }
        } catch (e) {
          if (mounted) {
            setShopifyProductErrorMessage(
              'There was a problem fetching the products on that store.'
            );
            setProductsInShop([]);
          }
        }
      }
    };
    if (shop || shop_rule) {
      wrapper();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop, shop_rule]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Grid
          container
          sx={{
            alignItems: 'left',
            width: '100%',
          }}
        >
          <Grid item xs={9}>
            <Typography
              component="h1"
              variant="h5"
              sx={{ marginBottom: 2 }}
              fontWeight="bold"
              fontSize={40}
            >
              {campaign?.name}
            </Typography>

            <div style={{ display: 'flex' }}>
              <Typography
                component="h1"
                variant="h6"
                sx={{ marginBottom: 2, marginRight: 1 }}
                fontWeight="bold"
              >
                ID:
              </Typography>
              <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                {campaign?.id}
              </Typography>
            </div>

            <div style={{ display: 'flex' }}>
              <Typography
                component="h1"
                variant="h6"
                sx={{ marginBottom: 2, marginRight: 1 }}
                fontWeight="bold"
              >
                Status:
              </Typography>
              <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                {status}
              </Typography>
            </div>

            <div style={{ display: 'flex' }}>
              <Typography
                component="h1"
                variant="h6"
                sx={{ marginBottom: 2, marginRight: 1 }}
                fontWeight="bold"
              >
                Start Date/Time:
              </Typography>
              <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                {!isSnapshot ? new Date(startTime || 0).toString() : 'N/a'}
              </Typography>
              {status === 'scheduled' ? (
                <Button onClick={saveOrEditStartTime}>{showEditStartTime ? 'Save' : 'Edit'}</Button>
              ) : null}
              {showEditStartTime && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Start Date"
                    value={startTime}
                    onChange={startTimeHandler}
                  />
                </LocalizationProvider>
              )}
            </div>

            <div style={{ display: 'flex' }}>
              <Typography
                component="h1"
                variant="h6"
                sx={{ marginBottom: 2, marginRight: 1 }}
                fontWeight="bold"
              >
                End Date/Time:
              </Typography>
              <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                {!isSnapshot ? (endTime ? new Date(endTime).toString() : 'Never') : 'N/a'}
              </Typography>
              <Button onClick={saveOrEditEndTime}>{showEditEndTime ? 'Save' : 'Edit'}</Button>
              {showEditEndTime && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="End Date"
                    value={endTime}
                    onChange={endTimeHandler}
                  />
                </LocalizationProvider>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <Typography
                component="h1"
                variant="h6"
                sx={{ marginBottom: 2, marginRight: 1 }}
                fontWeight="bold"
              >
                Campaign Type:
              </Typography>
              <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                {campaign?.snapshot === true ? 'Snapshot' : 'Live' || 'N/a'}
              </Typography>
            </div>

            {campaign?.snapshot && (
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ marginBottom: 2, marginRight: 1 }}
                    fontWeight="bold"
                  >
                    Snapshot Time:
                  </Typography>
                  <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                    {snapshotTime}
                  </Typography>
                </div>
              </div>
            )}

            {campaign?.snapshot && snapshotCutoffBlockNumbers && (
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{ marginBottom: 2, marginRight: 1 }}
                    fontWeight="bold"
                  >
                    Snapshot Cutoff Block:
                  </Typography>
                  <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                    {Object.keys(snapshotCutoffBlockNumbers!).map((key, index) => {
                      return (
                        <div>
                          Network {key}: {snapshotCutoffBlockNumbers![key]}
                        </div>
                      );
                    })}
                  </Typography>
                </div>
              </div>
            )}

            <div style={{ display: 'flex' }}>
              <Typography
                component="h1"
                variant="h6"
                sx={{ marginBottom: 2, marginRight: 1 }}
                fontWeight="bold"
              >
                Redemption Action:
              </Typography>
              <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                {!isSnapshot ? campaignActionToString(campaign?.action || 0) : 'N/A'}
              </Typography>
            </div>
            <div>
              <Button onClick={() => endCampaign()}>End Campaign</Button>
            </div>
          </Grid>
        </Grid>

        {campaign?.snapshot && (
          <div style={{ display: 'flex' }}>
            {showCSVDownload && (
              <>
                <div style={{ display: 'flex' }}>
                  <Button sx={{ mt: 3, mb: 2 }}>
                    <CSVLink
                      data={snapshotData}
                      enclosingCharacter={``}
                      filename={'campaign_snapshot_' + campaignId.toString() + '.csv'}
                    >
                      Download Snapshot
                    </CSVLink>
                  </Button>
                  MerkleRoot: {merkleRoot}
                </div>
              </>
            )}
            {snapshotErrorMessage ? (
              <div style={{ color: 'red' }}>
                <h1>{snapshotErrorMessage[0]}</h1>
                <p>{snapshotErrorMessage[1]}</p>
              </div>
            ) : null}
          </div>
        )}

        <br />
        <Grid
          container
          sx={{
            alignItems: 'left',
            width: '100%',
          }}
        >
          <Grid item xs={9}>
            <Typography
              component="h1"
              variant="h6"
              sx={{ marginBottom: 2 }}
              fontWeight="bold"
              fontSize={30}
            >
              Per wallet Limit: {perWalletLimit}
            </Typography>
            <TextField
              fullWidth
              type="number"
              value={updatedPerWalletLimit}
              autoFocus
              onChange={perWalletLimitHandler}
            />
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={savePerWalletLimit}
              // type="submit"
            >
              Save Limit
            </Button>
          </Grid>
        </Grid>
        {!campaign?.snapshot &&
        campaignActionToString(campaign?.action || 0) === 'Shopify URL' &&
        false ? null : (
          <Grid
            container
            sx={{
              alignItems: 'left',
              width: '100%',
            }}
          >
            <Grid item xs={9}>
              <Typography
                component="h1"
                variant="h6"
                sx={{ marginBottom: 2 }}
                fontWeight="bold"
                fontSize={30}
              >
                Whitelisted Tokens:
              </Typography>
              <form>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow key={campaign?.id}>
                        <TableCell>Network</TableCell>
                        <TableCell>Contract Address</TableCell>
                        <TableCell>Criteria</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {whitelistedTokens.map((whitelistedToken, index) => {
                        const tokenKey = `${whitelistedToken.network}_${whitelistedToken.tokenAddress}_${whitelistedToken.id}`;
                        return (
                          <Fragment>
                            {editTokenId === index ? (
                              <EditableRow key={tokenKey} index={index} />
                            ) : (
                              <ReadOnlyRow
                                whitelistedToken={whitelistedToken}
                                handleEditTokenClick={handleEditTokenClick}
                                handleDeleteTokenClick={handleDeleteTokenClick}
                                index={index}
                                currentTokenIndex={currentTokenIndex}
                                deleteTokenInProgress={deleteTokenInProgress}
                                key={tokenKey}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Add New token */}
                <br />
                <br />
                <div>
                  <Typography
                    component="h3"
                    variant="h6"
                    sx={{ marginBottom: 2 }}
                    fontWeight="bold"
                    fontSize={20}
                  >
                    Add New Token
                  </Typography>
                  <div>
                    <NetworkSelectDropdown
                      selectedNetwork={network}
                      setSelectedNetwork={setNetwork}
                    />
                    <TextField
                      // name="contractAddress"
                      required
                      fullWidth
                      label="Contract Address"
                      value={tokenAddress}
                      autoFocus
                      onChange={tokenAddressChangeHandler}
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="allTokens"
                          color="primary"
                          checked={whichTokens === 'allTokens'}
                        />
                      }
                      label="All Tokens"
                      id="allTokens"
                      name="allTokens"
                      onChange={whichTokensSelector}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="selectTokens"
                          color="primary"
                          checked={whichTokens === 'selectTokens'}
                        />
                      }
                      label="Select Individual Token"
                      id="selectTokens"
                      name="selectTokens"
                      onChange={whichTokensSelector}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="rangeTokens"
                          color="primary"
                          checked={whichTokens === 'rangeTokens'}
                        />
                      }
                      label="Token Range"
                      id="rangeTokens"
                      name="rangeTokens"
                      onChange={whichTokensSelector}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="attributeTokens"
                          color="primary"
                          checked={whichTokens === 'attributeTokens'}
                        />
                      }
                      label="Tokens by Attribute"
                      id="attributeTokens"
                      name="attributeTokens"
                      onChange={whichTokensSelector}
                    />
                    {whichTokens === 'selectTokens' && (
                      <TextField
                        name="token id"
                        required
                        fullWidth
                        label="Token ID"
                        autoFocus
                        onChange={setIndividualToken}
                      />
                    )}
                    {whichTokens === 'rangeTokens' && (
                      <>
                        <TextField
                          name="minToken"
                          required
                          fullWidth
                          label="Min Token"
                          autoFocus
                          onChange={setTokenMin}
                        />
                        <TextField
                          name="maxToken"
                          required
                          fullWidth
                          label="Max Token"
                          autoFocus
                          onChange={setTokenMax}
                        />
                      </>
                    )}
                    {whichTokens === 'attributeTokens' && (
                      <>
                        {/* HERE TODO */}
                        {/* <SelectAttributesGrouping nftAttributes={nftAttributes} setSelectedAttribute={setSelectedAttribute} /> */}
                        <AttributeMultiSelectDropdown
                          nftAttributes={nftAttributes}
                          selectedAttributeMulti={selectedAttributeMulti}
                          setSelectedAttributeMulti={setSelectedAttributeMulti}
                        />
                        <Typography
                          component="h2"
                          variant="h6"
                          sx={{ marginBottom: 2 }}
                          fontWeight="bold"
                          fontSize={20}
                        >
                          Not seeing attributes you expect? Click here to refresh token attributes
                          (may take a while)
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          onClick={refreshTokenMetadata}
                          // type="submit"
                        >
                          Refresh
                        </Button>
                      </>
                    )}
                    <br />
                    <Button
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={addTokenToCampaign}
                      // type="submit"
                    >
                      Add Token
                    </Button>
                    <div>
                      {tokenErrorMessage ? (
                        <div style={{ color: 'red' }}>Error: {tokenErrorMessage}</div>
                      ) : null}
                    </div>
                    <div>
                      {/* Todo spinning wheel */}
                      {createTokenInProgress ? (
                        <Box sx={{ display: 'flex' }}>
                          <CircularProgress />
                        </Box>
                      ) : null}
                      {tokenSuccessMessage ? (
                        <div style={{ color: 'green' }}>{tokenSuccessMessage}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            </Grid>
          </Grid>
        )}
        <br />
        <br />
        {campaignActionToString(campaign?.action || 0) === 'Shopify URL' ||
        campaignActionToString(campaign?.action || 0) === 'Shopify Delayed' ? (
          <div>
            <Grid
              container
              sx={{
                alignItems: 'left',
                width: '100%',
              }}
            >
              <Grid item xs={9}>
                <Typography
                  component="h1"
                  variant="h6"
                  sx={{ marginBottom: 2 }}
                  fontWeight="bold"
                  fontSize={30}
                >
                  Campaign Shopify Products
                </Typography>

                <form onSubmit={handleEditProductFormSubmit}>
                  <Button>Add Store to Campaign</Button>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow key={campaign?.id}>
                          <TableCell>Index</TableCell>
                          <TableCell>Image</TableCell>
                          <TableCell>Shop</TableCell>
                          <TableCell>Item Name</TableCell>
                          <TableCell>Product Id</TableCell>
                          <TableCell>Discount Percent (%)</TableCell>
                          <TableCell>Discount Amount ($)</TableCell>
                          <TableCell>Redemption Limit</TableCell>
                          <TableCell>Total Inventory</TableCell>
                          <TableCell>Consumed Inventory</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {campaignProducts.map((campaignProduct, index) => (
                          <Fragment>
                            {editProductTableId === index ? (
                              <EditableProductRow
                                key={index}
                                index={index}
                                campaignProduct={campaignProduct}
                                editProductFormData={editProductFormData}
                                handleEditProductFormChange={handleEditProductFormChange}
                                handleEditProductFormSubmit={handleEditProductFormSubmit}
                                handleCancelProductClick={handleCancelProductClick}
                              />
                            ) : (
                              <ReadOnlyProductRow
                                key={index}
                                index={index}
                                campaignProduct={campaignProduct}
                                handleEditProductClick={handleEditProductClick}
                                handleDeleteProductClick={handleDeleteProductClick}
                                currentProductIndex={currentProductIndex}
                                deleteProductInProgress={deleteProductInProgress}
                                active={status === 'active'}
                              />
                            )}
                          </Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </form>

                {/* Add New Store */}
                <br />
                <div>
                  <Typography
                    component="h3"
                    variant="h6"
                    sx={{ marginTop: 2 }}
                    fontWeight="bold"
                    fontSize={20}
                  >
                    Add Product
                  </Typography>
                  <br />
                  {showDiscountWarning && (
                    <>
                      <div style={{ color: 'red' }}>
                        WARNING:For a 0% product please make sure your store-level inventory is set
                        to 0, otherwise anyone could utilize the product ID to construct a purchase
                        URL and create an order. Also deselect "Track quantity" for item inventory
                        settings or product will display as SOLD OUT. Inventory tracking/limiting
                        for NFT-exclusive products can be done in the "Products" section of your
                        campaign if desired.
                      </div>
                      <br />
                    </>
                  )}
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="shopify-store">Shop</InputLabel>
                      <Select
                        labelId="shopify-store"
                        id="shopify-store"
                        value={shop}
                        label="Shop"
                        onChange={handleShopChange}
                        defaultValue={''}
                      >
                        {campaignShops.map((shop, index) => (
                          <MenuItem value={shop.toString()} key={index}>
                            {shop}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    {shop ? (
                      <div>
                        <FormControl fullWidth>
                          <InputLabel id="shopify-store-product">Product</InputLabel>
                          <Select
                            labelId="shopify-store-product"
                            id="shopify-store-product"
                            value={shopProductNameToAdd}
                            label="Product"
                            onChange={handleProductChange}
                          >
                            {productsInShop.map((product, index) => (
                              //@ts-ignore
                              <MenuItem value={product.title} key={index}>
                                {/* @ts-ignore */}
                                {product.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <br />
                        <br />
                        <Grid
                          item
                          xs={5}
                          style={{
                            display: 'flex',
                          }}
                        >
                          <TextField
                            style={{
                              display: 'inline-block',
                            }}
                            name="productDiscountPercent"
                            fullWidth
                            label="Discount %"
                            value={productDiscountPercent}
                            autoFocus
                            onChange={handleProductDiscountPercentChange}
                          />
                          <Typography
                            component="h1"
                            variant="h6"
                            sx={{ margin: 1 }}
                            fontWeight="bold"
                            fontSize={20}
                          >
                            OR
                          </Typography>
                          <TextField
                            style={{
                              display: 'inline-block',
                            }}
                            name="productDiscountAmount"
                            fullWidth
                            label="Discount Amount"
                            value={productDiscountAmount}
                            autoFocus
                            onChange={handleProductDiscountAmountChange}
                          />
                        </Grid>
                        <br />
                        <br />
                        <div style={{ display: 'flex' }}>
                          <TextField
                            name="productRedemptionLimit"
                            required
                            fullWidth
                            label="Redemption Limit"
                            value={productRedemptionLimit}
                            autoFocus
                            onChange={handleProductRedemptionLimitChange}
                          />
                          <Tooltip title="The number of product(s) a token holder can redeem per token at the discount.">
                            <IconButton>
                              <HelpIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <br />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="isCappedInventory"
                              color="primary"
                              checked={isCappedInventory}
                            />
                          }
                          label="Inventory is capped"
                          id="cappedInventory"
                          name="cappedInventory"
                          onChange={cappedInventorySelector}
                        />
                        <Tooltip title="Check this if you have a hard cap on the inventory of this product. If you are dropshipping this should NOT be checked.">
                          <IconButton>
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>

                        {isCappedInventory ? (
                          <div style={{ display: 'flex' }}>
                            <TextField
                              name="Inventory"
                              required
                              fullWidth
                              label="Inventory"
                              value={productInventory}
                              autoFocus
                              onChange={handleProductInventory}
                            />
                            <Tooltip title="The total quantity of the product you have available. If you are dropshipping a product set this to something very high Ex: 9999999">
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    <br />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={addProductToCampaign}
                    >
                      Add Product
                    </Button>
                  </div>
                  <div>
                    {shopifyProductErrorMessage ? (
                      <div style={{ color: 'red' }}>Failed: {shopifyProductErrorMessage}</div>
                    ) : null}
                  </div>
                  <div>
                    {/* Todo spinning wheel */}
                    {addProductInProgress ? (
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                      </Box>
                    ) : null}
                    {productSuccessMessage ? (
                      <div style={{ color: 'green' }}>{productSuccessMessage}</div>
                    ) : null}
                  </div>
                </div>
              </Grid>
            </Grid>

            <br />
            <br />
          </div>
        ) : null}

        {campaignActionToString(campaign?.action || 0) === 'Shopify Discount' ? (
          <div>
            <Grid
              container
              sx={{
                alignItems: 'left',
                width: '100%',
              }}
            >
              <Grid item xs={9}>
                <Typography
                  component="h1"
                  variant="h6"
                  sx={{ marginBottom: 2 }}
                  fontWeight="bold"
                  fontSize={30}
                >
                  Shopfiy Discount Codes
                </Typography>

                <form onSubmit={handleEditProductFormSubmit}>
                  <Button>Add Store to Campaign</Button>
                </form>

                {campaignPriceRuleId ? (
                  <div>
                    <ListRow
                      key={campaignPriceRuleId}
                      priceRuleId={campaignPriceRuleId}
                      handleDeletePriceRuleClick={handleDeletePriceRuleClick}
                    />
                  </div>
                ) : (
                  <div>
                    <div style={{ display: 'flex' }}>
                      {/* <p>
                        Link a Shopify Price Rule Id to your campaign to generate discount codes.
                      </p> */}
                      <Typography
                        component="h3"
                        variant="h6"
                        // sx={{ marginBottom: 2 }}
                        fontSize={20}
                      >
                        Link a Shopify Price Rule Id to your campaign to generate discount codes.
                      </Typography>
                      <Tooltip
                        sx={{ bottom: 4 }}
                        title='(!) Create a discount code in your shopify store and set "Usage limits" to "Limit to one use per customer" for price rule to appear below'
                      >
                        <IconButton>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div>
                      <br />
                      <div>
                        <FormControl fullWidth>
                          <InputLabel id="shopify-store">Shop</InputLabel>
                          <Select
                            labelId="shopify-store"
                            id="shopify-store"
                            value={shop}
                            label="Shop"
                            onChange={handleShopChange}
                            defaultValue={''}
                          >
                            {campaignShops.map((shop, index) => (
                              <MenuItem value={shop.toString()} key={index}>
                                {shop}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <br />
                      {shop ? (
                        <div>
                          <div style={{ display: 'flex' }}>
                            <FormControl fullWidth>
                              <InputLabel id="shopify-price-rule-id">Price Rule</InputLabel>
                              <Select
                                name="shopifyPriceRuleId"
                                fullWidth
                                labelId="shopify-price-rule-id"
                                id="shopify-price-rule-id"
                                value={priceRuleId}
                                label="Price Rule Id"
                                autoFocus
                                onChange={handlePriceRuleChange}
                                defaultValue={''}
                              >
                                {priceRulesOnShop.map((priceRule, index) => (
                                  <MenuItem value={priceRule.toString()} key={index}>
                                    {priceRule}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Tooltip title="This is the price rule you need to create for a store within shopify. This shows all available price rules on a selected store.">
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <br />
                          <div style={{ display: 'flex' }}>
                            <TextField
                              name="productPriceRuleDescirption"
                              required
                              fullWidth
                              label="Redemption message"
                              value={priceRuleDescription}
                              autoFocus
                              onChange={handlePriceRuleDescriptionChange}
                            />
                            <Tooltip title="This will be the message shown to token holders when they claim the discount.">
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      ) : null}
                      <br />
                    </div>
                    <div>
                      <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={addPriceRuleId}>
                        Add Price Rule Id
                      </Button>
                    </div>
                    <div>
                      {shopifyPriceRuleErrorMessage ? (
                        <div style={{ color: 'red' }}>Failed: {shopifyPriceRuleErrorMessage}</div>
                      ) : null}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>

            <br />
            <br />
          </div>
        ) : null}

        {/* Conditionally Render Rules Section */}
        {campaignActionToString(campaign?.action || 0) === 'Shopify URL' && false ? (
          <div>
            <Grid
              container
              sx={{
                alignItems: 'left',
                width: '100%',
              }}
            >
              <Grid item xs={9}>
                <Typography
                  component="h1"
                  variant="h6"
                  sx={{ marginBottom: 2 }}
                  fontWeight="bold"
                  fontSize={30}
                >
                  Rules
                </Typography>

                <form onSubmit={handleEditProductFormSubmit}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow key={campaign?.id}>
                          <TableCell>Index</TableCell>
                          <TableCell>Product Id</TableCell>
                          <TableCell>Contract</TableCell>
                          <TableCell>Restriction</TableCell>
                          <TableCell>Restriction Info</TableCell>
                          <TableCell>Discount</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rules.map((rule, index) => (
                          <Fragment key={rule.id}>
                            <TableRow
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell>{rule.productId}</TableCell>
                              <TableCell>{rule.contract}</TableCell>
                              <TableCell>{rule.restriction}</TableCell>
                              <TableCell>
                                {rule.restrictionInfo ? rule.restrictionInfo : 'None'}
                              </TableCell>
                              <TableCell>{rule.discountPercent}%</TableCell>
                              <TableCell>
                                {deleteRuleInProgress && currentRuleIndex === index ? (
                                  <Box>
                                    <CircularProgress />
                                  </Box>
                                ) : (
                                  <Button onClick={() => handleDeleteRuleClick(index)}>
                                    Delete
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </form>

                {/* Add New Store */}
                <br />
                <div>
                  <Typography
                    component="h3"
                    variant="h6"
                    sx={{ marginTop: 2 }}
                    fontWeight="bold"
                    fontSize={20}
                  >
                    Add New Rule to a Product
                  </Typography>
                  <br />
                  <div>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="shopify-store-product_rule">Product</InputLabel>
                        <Select
                          labelId="shopify-store-product_rule"
                          id="shopify-store-product_rule"
                          value={shopProductIdToAdd_rule}
                          label="Product"
                          onChange={handleProductChange_rule}
                        >
                          {campaignProducts.map((product, index) => (
                            //@ts-ignore
                            <MenuItem value={product.productId} key={index}>
                              {/* @ts-ignore */}
                              {product.productId}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <br />
                      <br />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="isRestrictedRule"
                            color="primary"
                            checked={isRestrictedRule}
                          />
                        }
                        label="Restricted Rule"
                        id="restirctedRule"
                        name="restrictedRule"
                        onChange={restrictedRuleSelector}
                      />
                      <Tooltip title="Check if you want to create a rule that is only open to specific token holders.">
                        <IconButton>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                      <br />
                      <br />
                      <TextField
                        name="productDiscountPercent_rule"
                        required
                        fullWidth
                        label="Discount %"
                        value={productDiscountPercent_rule}
                        autoFocus
                        onChange={handleProductDiscountPercentChange_rule}
                      />
                      <TextField
                        name="productDiscountAmount_rule"
                        required
                        fullWidth
                        label="Discount Amount"
                        value={productDiscountAmount_rule}
                        autoFocus
                        onChange={handleProductDiscountAmountChange_rule}
                      />
                    </div>

                    <br />
                  </div>
                  <div>
                    {shopProductIdToAdd_rule && isRestrictedRule ? (
                      <div>
                        <TextField
                          // name="contractAddress"
                          required
                          fullWidth
                          label="Contract Address"
                          value={tokenAddress_rule}
                          autoFocus
                          onChange={tokenAddressChangeHandler_rule}
                        />

                        <br />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="allTokens"
                              color="primary"
                              checked={whichTokens_rule === 'allTokens'}
                            />
                          }
                          label="All Tokens"
                          id="allTokens"
                          name="allTokens"
                          onChange={whichTokensSelector_rule}
                        />
                        {/* <FormControlLabel
                          control={
                            <Checkbox
                              value="selectTokens"
                              color="primary"
                              checked={whichTokens_rule === 'selectTokens'}
                            />
                          }
                          label="Select Individual Token"
                          id="selectTokens"
                          name="selectTokens"
                          onChange={whichTokensSelector_rule}
                        /> */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="rangeTokens"
                              color="primary"
                              checked={whichTokens_rule === 'rangeTokens'}
                            />
                          }
                          label="Token Range"
                          id="rangeTokens"
                          name="rangeTokens"
                          onChange={whichTokensSelector_rule}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="attributeTokens"
                              color="primary"
                              checked={whichTokens_rule === 'attributeTokens'}
                            />
                          }
                          label="Tokens by Attribute"
                          id="attributeTokens"
                          name="attributeTokens"
                          onChange={whichTokensSelector_rule}
                        />
                        {whichTokens_rule === 'selectTokens' && (
                          <TextField
                            name="token id"
                            required
                            fullWidth
                            label="Token ID"
                            autoFocus
                            onChange={setIndividualToken_rule}
                          />
                        )}
                        {whichTokens_rule === 'rangeTokens' && (
                          <>
                            <TextField
                              name="minToken"
                              required
                              fullWidth
                              label="Min Token"
                              autoFocus
                              onChange={setTokenMin_rule}
                            />
                            <TextField
                              name="maxToken"
                              required
                              fullWidth
                              label="Max Token"
                              autoFocus
                              onChange={setTokenMax_rule}
                            />
                          </>
                        )}
                        {whichTokens_rule === 'attributeTokens' && (
                          <>
                            {/* HERE TODO */}
                            {/* <SelectAttributesGrouping nftAttributes={nftAttributes} setSelectedAttribute={setSelectedAttribute} /> */}
                            <AttributeMultiSelectDropdown
                              nftAttributes={nftAttributes_rule}
                              selectedAttributeMulti={selectedAttributeMulti_rule}
                              setSelectedAttributeMulti={setSelectedAttributeMulti_rule}
                            />
                            <Typography
                              component="h2"
                              variant="h6"
                              sx={{ marginBottom: 2 }}
                              fontWeight="bold"
                              fontSize={20}
                            >
                              Not seeing attributes you expect? Click here to refresh token
                              attributes (may take a while)
                            </Typography>
                            <Button
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                              onClick={refreshTokenMetadata}
                              // type="submit"
                            >
                              Refresh
                            </Button>
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>

                  {showDiscountWarning_rule && (
                    <>
                      <div style={{ color: 'red' }}>
                        WARNING: If you are gating a product with no discount, please make sure your
                        store-level inventory is set to 0, "Track quantity" is true and you have
                        removed the item from your online store, otherwise anyone could utilize the
                        product ID to construct a purchase URL and create an order. Inventory
                        tracking/limiting for NFT-exclusive products can be done in the "Products"
                        section of your campaign if desired.
                      </div>
                      <br />
                    </>
                  )}

                  <div>
                    <Button
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={addRuleToProductOnCampaign}
                    >
                      Add Rule
                    </Button>
                  </div>
                  <div>
                    {ruleErrorMessage ? (
                      <div style={{ color: 'red' }}>Failed: {ruleErrorMessage}</div>
                    ) : null}
                  </div>
                  <div>
                    {/* Todo spinning wheel */}
                    {addRuleInProgress ? (
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                      </Box>
                    ) : null}
                    {ruleSuccessMessage ? (
                      <div style={{ color: 'green' }}>{ruleSuccessMessage}</div>
                    ) : null}
                  </div>
                </div>
              </Grid>
            </Grid>

            <br />
            <br />
          </div>
        ) : null}

        {/* Conditionally Render Campaign Progress Section */}
        <div>
          <Grid
            container
            sx={{
              alignItems: 'left',
              width: '100%',
            }}
          >
            <Grid item xs={9}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ marginBottom: 2 }}
                fontWeight="bold"
                fontSize={30}
              >
                Campaign Progress
              </Typography>
              <div style={{ display: 'flex' }}>
                <Typography
                  component="h1"
                  variant="h6"
                  sx={{ marginBottom: 2, marginRight: 1 }}
                  fontWeight="bold"
                >
                  Redemptions:
                </Typography>
                <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                  {campaign?.redemptionCount}{' '}
                  {campaign?.maxRedemptions ? '/' + campaign?.maxRedemptions : ''}
                </Typography>
              </div>
              {/* {<div>{JSON.stringify(campaignRedemptions)}</div>} */}
              <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                Data:
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow key={campaign?.id}>
                      {redemptionTableColumnHeaders.map((columnHeader, index) => (
                        <TableCell key={index}>{columnHeader}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getRedemptionSubmissionRows(campaignRedemptions, campaign?.action)?.map(
                      (row, index) => (
                        <Fragment key={`redemption-${index}`}>
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            {row.map((value, index) => (
                              <TableCell key={redemptionTableColumnHeaders[index]}>
                                {value}
                              </TableCell>
                            ))}
                          </TableRow>
                        </Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Button>
            {campaignRedemptions.length > 0 && (
              <CSVLink
                data={csvRedemptions}
                enclosingCharacter={``}
                filename={'campaign_redemptions_' + campaignId.toString() + '.csv'}
              >
                Download as CSV
              </CSVLink>
            )}
          </Button>
        </div>
        {/* Conditionally Render Questions Table if there are questions */}
        {/* {campaignActionToString(campaign?.action || 0) === 'Questionnaire' ? ( */}
        {[CampaignAction.QUESTION, CampaignAction.CODE_WITH_QUESTIONS].includes(
          campaign?.action || 0
        ) ? (
          <div>
            <br />
            <br />
            <Grid
              container
              sx={{
                alignItems: 'left',
                width: '100%',
              }}
            >
              <Grid item xs={9}>
                <Typography
                  component="h1"
                  variant="h6"
                  sx={{ marginBottom: 2 }}
                  fontWeight="bold"
                  fontSize={30}
                >
                  Questions:
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow key={campaign?.id}>
                        <TableCell>index</TableCell>
                        <TableCell>Question Text</TableCell>
                        <TableCell>Required</TableCell>
                        <TableCell>Options</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {campaignQuestions.map((question) => (
                        <TableRow
                          key={question.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {question.index}
                          </TableCell>
                          <TableCell>{question.text}</TableCell>
                          <TableCell>{question.required ? 'true' : 'false'}</TableCell>
                          <TableCell>
                            {question.options ? question.options : 'free response'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {/* Add New Question */}
            <div>
              <h3>Add Question</h3>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox value="name" color="primary" checked={whichQuestion === 'name'} />
                  }
                  label="Name"
                  id="name"
                  name="name"
                  onChange={whichQuestionSelector}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="twitter"
                      color="primary"
                      checked={whichQuestion === 'twitter'}
                    />
                  }
                  label="Twitter"
                  id="twitter"
                  name="twitter"
                  onChange={whichQuestionSelector}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="address"
                      color="primary"
                      checked={whichQuestion === 'address'}
                    />
                  }
                  label="Address"
                  id="address"
                  name="address"
                  onChange={whichQuestionSelector}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="phoneNumber"
                      color="primary"
                      checked={whichQuestion === 'phoneNumber'}
                    />
                  }
                  label="Phone Number"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={whichQuestionSelector}
                />
                <FormControlLabel
                  control={
                    <Checkbox value="email" color="primary" checked={whichQuestion === 'email'} />
                  }
                  label="Email"
                  id="email"
                  name="email"
                  onChange={whichQuestionSelector}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="allowlist"
                      color="primary"
                      checked={whichQuestion === 'allowlist'}
                    />
                  }
                  label="AllowList"
                  id="allowlist"
                  name="allowlist"
                  onChange={whichQuestionSelector}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="freeform"
                      color="primary"
                      checked={whichQuestion === 'freeform'}
                    />
                  }
                  label="FreeForm"
                  id="freeform"
                  name="freeform"
                  onChange={whichQuestionSelector}
                />
                <FormControlLabel
                  control={
                    <Checkbox value="poll" color="primary" checked={whichQuestion === 'poll'} />
                  }
                  label="Poll"
                  id="poll"
                  name="poll"
                  onChange={whichQuestionSelector}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="publicPoll"
                      color="primary"
                      checked={whichQuestion === 'publicPoll'}
                    />
                  }
                  label="Public Poll"
                  id="publicPoll"
                  name="publicPoll"
                  onChange={whichQuestionSelector}
                />
                {whichQuestion === 'poll' && (
                  <>
                    {
                      <>
                        <TextField
                          key={'pollQuestionText'}
                          required
                          fullWidth
                          label={'Question Text'}
                          value={pollQuestionText}
                          autoFocus
                          onChange={pollQuestionTextHandler}
                          name={'pollQuestionText'}
                          sx={{ mb: 2 }}
                        />
                        {pollQuestions.map((pollQuestion, index) => (
                          <TextField
                            key={index}
                            required
                            fullWidth
                            label={pollQuestion.label}
                            value={pollQuestion.value}
                            autoFocus
                            onChange={pollQuestionHandler}
                            name={`${index + 1}`}
                          />
                        ))}

                        <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={addPollOption}>
                          Add Option
                        </Button>
                      </>
                    }
                  </>
                )}
                {whichQuestion === 'publicPoll' && (
                  <>
                    {
                      <>
                        <TextField
                          key={'publicPollQuestionText'}
                          required
                          fullWidth
                          label={'Question Text'}
                          value={publicPollQuestionText}
                          autoFocus
                          onChange={publicPollQuestionTextHandler}
                          name={'publicPollQuestionText'}
                          sx={{ mb: 2 }}
                        />
                        {publicPollQuestions.map((pollQuestion, index) => (
                          <TextField
                            key={index}
                            required
                            fullWidth
                            label={pollQuestion.label}
                            value={pollQuestion.value}
                            autoFocus
                            onChange={publicPollQuestionHandler}
                            name={`${index + 1}`}
                          />
                        ))}

                        <Button
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          onClick={addPublicPollOption}
                        >
                          Add Option
                        </Button>
                      </>
                    }
                  </>
                )}
                {whichQuestion === 'freeform' && (
                  <TextField
                    required
                    fullWidth
                    label="Example question"
                    value={freeFormQuestion}
                    autoFocus
                    onChange={freeFormQuestionHandler}
                  />
                )}
              </div>
              <div>
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2, mr: 2 }}
                  onClick={addOptionalQuestion}
                >
                  Add Optional Question
                </Button>
                <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={addRequiredQuestion}>
                  Add Required Question
                </Button>
              </div>
              <div>
                {questionErrorMessage ? (
                  <div style={{ color: 'red' }}>Failed: {questionErrorMessage}</div>
                ) : null}
              </div>
              <div>
                {createQuestionInProgress ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                ) : null}
                {questionSuccessMessage ? (
                  <div style={{ color: 'green' }}>{questionSuccessMessage}</div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        {/* Conditionally Render Code Options Table & Creation Form if there are code options */}
        {[CampaignAction.CODE].includes(campaign?.action || 0) ? (
          <div>
            <Grid
              container
              sx={{
                alignItems: 'left',
                width: '100%',
              }}
            >
              <Grid item xs={9}>
                <Typography
                  component="h1"
                  variant="h6"
                  sx={{ marginBottom: 2 }}
                  fontWeight="bold"
                  fontSize={30}
                >
                  Options:
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow key={campaign?.id}>
                        <TableCell>Option ID</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Limit</TableCell>
                        <TableCell>Redeemed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {campaignCodeOptions.map((option) => (
                        <TableRow
                          key={option.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell>{option.id}</TableCell>
                          <TableCell>{option.description}</TableCell>
                          <TableCell>{option.limit ? option.limit : ''}</TableCell>
                          <TableCell>{option.redeemed}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {/* Add New Option */}
            <div>
              <h3>Add Option</h3>
              <div>
                <TextField
                  required
                  fullWidth
                  label="Description"
                  value={codeOptionDescription}
                  autoFocus
                  onChange={handleCodeOptionDescriptionChange}
                />
                <TextField
                  required
                  fullWidth
                  label="Limit"
                  value={codeOptionLimit}
                  onChange={handleCodeOptionLimitChange}
                />
              </div>
              <div>
                <Button variant="contained" sx={{ mt: 3, mb: 2, mr: 2 }} onClick={addCodeOption}>
                  Add
                </Button>
              </div>
              <div>
                {codeOptionErrorMessage ? (
                  <div style={{ color: 'red' }}>Failed: {codeOptionErrorMessage}</div>
                ) : null}
              </div>
              <div>
                {createCodeOptionInProgress ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                ) : null}
                {codeOptionSuccessMessage ? (
                  <div style={{ color: 'green' }}>{codeOptionSuccessMessage}</div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </Container>
    </ThemeProvider>
  );
};
