import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import setSeconds from 'date-fns/setSeconds';
import { useState } from 'react';
import { useCampaignAPI } from '../../hooks/useCampaignAPI';
import { Campaign, CampaignAction } from '../../utils/interfaces/campaign';

const theme = createTheme();

interface NewAppProps {
  cognitoSession: CognitoUserSession | null;
}

export const NewCampaign = ({ cognitoSession }: NewAppProps) => {
  const { campaignAPI } = useCampaignAPI();
  const [campaignName, setCampaignName] = useState('');

  const [actionTypeSelected, setActionTypeSelected] = useState<number>();
  const [whichQuestions, setWhichQuestions] = useState<string[]>([]);
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  const [snapshotTime, setSnapshotTime] = useState<number>();
  const [isSnapshot, setIsSnapshot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [explicitNoEndDate, setExplicitNoEndDate] = useState(false);

  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [dateErrorMessage, setDateErrorMessage] = useState('');
  const [snapshotErrorMessage, setSnapshotErrorMessage] = useState('');
  const [actionErrorMessage, setActionErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [failMessage, setFailMessage] = useState('');

  const campaignNameHandler = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setCampaignName(event.currentTarget.value);
  };

  const startTimeHandler = (event: any) => {
    setStartTime(event.getTime());
  };

  const endTimeHandler = (event: any) => {
    setEndTime(event.getTime());
  };

  const snapshotTimeHandler = (event: any) => {
    setSnapshotTime(event.getTime());
  };

  const actionTypeSelector = (event: React.SyntheticEvent) => {
    // @ts-ignore;
    const value = event.currentTarget.value;
    if (value !== 1) {
      setWhichQuestions([]);
    }
    console.log('value: ', value, typeof value);
    setActionTypeSelected(parseInt(value));
  };

  const snapshotSelector = (event: React.SyntheticEvent) => {
    // @ts-ignore
    if (event.currentTarget.value === 'true') {
      setIsSnapshot(false);
      setSnapshotTime(undefined);
    } else {
      setIsSnapshot(true);
    }
  };

  const explicitNoEndDateSelector = (event: React.SyntheticEvent) => {
    // @ts-ignore
    if (event.currentTarget.value === 'true') {
      setExplicitNoEndDate(false);
    } else {
      setExplicitNoEndDate(true);
    }
  };

  const validate = async () => {
    let foundError = false;
    let fullTimeError = '';
    let fullSnapshotError = '';
    const current = Date.now();

    // Validate campaign name
    if (!campaignName) {
      foundError = true;
      setNameErrorMessage('Please enter a name for your campaign');
    } else {
      setNameErrorMessage('');
    }

    if (!startTime) {
      foundError = true;
      fullTimeError += 'Please enter a start date.';
    }

    if (startTime! < current) {
      foundError = true;
      fullTimeError += 'Start date must be in the future.';
    }
    if (endTime && endTime < current) {
      foundError = true;
      fullTimeError += 'End date cannot be in the past.';
    }
    if (isSnapshot && snapshotTime && snapshotTime < current) {
      foundError = true;
      fullSnapshotError += '\n Snapshot time cannot be in the past.';
    }

    // Validate start and end time
    console.log(
      'actionTypeSelected !== CampaignAction.NONE: ',
      actionTypeSelected !== CampaignAction.NONE
    );
    if (actionTypeSelected !== CampaignAction.NONE) {
      if (!endTime && !explicitNoEndDate) {
        fullTimeError += '\n Please enter an end date.';
      }
      if (endTime! <= startTime!) {
        fullTimeError += '\n End Date cannot be before Start Date';
      }
    }

    if (fullTimeError) {
      foundError = true;
      setDateErrorMessage(fullTimeError);
    } else {
      setDateErrorMessage('');
    }

    // Validate that snapshot
    if (isSnapshot! && !snapshotTime) {
      fullSnapshotError += '\n snapshot chosen but snapshot date is not set.';
    }
    // Validate that snapshot is at least 60 minutes before start date
    if (isSnapshot && snapshotTime! + 60 * 60 * 1000 >= startTime!) {
      fullSnapshotError +=
        '\n Snapshot time must come before the Start date by atleast 60 minutes.';
    }
    if (actionTypeSelected === CampaignAction.NONE && !isSnapshot) {
      fullSnapshotError += '\n Action type None must have snapshot selected';
    }

    if (fullSnapshotError) {
      console.log('fullSnapshotError: ', fullSnapshotError);
      foundError = true;
      setSnapshotErrorMessage(fullSnapshotError);
    } else {
      setSnapshotErrorMessage('');
    }

    // Validate action type
    // Make sure a user has checked an action type. Since
    // CampaignAction.None === 0 (in the case we want the campaing to just be a snapshot)
    // we should only be checking if the actionTypeSelected is undefined not false.
    if (actionTypeSelected === undefined) {
      foundError = true;
      setActionErrorMessage('Please select an action type');
    } else {
      setActionErrorMessage('');
    }
    if (foundError) {
      return false;
    }

    return true;
  };

  const newCampaignSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = await validate();
    if (isValid) {
      setLoading(true);
      // Create the campaign object to submit..
      const campaignToSubmit: Partial<Campaign> = {
        name: campaignName,
        snapshot: isSnapshot,
        startDate: startTime ? startTime : Date.now(),
        endDate: explicitNoEndDate ? null : endTime,
        action: actionTypeSelected!,
      };
      // conditionally add the snapshotTime if snapshot is set
      if (isSnapshot && snapshotTime) {
        campaignToSubmit['snapshotDate'] = snapshotTime;
      }

      // -- Make the request to create the campaign --
      try {
        await campaignAPI.createCampaign(campaignToSubmit);
        setLoading(false);
        setDone(true);
        setSuccessMessage('Successfully Created Campaign!');
      } catch (e: any) {
        setFailMessage('Something went wrong. Please try again.');
        setLoading(false);
        setDone(true);
        throw new Error('Campaign could not be created');
      }
    }
    return;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
          noValidate
          autoComplete="off"
          onSubmit={newCampaignSubmit}
        >
          <Grid
            container
            sx={{
              alignItems: 'left',
              width: '100%',
            }}
          >
            <Grid item xs={6}>
              {!done && (
                <>
                  <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
                    New Campaign
                  </Typography>
                  <TextField
                    name="campaignName"
                    required
                    fullWidth
                    label="Campaign Name"
                    autoFocus
                    value={campaignName}
                    onChange={campaignNameHandler}
                  />
                </>
              )}
              {done && (
                <Typography component="h6" variant="h6">
                  Name: {campaignName}
                </Typography>
              )}
              <div>
                {nameErrorMessage ? <div style={{ color: 'red' }}>{nameErrorMessage}</div> : null}
              </div>
              <br />
              <br />
              {!done && (
                <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label="Start Date"
                      value={setSeconds(startTime || Date.now(), 0)}
                      onChange={startTimeHandler}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label="End Date"
                      value={setSeconds(endTime || Date.now(), 0)}
                      onChange={endTimeHandler}
                    />
                  </LocalizationProvider>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={explicitNoEndDate}
                        color="primary"
                        // eslint-disable-next-line
                        checked={explicitNoEndDate === true}
                      />
                    }
                    label="No End Date"
                    id="explicitNoEndDate"
                    name="explicitNoEndDate"
                    onChange={explicitNoEndDateSelector}
                  />
                </>
              )}
              {done && (
                <>
                  <Typography component="h6" variant="h6">
                    Start Time: {new Date(startTime!).toString()}
                  </Typography>
                  <Typography component="h6" variant="h6">
                    End Time: {new Date(endTime!).toString()}
                  </Typography>
                </>
              )}
              <div>
                {dateErrorMessage ? <div style={{ color: 'red' }}>{dateErrorMessage}</div> : null}
              </div>
              <br />
              <br />
              <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                Snapshot Campaign:
              </Typography>
              <div>
                <div>
                  {!done && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={isSnapshot}
                          color="primary"
                          // eslint-disable-next-line
                          checked={isSnapshot === true}
                        />
                      }
                      label="snapshot"
                      id="snapshot"
                      name="snapshot"
                      onChange={snapshotSelector}
                    />
                  )}
                  {done && (
                    <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                      {isSnapshot === true ? 'True' : 'False'}
                    </Typography>
                  )}
                </div>
              </div>
              <div>
                {
                  // eslint-disable-next-line
                  isSnapshot ? (
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="Snapshot Date"
                          value={setSeconds(snapshotTime || Date.now(), 0)}
                          onChange={snapshotTimeHandler}
                        />
                      </LocalizationProvider>
                    </div>
                  ) : null
                }
              </div>
              <div>
                {snapshotErrorMessage ? (
                  <div style={{ color: 'red' }}>{snapshotErrorMessage}</div>
                ) : null}
              </div>
              <br />
              <br />

              <Typography component="h1" variant="h6" sx={{ marginBottom: 2 }}>
                Action Type:
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    value={CampaignAction.QUESTION}
                    color="primary"
                    // eslint-disable-next-line
                    checked={actionTypeSelected === CampaignAction.QUESTION}
                    disabled={done}
                  />
                }
                label="Questionnaire"
                id="question"
                name="question"
                onChange={actionTypeSelector}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={CampaignAction.CODE}
                    color="primary"
                    // eslint-disable-next-line
                    checked={actionTypeSelected === CampaignAction.CODE}
                    disabled={done}
                  />
                }
                label="QR Code"
                id="code"
                name="code"
                onChange={actionTypeSelector}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={CampaignAction.CODE_WITH_QUESTIONS}
                    color="primary"
                    // eslint-disable-next-line
                    checked={actionTypeSelected === CampaignAction.CODE_WITH_QUESTIONS}
                    disabled={done}
                  />
                }
                label="QR Code with Questions"
                id="codeWithQuestions"
                name="codeWithQuestions"
                onChange={actionTypeSelector}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value={CampaignAction.NONE}
                    color="primary"
                    // eslint-disable-next-line
                    checked={actionTypeSelected === CampaignAction.NONE}
                    disabled={done}
                  />
                }
                label="None"
                id="None"
                name="None"
                onChange={actionTypeSelector}
              />
              <div>
                {actionErrorMessage ? (
                  <div style={{ color: 'red' }}>{actionErrorMessage}</div>
                ) : null}
              </div>
              {loading && !done && <CircularProgress />}
              {!loading && !done && (
                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Create
                </Button>
              )}
              <div style={{ color: 'green' }}>{successMessage ? successMessage : null}</div>
              <div style={{ color: 'red' }}>{failMessage ? failMessage : null}</div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
