import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CognitoUser } from 'amazon-cognito-identity-js';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCognito } from '../../hooks/useCognito';
import { formatUsername } from '../../utils/formatUsername';
import { Copyright } from '../common/Copyright';
import { ConfirmReset } from './ConfirmReset';
import { ConfirmSignup } from './ConfirmSignup';

const theme = createTheme();

export const Reset = () => {
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [showConfirmSignup, setShowConfirmSignup] = useState(false);
  const [userName, setUserName] = useState('');
  const { userPool } = useCognito();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email')!.toString();
    const username = formatUsername(email);

    const dataUserName = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(dataUserName);
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        setUserName(cognitoUser.getUsername());
        setShowConfirmReset(true);
      },
      onFailure: function (err) {
        if ((err as any).code === 'InvalidParameterException') {
          cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
              alert(err.message || JSON.stringify(err));
              return;
            }
            setUserName(cognitoUser.getUsername());
            setShowConfirmSignup(true);
            setUserName(cognitoUser.getUsername());
          });
        } else {
          alert(err.message || JSON.stringify(err));
        }
      },
    });
  };

  if (showConfirmReset) {
    return <ConfirmReset userPool={userPool} userName={userName} />;
  }
  if (showConfirmSignup) {
    return <ConfirmSignup userPool={userPool} userName={userName} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Reset
            </Button>
            <Grid container>
              <Grid item>
                <Link component={RouterLink} to="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};
