import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export const Copyright = (props: any) => {
  return (
    <>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://manifold.xyz/">
          Manifold
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        <Link
          color="inherit"
          href="https://manifoldxyz.notion.site/Privacy-Policy-bfc497773ea048d08dfa96644cb48adc"
        >
          Privacy Policy
        </Link>
      </Typography>
    </>
  );
};
