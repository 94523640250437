import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTiledCardStyles } from '../../constants/styles';
import { ManifoldApp } from '../../utils/interfaces/app';

interface AppCardProps {
  app: ManifoldApp;
  handleDelete: (appId: string) => void | Promise<void>;
  handleEdit: (appId: string) => void;
  handleView: (appId: string) => void;
}

type AppCardAction = 'delete' | 'edit' | 'view';

export const AppCard = ({
  app,
  handleDelete,
  handleEdit,
  handleView,
}: AppCardProps): JSX.Element => {
  const classes = useTiledCardStyles();
  const onAction = (action: AppCardAction) => {
    switch (action) {
      case 'delete':
        handleDelete(app.app);
        return;
      case 'edit':
        handleEdit(app.app);
        return;
      case 'view':
        handleView(app.app);
        return;
    }
  };

  const onError: React.ReactEventHandler<HTMLImageElement> = (
    event?: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    // if it errors, load a default image
    if (event?.currentTarget) {
      event.currentTarget.onerror = null;
      event.currentTarget.src = './logo-black-192x192.png';
    }
  };

  return (
    <Card variant="outlined" className={classes.cardRoot}>
      <CardActionArea onClick={() => onAction('view')}>
        <CardMedia
          component="img"
          alt="app image"
          height="140"
          src={'./logo-black-192x192.png'}
          onError={onError}
        />
        <CardContent>
          <Typography variant="h5" color="text.secondary" gutterBottom>
            {app.appName}
          </Typography>
          <Typography variant="body2">{app.description}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActionsRoot}>
        <IconButton
          size="small"
          aria-label="edit app"
          component="span"
          onClick={() => onAction('edit')}
        >
          <EditOutlinedIcon />
        </IconButton>
        <IconButton
          size="small"
          aria-label="delete app"
          component="span"
          onClick={() => onAction('delete')}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};
