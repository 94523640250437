import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useState } from 'react';

interface NewFeatureProps {
  cognitoSession: CognitoUserSession | null;
}

export const NewFeature = ({ cognitoSession }: NewFeatureProps) => {
  const newFeatureBackend =
    'https://kygy4d4nc3.execute-api.us-east-1.amazonaws.com/prod/app/feature';
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [description, setDescription] = useState('');

  const newFeatureSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const featureDescription = data.get('featureDescription')!.toString();

    await fetch(newFeatureBackend, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${cognitoSession?.getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        description: featureDescription,
      }),
    });

    setDone(true);
    setDescription(featureDescription);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
      }}
      noValidate
      autoComplete="off"
      onSubmit={newFeatureSubmit}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{
          marginBottom: 3,
        }}
      >
        {done ? 'Requested!' : 'Request New Feature'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!done && (
            <TextField
              style={{ width: '100%' }}
              fullWidth
              required
              id="featureDescription"
              name="featureDescription"
              label="Description"
              placeholder="Description"
              multiline
            />
          )}
          {done && (
            <Typography
              component="h1"
              variant="h5"
              sx={{
                marginBottom: 3,
              }}
            >
              Description: {description}
            </Typography>
          )}
        </Grid>
      </Grid>
      {loading && !done && <CircularProgress />}
      {!loading && !done && (
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Request
        </Button>
      )}
      {!loading && done && (
        <Typography
          component="h1"
          variant="h5"
          sx={{
            marginBottom: 3,
          }}
        >
          Created!
        </Typography>
      )}
    </Box>
  );
};
