import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { SIGNUP_REDIRECT } from '../../constants/routing';
import { useQueryParams } from '../../hooks/useQueryParams';
import { Copyright } from '../common/Copyright';

interface ConfirmSignupProps {
  userPool: CognitoUserPool;
  userName: string;
}
const theme = createTheme();

export const ConfirmSignup = ({ userPool, userName }: ConfirmSignupProps) => {
  const navigate = useNavigate();
  const query = useQueryParams();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Prevent submit form from refreshing
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const confirmCode = data.get('confirmCode')!.toString();

    const dataUserName = {
      Username: userName,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(dataUserName);
    cognitoUser.confirmRegistration(confirmCode, true, function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }

      if (result === 'SUCCESS') {
        if (query.has(SIGNUP_REDIRECT)) {
          const queryString = window.location.search.substring(
            0,
            window.location.search.search(SIGNUP_REDIRECT + '=') - 1
          );
          navigate(`${query.get(SIGNUP_REDIRECT)}${queryString}`);
        } else {
          navigate('/');
        }
      }

      // redirect to dashboard
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Confirm Code
          </Typography>
          <Typography component="h6" variant="body1">
            Please check your email for a confirm code from Manifold.
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="cCode"
                  name="confirmCode"
                  required
                  fullWidth
                  id="confirmCode"
                  label="Confirm Code"
                  autoFocus
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Confirm
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};
