import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DeveloperPortalAppBar } from '../components/common/AppBar';
import { DrawerHeader } from '../components/common/DrawerHeader';
import { NavigationDrawer } from '../components/common/NavigationDrawer';
import { useCognito } from '../hooks/useCognito';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  ...(true && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const ROUTES_TO_REQUIRE_AUTH: { [route: string]: boolean } = {
  '/': true,
  '/apps': true,
  '/campaigns': true,
  '/discord': true,
  '/login': false,
  '/merkle': true,
  '/register': false,
  '/reset': false,
  '/settings': true,
  '/snapshot': true,
};

export const DeveloperPortalPage = (): JSX.Element => {
  const { cognitoSession } = useCognito();
  const navigate = useNavigate();
  const location = useLocation();
  // have drawer open on pages that require logged in state
  const [drawerOpen, setDrawerOpen] = useState<boolean>(ROUTES_TO_REQUIRE_AUTH[location.pathname]);

  useEffect(() => {
    function onLoad() {
      if (cognitoSession) {
        if (location.pathname === '/') {
          navigate('/apps', { replace: true });
        }
      } else if (ROUTES_TO_REQUIRE_AUTH[location.pathname]) {
        navigate('/login', {
          replace: true,
          state: {
            from: { hash: location.hash, pathname: location.pathname, search: location.search },
          },
        });
      }
    }
    onLoad();
  }, [cognitoSession, navigate, location]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DeveloperPortalAppBar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <NavigationDrawer open={drawerOpen} setOpen={setDrawerOpen} />
      <Main>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
};
