import { LinearProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ManifoldApp } from '../../utils/interfaces/app';
import { LoadingWrapper } from '../common/LoadingWrapper';
import { AppCard } from './AppCard';
import { EditApp } from './EditApp';
import { NewApp } from './NewApp';
import { NewAppCard } from './NewAppCard';
import { NewFeatureCard } from './NewFeatureCard';

interface YourAppsProps {
  cognitoSession: CognitoUserSession | null;
}

export const YourApps = ({ cognitoSession }: YourAppsProps) => {
  const allAppsBackend = 'https://kygy4d4nc3.execute-api.us-east-1.amazonaws.com/prod/app/all';
  const deleteAppBackend = 'https://kygy4d4nc3.execute-api.us-east-1.amazonaws.com/prod/app';

  const [apps, setApps] = useState<ManifoldApp[]>([]);
  const [loadingApps, setLoadingApps] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const deleteApp = async (appId: string) => {
    await fetch(`${deleteAppBackend}/${appId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${cognitoSession?.getIdToken().getJwtToken()}`,
      },
    });

    const appsRemoved = apps.filter((app: ManifoldApp) => app.app !== appId);
    setApps(appsRemoved);
  };

  const showSettings = async (appId: string) => {
    navigate(`${appId}/edit`);
  };

  const goToDetails = (appId: string) => {
    // TODO
  };

  useEffect(() => {
    let mounted = true;
    const wrapper = async () => {
      // fetch only if pathname matches index
      if (cognitoSession && location.pathname === '/apps') {
        setLoadingApps(true);
        const resp = await fetch(allAppsBackend, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${cognitoSession.getIdToken().getJwtToken()}`,
          },
        });

        const resJson = await resp.json();
        if (mounted) {
          setApps(resJson);
          setLoadingApps(false);
        }
      }
    };
    wrapper();

    return () => {
      mounted = false;
    };
    // also triggers when location changes (to refetch the apps upon re-navigation)
  }, [cognitoSession, location]);

  return (
    <Routes>
      <Route path=":appId/*">
        <Route path="edit" element={<EditApp cognitoSession={cognitoSession} />} />
      </Route>
      <Route path="create" element={<NewApp cognitoSession={cognitoSession} />} />
      <Route
        index
        element={
          <>
            <h3>Your Apps</h3>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
              <Grid item xs={4} my={1.5}>
                <NewAppCard />
              </Grid>
              <Grid item xs={4} my={1.5}>
                <NewFeatureCard />
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 1.5 }} />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
              <LoadingWrapper
                loading={loadingApps}
                progressComponent={
                  <Grid item xs={12}>
                    Loading...
                    <LinearProgress color="inherit" />
                  </Grid>
                }
                minimumLoadMs={500}
              >
                {apps.map((object) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={object.app} my={1.5}>
                      <AppCard
                        app={object}
                        handleEdit={showSettings}
                        handleDelete={deleteApp}
                        handleView={goToDetails}
                      />
                    </Grid>
                  );
                })}
              </LoadingWrapper>
            </Grid>
          </>
        }
      />
    </Routes>
  );
};
