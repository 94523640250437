import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useCampaignAPI } from '../../hooks/useCampaignAPI';
import { useQueryParams } from '../../hooks/useQueryParams';
import { Campaign } from '../../utils/interfaces/campaign';
import { LoadingWrapper } from '../common/LoadingWrapper';
import { CampaignCard } from './CampaignCard';
import { CampaignView } from './CampaignView';
import { NewCampaign } from './NewCampaign';
import { NewCampaignCard } from './NewCampaignCard';

interface AllCampaignsProps {
  cognitoSession: CognitoUserSession | null;
  setShowCampaignView?: Function;
  showCampaignView?: boolean;
}

export const AllCampaigns = ({ cognitoSession }: AllCampaignsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const { campaignAPI } = useCampaignAPI();
  const query = useQueryParams();
  const isAdmin = query.get('admin');

  const [loadingCampaigns, setLoadingCampaigns] = useState(true);

  const deleteCampaign = async (campaignId: number) => {
    try {
      await campaignAPI.deleteCampaign(campaignId);
      alert('Deleted!');
      const resAllJson = await campaignAPI.fetchAllCampaigns();
      setCampaigns(resAllJson);
    } catch (e: any) {
      alert(e.message);
    }
  };

  const viewCampaign = async (campaignId: number) => {
    // since it is a number, needs to be cast as string to navigate
    navigate(`${campaignId}`);
  };

  useEffect(() => {
    let mounted = true;
    const wrapper = async () => {
      // fetch only if pathname matches index
      if (cognitoSession && location.pathname === '/campaigns') {
        setLoadingCampaigns(true);
        let allCampaignsJson: Campaign[];
        if (
          //@ts-ignore
          cognitoSession.accessToken.payload.username === '1a210488-8967-4937-9723-554a85e184e5' &&
          isAdmin
        ) {
          // kevmetz account can view all campaigns if he hits https://developer.manifoldxyz.dev/?admin=true
          allCampaignsJson = await campaignAPI.fetchAllCampaignsAdmin();
        } else {
          allCampaignsJson = await campaignAPI.fetchAllCampaigns();
        }

        if (mounted) {
          setCampaigns(allCampaignsJson);
          setLoadingCampaigns(false);
        }
      }
    };
    wrapper();

    return () => {
      mounted = false;
    };

    // eslint-disable-next-line
  }, [cognitoSession, location]);

  return (
    <Routes>
      <Route path="create" element={<NewCampaign cognitoSession={cognitoSession} />} />
      <Route path=":id" element={<CampaignView cognitoSession={cognitoSession} />} />
      <Route
        index
        element={
          <>
            <h3>Your Campaigns</h3>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
              <Grid item xs={4} my={1.5}>
                <NewCampaignCard />
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 1.5 }} />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <LoadingWrapper
                loading={loadingCampaigns}
                progressComponent={
                  <Grid item xs={12}>
                    Loading...
                    <LinearProgress color="inherit" />
                  </Grid>
                }
                minimumLoadMs={500}
              >
                {campaigns.map((object) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={object.id} my={1.5}>
                      <CampaignCard
                        campaign={object}
                        handleDelete={deleteCampaign}
                        handleView={viewCampaign}
                      />
                    </Grid>
                  );
                })}
              </LoadingWrapper>
            </Grid>
          </>
        }
      />
    </Routes>
  );
};
