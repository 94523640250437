import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { SIGNUP_REDIRECT } from '../../constants/routing';
import { useCognito } from '../../hooks/useCognito';
import { formatUsername } from '../../utils/formatUsername';
import { Copyright } from '../common/Copyright';

const theme = createTheme();

interface SignInProps {
  postSignInCallback?: Function;
  setShowSignIn?: (show: boolean) => void;
}

export const SignIn = ({ postSignInCallback, setShowSignIn }: SignInProps) => {
  const [loginError, setLoginError] = useState(false);
  const { setCognitoSession, setPreferredName, userPool } = useCognito();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email')!.toString();
    const password = data.get('password')!.toString();
    const username = formatUsername(email);

    const authenticationData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const dataUserName = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(dataUserName);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        setCognitoSession(result);
        setShowSignIn?.(false);
        setPreferredName(result.getIdToken().payload.preferred_username);
        if (postSignInCallback) postSignInCallback();
        // User authentication was successful
      },
      onFailure: function (err) {
        if (err.code === 'NotAuthorizedException' || err.code === 'UserNotFoundException') {
          setLoginError(true);
        }

        // User authentication was not successful
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={loginError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={loginError}
            />
            {loginError && (
              <Typography component="h1" variant="h5">
                Incorrect username or password.
              </Typography>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link component={RouterLink} to="/reset" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  component={RouterLink}
                  to={`/register${
                    window.location.search
                      ? window.location.search + `&${SIGNUP_REDIRECT}=` + window.location.pathname
                      : `?${SIGNUP_REDIRECT}=` + window.location.pathname
                  }`}
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};
