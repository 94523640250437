export enum CampaignStatus {
  SCHEDULED = 1,
  ACTIVE = 2,
  COMPLETED = 3,
}

export enum CampaignAction {
  NONE = 0,
  QUESTION = 1,
  SHOPIFY_URL = 2,
  SHOPIFY_DISCOUNT = 3,
  SHOPIFY_DELAYED = 4,
  CODE = 5,
  CODE_WITH_QUESTIONS = 6,
}

export enum CampaignQuestion {
  CUSTOM = 0,
  ADDRESS = 1,
  EMAIL = 2,
  PHONE_NUMBER = 3,
  NAME = 4,
  TWITTER = 5,
  POLL = 6,
  ALLOWLIST = 7,
  PUBLIC_POLL = 8,
}

export const statusToString = (status: CampaignStatus) => {
  switch (status) {
    case CampaignStatus.SCHEDULED:
      return 'Scheduled';
    case CampaignStatus.ACTIVE:
      return 'Active';
    case CampaignStatus.COMPLETED:
      return 'Completed';
    default:
      return 'Unknown';
  }
};

export enum CampaignType {
  ERC721 = 1,
  ERC1155 = 2,
}

export const campaignTypeToNumber = (type: CampaignType) => {
  switch (type) {
    case CampaignType.ERC1155:
      return 2;
    case CampaignType.ERC721:
      return 1;
    default:
      return 0;
  }
};

export const campaignTypeToString = (type: CampaignType) => {
  switch (type) {
    case CampaignType.ERC1155:
      return 'ERC1155';
    case CampaignType.ERC721:
      return 'ERC721';
    default:
      return 'Unknown';
  }
};

export const campaignActionToString = (type: CampaignAction) => {
  switch (type) {
    case CampaignAction.QUESTION:
      return 'Questionnaire';
    case CampaignAction.SHOPIFY_URL:
      return 'Shopify URL';
    case CampaignAction.SHOPIFY_DISCOUNT:
      return 'Shopify Discount';
    case CampaignAction.SHOPIFY_DELAYED:
      return 'Shopify Delayed';
    case CampaignAction.CODE:
      return 'QR Code';
    case CampaignAction.CODE_WITH_QUESTIONS:
      return 'QR Code with Questions';
    default:
      return 'Unknown';
  }
};

export interface Campaign {
  id: number;
  cognitoUserId: string;
  name: string;
  status: CampaignStatus;
  type: CampaignType;
  snapshot: boolean;
  startDate: number | null;
  endDate: number | null;
  snapshotDate?: number;
  snapshotData?: {
    scheduledAt: number | null;
    cutoffBlockNumbers?: { [key: string]: number };
  };
  webhookUrl?: string;
  active?: boolean;
  // tokenAddress?: string,
  action: CampaignAction;
  redemptionCount?: number;
  maxRedemptions?: number;
  redemptionsPerAddress?: number;
  statusString?: ReturnType<typeof statusToString>;
  actionString?: ReturnType<typeof campaignActionToString>;

  tokenData: {
    tokens: TokenWithNoFilter[];
    tokenRanges: TokenFilteredByIdRange[];
    tokenIds: TokenFilteredById[];
    tokenAttributes: TokenFilteredByAttributes[];
  };

  // Optional params dependent on action
  questions?: Question[];
  options?: CodeOption[];
  products?: ShopifyProduct[];
  priceRule?: ShopifyPriceRule;
}

type BaseToken = {
  network: number;
  tokenAddress: string;
};

export interface TokenWithNoFilter extends BaseToken {
  filterType: 'no_filter';
}

export interface TokenFilteredByIdRange extends BaseToken {
  minTokenId: string;
  maxTokenId: string;
  filterType: 'id_range';
}

export interface TokenFilteredById extends BaseToken {
  tokenId: string;
  filterType: 'id';
}

export interface TokenFilteredByAttributes extends BaseToken {
  attributeEntries: { traitType: string; value: string | number }[];
  filterType: 'attributes';
  id?: string;
}

export type Token =
  | TokenWithNoFilter
  | TokenFilteredByIdRange
  | TokenFilteredById
  | TokenFilteredByAttributes;

export type TokenFilterType = Token['filterType'];

interface INFT {
  network: number;
  contractAddress: string;
  tokenId: string;
  ownerAddress?: string;
  name?: string;
  image?: string;
  metadata?: object;
  count: string;
  spec: string;
}

export interface Redemption {
  id: number;
  // campaignId: number,
  network: number;
  ownerAddress: string;
  contractAddress: string;
  tokenId: string;
  // index: number,
  nft?: INFT;
  submissions?: any;
  redeemedAt?: string; // Date.toISOString() format string
  updatedAt?: string; // Date.toISOString() format string
}
export interface Question {
  id: number;
  type: number;
  text: string;
  options: string;
  required: boolean;
  index: number;
}

export interface CodeOption {
  id: number;
  description: string;
  limit?: number;
  redeemed?: number;
}

export interface ShopifyProduct {
  shop: string;
  productId: number;
  discountPercent: number | null;
  discountAmount: number | null;
  limit: number | null;
  total: number | null;
  consumed: number;
  productData: {
    title: string;
    id: number | string;
    handle: string;
    status: string;
    image: {
      width: number;
      height: number;
      src: string;
    };
  };
}

export interface ShopifyPriceRule {
  shop: string;
  priceRuleId: string;
  description: string;
}

export type CampaignShopifyProductRules = {
  [productId in number | string]: CampaignShopifyRule[];
};

export enum CampaignShopifyRuleRestrictionType {
  TOKEN,
  TOKEN_ATTRIBUTE,
  TOKEN_RANGE,
  UNRESTRICTED,
}

interface CampaignShopifyRestrictionBase {
  network: number;
  tokenAddress: string;
}

interface CampaignShopifyTokenAttributeRestriction extends CampaignShopifyRestrictionBase {
  attributeEntries: CampaignShopifyTokenAttributeRestrictionEntry[];
}

interface CampaignShopifyTokenAttributeRestrictionEntry {
  traitType: string;
  value: string;
}

type CampaignShopifyTokenRestriction = CampaignShopifyRestrictionBase;

export interface CampaignShopifyTokenRangeRestriction extends CampaignShopifyRestrictionBase {
  minTokenId: string;
  maxTokenId: string;
}

export type CampaignShopifyRule =
  | CampaignShopifyRule_TOKEN
  | CampaignShopifyRule_TOKEN_RANGE
  | CampaignShopifyRule_TOKEN_ATTRIBUTE
  | CampaignShopifyRule_UNRESTRICTED;

interface CampaignShopifyRule_TOKEN {
  id: string;
  restrictionType: CampaignShopifyRuleRestrictionType.TOKEN;
  restriction: CampaignShopifyTokenRestriction;
  discountPercent: number | undefined;
  discountAmount?: number | undefined;
}

interface CampaignShopifyRule_TOKEN_RANGE {
  id: string;
  restrictionType: CampaignShopifyRuleRestrictionType.TOKEN_RANGE;
  restriction: CampaignShopifyTokenRangeRestriction;
  discountPercent: number | undefined;
  discountAmount?: number | undefined;
}

interface CampaignShopifyRule_TOKEN_ATTRIBUTE {
  id: string;
  restrictionType: CampaignShopifyRuleRestrictionType.TOKEN_ATTRIBUTE;
  restriction: CampaignShopifyTokenAttributeRestriction;
  discountPercent: number | undefined;
  discountAmount?: number | undefined;
}

interface CampaignShopifyRule_UNRESTRICTED {
  id: string;
  restrictionType: CampaignShopifyRuleRestrictionType.UNRESTRICTED;
  restriction: undefined;
  discountPercent: undefined;
  discountAmount?: undefined;
}
