import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';

interface ReadOnlyProductRowProps {
  index: number;
  campaignProduct: any;
  handleEditProductClick: Function;
  handleDeleteProductClick: Function;
  active: boolean;
  currentProductIndex: number | undefined;
  deleteProductInProgress: boolean;
}

export const ReadOnlyProductRow = ({
  campaignProduct,
  index,
  handleEditProductClick,
  handleDeleteProductClick,
  active,
  currentProductIndex,
  deleteProductInProgress,
}: ReadOnlyProductRowProps) => {
  return (
    <TableRow
      key={campaignProduct.productId}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell>
        <img
          style={{ maxWidth: 100, maxHeight: 100 }}
          src={campaignProduct?.productData?.image ? campaignProduct.productData.image.src : ''}
          alt="pr"
        />
      </TableCell>
      <TableCell>{campaignProduct.productData.vendor}</TableCell>
      <TableCell>{campaignProduct.productData.title}</TableCell>
      <TableCell>{campaignProduct.productData.id}</TableCell>
      <TableCell>{campaignProduct.discountPercent}</TableCell>
      <TableCell>{campaignProduct.discountAmount}</TableCell>
      <TableCell>{campaignProduct.limit}</TableCell>
      <TableCell>{campaignProduct.total === null ? 'uncapped' : campaignProduct.total}</TableCell>
      <TableCell>{campaignProduct.consumed}</TableCell>
      <TableCell>
        {deleteProductInProgress && currentProductIndex === index ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <Button onClick={(event) => handleEditProductClick(event, campaignProduct, index)}>
              Edit
            </Button>
            {!active && <Button onClick={() => handleDeleteProductClick(index)}>Delete</Button>}
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};
