import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import { SignIn } from '../login/SignIn';
import { ShopifyEmbedv2 } from './ShopifyEmbed';

const theme = createTheme();

interface ShopifyCompleteProps {
  userPool: CognitoUserPool;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  ...(true && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

// All shopify embed paths
const SHOPIFY_EMBED_PATHS: any = {
  manifoldgate: 'manifold-token-gate',
};

export const ShopifyComplete = ({ userPool }: ShopifyCompleteProps) => {
  const { appName } = useParams<{ appName: string }>();
  const navigate = useNavigate();
  const query = useQueryParams();
  const shop = query.get('shop');

  const isEmbed = window.top !== window.self;
  const shouldEmbed = appName && !!SHOPIFY_EMBED_PATHS[appName];

  // Redirect to shopify app management embeded if we should be embedding but we are not
  let showSignInDefault = true;
  if (shouldEmbed && !isEmbed) {
    window.location.href = `https://${shop}/admin/apps/${SHOPIFY_EMBED_PATHS[appName!]}`;
    showSignInDefault = false;
  }

  const [showSignIn, setShowSignIn] = useState(showSignInDefault);
  const [preferredName, setPreferredName] = useState('');
  const [cognitoSession, setCognitoSession] = useState<CognitoUserSession | null>(null);
  const [showError, setShowError] = useState(false);
  const [linking, setLinking] = useState(true);

  /** whether the current component is mounted */
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    authenticate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  const authenticate = () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      // We have a cognito user, so attempt to complete login
      cognitoUser.getSession(function (err: any, session: any) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        if (session.isValid()) {
          // User is logged in if valid session
          if (mounted) {
            setPreferredName(session.getIdToken().payload.preferred_username);
            setCognitoSession(session);
            setShowSignIn(false);
          }

          // Complete backend sign in
          const baseBackendURL = `https://6g5vuj5b15.execute-api.us-east-1.amazonaws.com/prod/redeem/shopify`;
          const urlToFetch = `${baseBackendURL}/${appName}/complete${
            window.location.search
          }&stage=${query.get('network') || 'mainnet'}`;

          const wrapper = async () => {
            try {
              const resp = await fetch(urlToFetch, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
                },
              });
              if (resp.status !== 200) {
                throw new Error('App sync failed');
              }
              await resp.json();
              if (mounted) {
                setLinking(false);
              }

              if (shouldEmbed && !isEmbed) {
                window.location.href = `https://${shop}/admin/apps/${
                  SHOPIFY_EMBED_PATHS[appName!]
                }`;
              } else if (!isEmbed) {
                navigate('/');
              }
            } catch (error) {
              console.log(error);
              if (mounted) {
                setShowError(true);
              }
            }
          };
          wrapper();
        }

        // NOTE: getSession must be called to authenticate user before calling getUserAttributes
        // CAN BE USED TO GET INFO LIKE TWITTER HANDLE OR SOMETHING - CAMPION
        // cognitoUser.getUserAttributes(function(err, attributes) {
        //   if (err) {
        //     // Handle error
        //   } else {
        //     // Do something with attributes
        //   }
        // });
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Main>
          {showSignIn ? (
            <SignIn postSignInCallback={authenticate} setShowSignIn={setShowSignIn} />
          ) : (
            <>
              {showError ? (
                <Box>Authentication error, please try again</Box>
              ) : (
                <>
                  {linking ? (
                    <Box>Authenticating your shopify account...</Box>
                  ) : (
                    <>
                      {!isEmbed ? (
                        <Box>Shopify authentication complete! Redirecting...</Box>
                      ) : (
                        <ShopifyEmbedv2
                          cognitoSession={cognitoSession}
                          shop={shop!}
                          preferredName={preferredName}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Main>
      </Box>
    </ThemeProvider>
  );
};
