const LEGACY_CASE_SENSITIVE_USERNAMES = {
  'nogod@polisomega.com': 'Nogod@polisomega.com',
  'khoi@famefanatics.com': 'Khoi@famefanatics.com',
  'nef@thesouldojo.com': 'Nef@TheSoulDojo.com',
  'thompsonnft@protonmail.com': 'thompsonNFT@protonmail.com',
  'danny.litin@gmail.com': 'Danny.litin@gmail.com',
  'metaphysicalartinstitute@gmail.com': 'MetaPhysicalArtInstitute@gmail.com',
  'charles.w.forsman@gmail.com': 'Charles.W.Forsman@gmail.com',
  'erjr1212@gmail.com': 'Erjr1212@gmail.com',
  '1.800.vince@gmail.com': '1.800.Vince@gmail.com',
  'mail@geoarbitrage.co': 'mail@Geoarbitrage.co',
  'info@maritimevintage.com': 'info@MaritimeVintage.com',
  'nftviking@cryptodadsnft.com': 'NFTViking@CryptoDadsNFT.com',
  'timpershd@gmail.com': 'TimpersHD@gmail.com',
  'colinmcd22@gmail.com': 'Colinmcd22@gmail.com',
  'natalianmurillo@gmail.com': 'Natalianmurillo@gmail.com',
  'jonathan@dimmak.com': 'Jonathan@dimmak.com',
  'info@marjiene.com': 'INFO@MARJIENE.COM',
  'd.wey820@gmail.com': 'D.WEY820@GMAIL.COM',
  'admin@0xbitcoin.org': 'Admin@0xbitcoin.org',
  'richerdintern@gmail.com': 'richerdIntern@gmail.com',
};

export function formatUsername(username: string): string {
  username = username.toLowerCase();
  if (Object.keys(LEGACY_CASE_SENSITIVE_USERNAMES).includes(username)) {
    username =
      LEGACY_CASE_SENSITIVE_USERNAMES[username as keyof typeof LEGACY_CASE_SENSITIVE_USERNAMES];
  }
  return username;
}
