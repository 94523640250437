import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import setSeconds from 'date-fns/setSeconds';
import React, { Fragment, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Navigate } from 'react-router-dom';
import { getStorefrontsForNetwork } from '@manifoldxyz/shared-storefront-contracts';
import { useCampaignAPI } from '../../hooks/useCampaignAPI';
import { useCognito } from '../../hooks/useCognito';
import { useQueryParams } from '../../hooks/useQueryParams';
import {
  Campaign,
  Token,
  TokenFilteredByAttributes,
  TokenFilteredById,
  TokenFilteredByIdRange,
  TokenWithNoFilter,
} from '../../utils/interfaces/campaign';
import { AttributeMultiSelectDropdown } from '../campaigns/campaignForm/AttributeMultiSelectDropdown';
import { EditableRow } from '../campaigns/campaignForm/EditableRow';
import { NetworkSelectDropdown } from '../campaigns/campaignForm/NetworkSelectDropdown';
import { ReadOnlyRow } from '../campaigns/campaignForm/ReadOnlyRow';

const theme = createTheme();

export const SnapshotGenerator = () => {
  const { cognitoSession } = useCognito();
  const query = useQueryParams();
  const [network, setNetwork] = useState(1);
  const campaignId = '123';

  const [snapshotTime, setSnapshotTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const [whitelistedTokens, setWhitelistedTokens] = useState<any[]>([]);

  const [tokenAddress, setTokenAddress] = useState('');
  const [whichTokens, setWhichTokens] = useState('');
  const [tokens, setTokens] = useState('');
  const [tokenErrorMessage, setTokenErrorMessage] = useState('');
  const [tokenSuccessMessage, setTokenSuccessMessage] = useState('');
  const [createTokenInProgress, setCreateTokenInProgress] = useState(false);
  const [snapshotErrorMessage, setSnapshotErrorMessage] = useState<string[]>([]);

  const [tokenIndividual, setTokenIndividual] = useState<string | undefined>();
  const [minToken, setMinToken] = useState<string | undefined>();
  const [maxToken, setMaxToken] = useState<string | undefined>();

  const [toUpdate, setToUpdate] = useState(-1);

  const [editTokenId, setEditTokenId] = useState<number | null>(null);

  const [showCSVDownload, setShowCSVDownload] = useState(false);
  const [snasphotData, setSnapshotData] = useState<any[]>([]);

  const [nftAttributes, setNFTAttributes] = useState<any[]>();
  const [selectedAttributeMulti, setSelectedAttributeMulti] = useState<string[]>([]);

  const { campaignAPI } = useCampaignAPI();

  if (!cognitoSession) {
    return <Navigate to="/login" />;
  }

  // --- Whitelist Token Handlers
  const handleEditTokenClick = (
    event: React.SyntheticEvent,
    token: Token,
    tokenTableId: number
  ) => {
    event.preventDefault();
    setEditTokenId(tokenTableId);
  };

  const handleDeleteTokenClick = async (tokenTableId: number) => {
    const remainingTokens = [...whitelistedTokens];

    // find the correct token row in the table
    const tokenRowToDelete = remainingTokens[tokenTableId];
    // console.log('++++ tokenRowToDelete: ', tokenRowToDelete)
    remainingTokens.splice(tokenTableId, 1);
    setWhitelistedTokens(remainingTokens);
  };

  const tokenAddressChangeHandler = (event: React.SyntheticEvent) => {
    // @ts-ignore
    const tokenAddress = event.currentTarget.value.toLowerCase().trim();
    const storefrontContractAddresses = getStorefrontsForNetwork(network).map((x) => x.address);
    if (tokenAddress in storefrontContractAddresses) {
      setTokenErrorMessage('Cannot use a shared storefront address');
      setTokenAddress('');
    } else {
      setTokenAddress(tokenAddress);
      if (tokenErrorMessage === 'Cannot use a shared storefront address') setTokenErrorMessage('');
    }
  };

  const whichTokensSelector = async (event: React.SyntheticEvent) => {
    // @ts-ignore
    setWhichTokens(event.currentTarget.value);
    // @ts-ignore
    if (event.currentTarget.value === 'allTokens') {
      setTokens('');
    }

    // @ts-ignore
    if (event.currentTarget.value === 'attributeTokens') {
      const attributes = await campaignAPI.fetchTokenNFTAttributes(network, tokenAddress);
      setNFTAttributes(attributes);
    }
  };

  const snapshotTimeHandler = (event: any) => {
    setSnapshotTime(event.getTime());
  };

  const setIndividualToken = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setTokenIndividual(event.currentTarget.value);
  };

  const setTokenMin = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setMinToken(event.currentTarget.value);
  };

  const setTokenMax = (event: React.SyntheticEvent) => {
    // @ts-ignore
    setMaxToken(event.currentTarget.value);
  };

  const refreshTokenMetadata = async () => {
    // -- Make the request to create the campaign --
    await campaignAPI.refreshTokenMetadata(network, tokenAddress);
    alert('Triggered refresh!');
  };

  const createSnapshot = async (event: any) => {
    event.preventDefault();
    const current = Date.now();
    setLoading(true);

    // Check that if the snapshot date was changed it cannot be in the past.
    if (snapshotTime && parseInt(snapshotTime) < current) {
      setSnapshotErrorMessage(['Error', 'Snapshot Date cannot be in the past.']);
      setLoading(false);
      return;
    }

    if (whitelistedTokens.length === 0) {
      setSnapshotErrorMessage(['Error', 'No tokens added.']);
      setLoading(false);
      return;
    }

    // Must create campaign first...
    try {
      // Create the campaign object to submit..
      const campaignToSubmit: Partial<Campaign> = {
        name: `Snapshot for ${cognitoSession!.getIdToken().payload.preferred_username} at ${
          snapshotTime || new Date(Date.now() + 5 * 60000).getTime()
        }`,
        snapshot: true,
        action: 0,
        snapshotDate: parseInt(snapshotTime || `${new Date(Date.now() + 5 * 60000).getTime()}`),
      };

      const campaign = await campaignAPI.createCampaign(campaignToSubmit);
      // Add tokens now
      const campaignId = campaign.id;

      for await (const whitelistedToken of whitelistedTokens) {
        const tokenAddress = whitelistedToken.tokenAddress;
        if (whitelistedToken.type === 'attribute') {
          const tokenToSubmit: TokenFilteredByAttributes = {
            network: network,
            tokenAddress: tokenAddress,
            filterType: 'attributes',
            attributeEntries: [],
          };

          const attributePairs = whitelistedToken.criteria.split(',');
          // Loop through the attributes in the attribute multi-select.
          for (const currentAttribute of attributePairs) {
            // For each attribute Split the string to remove " :: " and get the
            const currentKeyValuePair = currentAttribute.split(' :: ');
            const currentTraitType = currentKeyValuePair[0];
            const currentTraitValue = currentKeyValuePair[1];
            tokenToSubmit.attributeEntries!.push({
              traitType: currentTraitType,
              value: currentTraitValue,
            });
          }

          // Create the "campaignTokenWithAtttribute" first
          await campaignAPI.addNewTokenFilteredByAttributesToAllowlist(campaignId, tokenToSubmit);
        } else if (whitelistedToken.type === 'id-range') {
          const tokenToSubmit: TokenFilteredByIdRange = {
            network: network,
            tokenAddress,
            filterType: 'id_range',
            minTokenId: minToken!,
            maxTokenId: maxToken!,
          };
          await campaignAPI.addNewTokenToAllowlist(campaignId, tokenToSubmit);
        } else if (whitelistedToken.type === 'id') {
          const tokenToSubmit: TokenFilteredById = {
            network: network,
            tokenAddress,
            filterType: 'id',
            tokenId: whitelistedToken.criteria!,
          };
          await campaignAPI.addNewTokenToAllowlist(campaignId, tokenToSubmit);
        } else if (whitelistedToken.type === 'address') {
          const tokenToSubmit: TokenWithNoFilter = {
            network: network,
            tokenAddress,
            filterType: 'no_filter',
          };
          await campaignAPI.addNewTokenToAllowlist(campaignId, tokenToSubmit);
        } else {
          throw new Error('Unknown filter type');
        }
      }
    } catch (e: any) {
      console.log('Error creating snapshot in dev-portal-fe snapshot tab: ', e);
      setTokenErrorMessage(e.message);
    } finally {
      setLoading(false);
      setDone(true);
    }
  };

  /**
   * Add a whitelisted token to the campaign.
   */
  const addTokenToCampaign = async (
    // event: React.FormEvent<HTMLFormElement>
    event: React.SyntheticEvent
  ) => {
    event.preventDefault();

    if (!tokenAddress) {
      setTokenErrorMessage('Please add a contract address before submitting a token');
      return;
    } else {
      if (!whichTokens) {
        setTokenErrorMessage(
          'Please select a checkbox for how you want to whitelist the contract address'
        );
        return;
      }
      // Cannot use opensea shared contract address.
      const storefrontContractAddresses = getStorefrontsForNetwork(network).map((x) => x.address);
      if (tokenAddress in storefrontContractAddresses) {
        setTokenErrorMessage('Cannot use a shared storefront address. ');
        return;
      }

      // check the contract address against a regex
      if (!tokenAddress.match(/^0x[0-9a-f]{40}$/)) {
        setTokenErrorMessage('Token address is not valid.');
        return;
      }
    }

    const tokenRows = [];

    // Min/max
    if (whichTokens === 'rangeTokens') {
      tokenRows.push({
        tokenAddress: tokenAddress,
        criteria: `${minToken} - ${maxToken}`,
        type: 'id-range',
      });
    } else if (whichTokens === 'selectTokens') {
      // Individual token
      tokenRows.push({
        tokenAddress: tokenAddress,
        criteria: tokenIndividual,
        type: 'id',
      });
    } else if (whichTokens === 'allTokens') {
      tokenRows.push({
        tokenAddress: tokenAddress,
        criteria: 'all',
        type: 'address',
      });
    } else if (whichTokens === 'attributeTokens') {
      // attribute
      let currentCriteria = '';

      for (let i = 0; i < selectedAttributeMulti.length; i++) {
        const attributeEntry = selectedAttributeMulti[i];
        currentCriteria = currentCriteria + attributeEntry; //attributeEntry.traitType + ' - ' + attributeEntry.value
        // add a comma and space for readability except for the last entry
        if (i < selectedAttributeMulti.length - 1) {
          currentCriteria += ', ';
        }
      }
      // console.log('currentCriteria: ', currentCriteria)
      //@ts-ignore
      tokenRows.push({
        tokenAddress: tokenAddress,
        criteria: currentCriteria,
        type: 'attribute',
      });
    }

    setWhitelistedTokens(whitelistedTokens.concat(tokenRows));
    setTokenAddress('');
    setWhichTokens('');
    setTokens('');
    setTokenErrorMessage('');
    setSelectedAttributeMulti([]);
    return;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Grid
          container
          sx={{
            alignItems: 'left',
            width: '100%',
          }}
        >
          <Grid item xs={9}>
            <Typography
              component="h1"
              variant="h5"
              sx={{ marginBottom: 2 }}
              fontWeight="bold"
              fontSize={40}
            >
              Snapshot Generator tool
            </Typography>
          </Grid>
        </Grid>

        <div style={{ display: 'flex' }}>
          {/* TODO: conditionally render snapshot download csv if */}
          {/* {campaign?.snapshot === true && status === "active" ? ( */}
          {showCSVDownload && (
            <>
              <div style={{ display: 'flex' }}>
                <Button
                  // variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  // onClick={getSnapshotData}
                >
                  <CSVLink
                    data={snasphotData}
                    enclosingCharacter={``}
                    filename={'campaign_snapshot_' + campaignId.toString() + '.csv'}
                  >
                    Download Snapshot
                  </CSVLink>
                </Button>
              </div>
            </>
          )}
        </div>

        <br />
        <Grid
          container
          sx={{
            alignItems: 'left',
            width: '100%',
          }}
        >
          <Grid item xs={9}>
            <Typography
              component="h1"
              variant="h6"
              sx={{ marginBottom: 2 }}
              fontWeight="bold"
              fontSize={30}
            >
              Tokens for Snapshot:
            </Typography>
            <form onSubmit={createSnapshot}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Token Contract Address</TableCell>
                      <TableCell>Criteria</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {whitelistedTokens.map((whitelistedToken, index) => (
                      <Fragment>
                        {editTokenId === index ? (
                          <EditableRow index={index} key={index} />
                        ) : (
                          <ReadOnlyRow
                            key={index}
                            whitelistedToken={whitelistedToken}
                            handleEditTokenClick={handleEditTokenClick}
                            handleDeleteTokenClick={handleDeleteTokenClick}
                            index={index}
                            currentTokenIndex={undefined}
                            deleteTokenInProgress={false}
                          />
                        )}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Add New token */}
              <br />
              <div>
                <Typography
                  component="h3"
                  variant="h6"
                  sx={{ marginBottom: 2 }}
                  fontWeight="bold"
                  fontSize={20}
                >
                  Add New Token
                </Typography>
                <div>
                  <NetworkSelectDropdown
                    selectedNetwork={network}
                    setSelectedNetwork={setNetwork}
                  />
                  <TextField
                    // name="contractAddress"
                    //required
                    fullWidth
                    label="Contract Address"
                    value={tokenAddress}
                    autoFocus
                    onChange={tokenAddressChangeHandler}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="allTokens"
                        color="primary"
                        checked={whichTokens === 'allTokens'}
                      />
                    }
                    label="All Tokens"
                    id="allTokens"
                    name="allTokens"
                    onChange={whichTokensSelector}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="selectTokens"
                        color="primary"
                        checked={whichTokens === 'selectTokens'}
                      />
                    }
                    label="Select Individual Token"
                    id="selectTokens"
                    name="selectTokens"
                    onChange={whichTokensSelector}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="rangeTokens"
                        color="primary"
                        checked={whichTokens === 'rangeTokens'}
                      />
                    }
                    label="Token Range"
                    id="rangeTokens"
                    name="rangeTokens"
                    onChange={whichTokensSelector}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="attributeTokens"
                        color="primary"
                        checked={whichTokens === 'attributeTokens'}
                      />
                    }
                    label="Tokens by Attribute"
                    id="attributeTokens"
                    name="attributeTokens"
                    onChange={whichTokensSelector}
                  />
                  {whichTokens === 'selectTokens' && (
                    <TextField
                      name="token id"
                      required
                      fullWidth
                      label="Token ID"
                      autoFocus
                      onChange={setIndividualToken}
                    />
                  )}
                  {whichTokens === 'rangeTokens' && (
                    <>
                      <TextField
                        name="minToken"
                        required
                        fullWidth
                        label="Min Token"
                        autoFocus
                        onChange={setTokenMin}
                      />
                      <TextField
                        name="maxToken"
                        required
                        fullWidth
                        label="Max Token"
                        autoFocus
                        onChange={setTokenMax}
                      />
                    </>
                  )}
                  {whichTokens === 'attributeTokens' && (
                    <>
                      {/* <SelectAttributesGrouping nftAttributes={nftAttributes} /> */}
                      <AttributeMultiSelectDropdown
                        nftAttributes={nftAttributes}
                        selectedAttributeMulti={selectedAttributeMulti}
                        setSelectedAttributeMulti={setSelectedAttributeMulti}
                      />
                      <Typography
                        component="h2"
                        variant="h6"
                        sx={{ marginBottom: 2 }}
                        fontWeight="bold"
                        fontSize={20}
                      >
                        Not seeing attributes you expect? Click here to refresh token attributes
                        (may take a while)
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={refreshTokenMetadata}
                        // type="submit"
                      >
                        Refresh
                      </Button>
                    </>
                  )}
                  <br />
                  <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={addTokenToCampaign}
                    // type="submit"
                  >
                    Add Token
                  </Button>
                  <div>
                    {tokenErrorMessage ? (
                      <div style={{ color: 'red' }}>Failed: {tokenErrorMessage}</div>
                    ) : null}
                  </div>
                  <div>
                    {/* Todo spinning wheel */}
                    {createTokenInProgress ? (
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                      </Box>
                    ) : null}
                    {tokenSuccessMessage ? (
                      <div style={{ color: 'green' }}>{tokenSuccessMessage}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <br />
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Snapshot Date"
                    // @ts-ignore
                    value={setSeconds(snapshotTime || new Date(Date.now() + 5 * 60000), 0)}
                    onChange={snapshotTimeHandler}
                  />
                </LocalizationProvider>
              </div>
              {loading && !done && <CircularProgress />}

              {!loading && !done && (
                <Button variant="contained" sx={{ mt: 3, mb: 2 }} type="submit">
                  Schedule Snapshot
                </Button>
              )}
              {snapshotErrorMessage ? (
                <div style={{ color: 'red' }}>
                  <h1>{snapshotErrorMessage[0]}</h1>
                  <p>{snapshotErrorMessage[1]}</p>
                </div>
              ) : null}
              {!loading && done && (
                <Typography
                  component="h1"
                  variant="h6"
                  sx={{ marginBottom: 2 }}
                  fontWeight="bold"
                  fontSize={30}
                >
                  Snapshot scheduled!
                </Typography>
              )}
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};
