import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export const NewCampaignCard = (): JSX.Element => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/campaigns/create');
  };

  return (
    <Box sx={{ minWidth: 275, maxWidth: 300 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" color="text.secondary" gutterBottom>
            New Campaign
          </Typography>
          <Typography variant="body2">Create a new campaign</Typography>
          <br />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={onClick}>
            Create Campaign
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
