import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { SetStateAction, useState } from 'react';
import { useCognito } from '../../hooks/useCognito';
import { leaveClosedDrawerWidthMixin, NAVIGATION_DRAWER_WIDTH } from './NavigationDrawer';

interface AppBarProps extends MuiAppBarProps {
  drawerOpen?: boolean;
  setDrawerOpen?: (value: SetStateAction<boolean>) => void;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'drawerOpen',
})<AppBarProps>(({ theme, drawerOpen: open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `${NAVIGATION_DRAWER_WIDTH}px`,
    width: `calc(100% - ${NAVIGATION_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && leaveClosedDrawerWidthMixin(theme, true)),
}));

export const DeveloperPortalAppBar = ({
  drawerOpen,
  setDrawerOpen,
  ...props
}: AppBarProps): JSX.Element => {
  const { cognitoSession, preferredName, setCognitoSession, userPool } = useCognito();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.signOut(() => {
        setCognitoSession(null);
      });
    }

    handleMenuClose();
  };

  const handleDrawerClose = () => {
    setDrawerOpen?.(false);
  };

  return (
    <AppBar position="fixed" style={{ background: 'black' }} drawerOpen={drawerOpen} {...props}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="close navigation drawer"
          onClick={handleDrawerClose}
          edge="start"
          sx={{
            marginRight: 5,
            ...(!drawerOpen && { display: 'none' }),
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        {/* hacky way of pushing the menu item to the right */}
        <span style={{ flexGrow: 1 }} />
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menuOpen}
          onClose={handleMenuClose}
          sx={{ justifySelf: 'flex-end' }}
        >
          <MenuItem onClick={logoutUser}>Logout</MenuItem>
        </Menu>
        {cognitoSession && (
          <Button color="inherit" onClick={handleProfileMenuOpen}>
            {preferredName}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
