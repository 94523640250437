import { styled } from '@mui/material/styles';

/**
 * Spacer component equivalent in height to the app bar.
 * Used in the navigation drawer as a header wrapper div. Also used as an empty
 * spacer sibling to page content so that the latter is not hidden beneath the
 * app bar.
 */
export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
