import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NETWORK_ID_TO_NAME: { [key: number]: string } = {
  1: 'mainnet',
  137: 'polygon',
  10: 'optimism',
  8453: 'base',
  11155111: 'sepolia',
};

const NETWORK_NAME_TO_ID: { [key: string]: number } = {
  mainnet: 1,
  polygon: 137,
  optimism: 10,
  base: 8453,
  sepolia: 11155111,
};

export const NetworkSelectDropdown = ({ selectedNetwork, setSelectedNetwork }: any) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setSelectedNetwork(NETWORK_NAME_TO_ID[value]);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="network-select-label">Network</InputLabel>
        <Select
          labelId="network-select-label"
          id="network-select"
          value={NETWORK_ID_TO_NAME[selectedNetwork]}
          onChange={handleChange}
          input={<OutlinedInput id="network-select" label="Network" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <Chip key={selected} label={selected} />
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {(() => {
            const items = [];
            for (const networkName in NETWORK_NAME_TO_ID) {
              items.push(
                <MenuItem key={networkName} value={networkName}>
                  {networkName}
                </MenuItem>
              );
            }
            return items;
          })()}
        </Select>
      </FormControl>
    </div>
  );
};
