import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';

const theme = createTheme();

export const ShopifyAuthenticate = ({}) => {
  const query = useQueryParams();
  const network = query.get('network');
  const { appName } = useParams<{ appName: string }>();
  window.location.href = `https://${
    network ? network : ''
  }redeem.api.manifoldxyz.dev/shopify/${appName}/authenticate${window.location.search}`;

  return <ThemeProvider theme={theme}></ThemeProvider>;
};
