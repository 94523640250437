import { BaseAPI, PostOptions } from './BaseAPI';
import {
  Campaign,
  campaignActionToString,
  CampaignShopifyProductRules,
  CampaignShopifyRule,
  CampaignStatus,
  Redemption,
  ShopifyPriceRule,
  ShopifyProduct,
  statusToString,
  Token,
  TokenFilteredByAttributes,
} from './interfaces/campaign';

const NETWORK_TO_STAGE: { [key: number]: string } = {
  1: 'mainnet',
  137: 'matic',
  10: 'optimism',
  8453: 'base',
  11155111: 'sepolia',
};

export class CampaignAPI extends BaseAPI {
  network: string | null;
  baseUrl = 'https://6g5vuj5b15.execute-api.us-east-1.amazonaws.com/prod';

  constructor(network: string | null, jwtToken?: string) {
    super(jwtToken);
    this.network = network;
  }

  pathToUrl(path: string) {
    return `${this.baseUrl}${path}`;
  }

  mapPropsToCampaign(campaign: Campaign): Campaign {
    return {
      ...campaign,
      status: getCampaignStatus(campaign),
      statusString: getCampaignStatusString(campaign),
      actionString: campaignActionToString(campaign.action),
      tokenData: {
        ...campaign.tokenData,
        tokenAttributes: campaign.tokenData.tokenAttributes.map((token) => ({
          ...token,
          filterType: 'attributes',
        })),
        tokens: campaign.tokenData.tokens.map((token) => ({
          ...token,
          filterType: 'no_filter',
        })),
        tokenIds: campaign.tokenData.tokenIds.map((token) => ({
          ...token,
          filterType: 'id',
        })),
        tokenRanges: campaign.tokenData.tokenRanges.map((token) => ({
          ...token,
          filterType: 'id_range',
        })),
      },
    };
  }

  fetch(path: string, options: RequestInit = {}) {
    return super.fetch(this.pathToUrl(path), options);
  }

  fetchRaw(path: string, options: RequestInit = {}) {
    return super.fetchRaw(this.pathToUrl(path), options);
  }

  put(path: string, options: PostOptions = {}) {
    return super.put(this.pathToUrl(path), options);
  }

  post(path: string, options: PostOptions = {}) {
    return super.post(this.pathToUrl(path), options);
  }

  //@ts-ignore
  delete(path: string, options: RequestInit = {}, value: number) {
    return super.delete(this.pathToUrl(path), options);
  }

  async deleteCampaign(campaignId: number) {
    return this.delete(`/redeem/campaign/${campaignId}`, {}, 1);
  }

  async fetchAllCampaigns(): Promise<Campaign[]> {
    const campaigns = (await this.fetch(`/redeem/campaign/all`)) as Campaign[];

    return campaigns.map((campaign) => this.mapPropsToCampaign(campaign));
  }

  async fetchAllCampaignsAdmin(): Promise<Campaign[]> {
    const campaigns = (await this.fetch(`/redeem/campaign/admin/all`)) as Campaign[];

    return campaigns.map((campaign) => this.mapPropsToCampaign(campaign));
  }

  async createCampaign(campaign: Partial<Campaign>) {
    return await this.post(`/redeem/campaign/new`, { body: campaign });
  }

  async fetchCampaign(campaignId: string | number): Promise<Campaign> {
    const campaign = await this.fetch(`/redeem/campaign/${campaignId}/internal`);
    return this.mapPropsToCampaign(campaign);
  }

  async addNewTokenToAllowlist(campaignId: number, token: Token) {
    if (token.filterType === 'attributes') {
      return this.addNewTokenFilteredByAttributesToAllowlist(campaignId, token);
    }
    const campaign = await this.post(`/redeem/campaign/${campaignId}/token/new`, {
      body: { ...token, filterType: undefined },
    });
    return this.mapPropsToCampaign(campaign);
  }

  async addNewTokenFilteredByAttributesToAllowlist(
    campaignId: number,
    token: TokenFilteredByAttributes
  ) {
    /**
     * Adding a token filtered by attributes requires multiple API requests.
     */
    const { id } = (await this.post(`/redeem/campaign/${campaignId}/token/attribute/new`, {
      body: { network: token.network, tokenAddress: token.tokenAddress },
    })) as { id: string };

    await Promise.all(
      token.attributeEntries.map(async ({ traitType, value }) => {
        return this.post(`/redeem/campaign/${campaignId}/token/attribute/entry/new`, {
          body: {
            tokenWithAttributesId: id,
            traitType,
            value,
          },
        });
      })
    );
  }

  async updateCampaign({
    campaignId,
    redemptionsPerAddress,
    endDate,
    startDate,
  }: {
    endDate?: number | null;
    startDate?: number;
    redemptionsPerAddress?: number;
    campaignId: string | number;
  }): Promise<Campaign> {
    const args: {
      endDate?: number | null;
      startDate?: number;
      redemptionsPerAddress?: number;
    } = {};

    if (endDate !== undefined) {
      args.endDate = endDate;
    }

    if (startDate !== undefined) {
      args.startDate = startDate;
    }

    if (redemptionsPerAddress !== undefined) {
      args.redemptionsPerAddress = redemptionsPerAddress;
    }

    const campaign = await this.put(`/redeem/campaign/${campaignId}`, { body: args });

    return this.mapPropsToCampaign(campaign);
  }

  async fetchTokenNFTAttributes(
    network: number,
    tokenAddress: string
  ): Promise<{ traitType: string; value: string }[]> {
    return this.fetch(`/nft/attributes/${tokenAddress}?stage=${NETWORK_TO_STAGE[network]}`);
  }

  async fetchShopifyShops(): Promise<string[]> {
    return this.fetch(`/redeem/shopify_internal/shops`);
  }

  async fetchShopifyPriceRules(shop: string): Promise<any[]> {
    return this.fetch(`/redeem/shopify_internal/shops/${shop}/priceRules`);
  }

  async fetchQuestions(campaignId: number): Promise<any> {
    return this.fetch(`/redeem/campaign/${campaignId}/questions`);
  }

  async fetchShopifyPriceRule(campaignId: number): Promise<ShopifyPriceRule> {
    return this.fetch(`/redeem/campaign/${campaignId}/shopify/price-rule`);
  }

  async fetchCampaignShopifyProducts(campaignId: number): Promise<ShopifyProduct[]> {
    return this.fetch(`/redeem/campaign/${campaignId}/shopify/products`);
  }

  async fetchShopifyProducts(shop: string): Promise<any[]> {
    return this.fetch(`/redeem/shopify_internal/shops/${shop}/products`);
  }

  async refreshTokenMetadata(network: number, tokenAddress: string) {
    return this.post(`/nft/refresh/${tokenAddress}?stage=${NETWORK_TO_STAGE[network]}`);
  }

  async deleteTokenFromAllowlist(campaignId: string | number, token: Token) {
    if (token.filterType === 'no_filter') {
      return this.delete(
        `/redeem/campaign/${campaignId}/token?network=${token.network}&tokenAddress=${token.tokenAddress}`,
        {},
        2
      );
    }

    if (token.filterType === 'attributes') {
      return this.delete(`/redeem/campaign/${campaignId}/token/attribute/${token.id!}`, {}, 2);
    }

    if (token.filterType === 'id') {
      return this.delete(
        `/redeem/campaign/${campaignId}/token?network=${token.network}&tokenAddress=${token.tokenAddress}&tokenId=${token.tokenId}`,
        {},
        2
      );
    }

    if (token.filterType === 'id_range') {
      return this.delete(
        `/redeem/campaign/${campaignId}/token?network=${token.network}&tokenAddress=${token.tokenAddress}&minTokenId=${token.minTokenId}&maxTokenId=${token.maxTokenId}`,
        {},
        2
      );
    }
  }

  async fetchSnapshot(campaignId: number): Promise<any> {
    return this.fetchRaw(`/redeem/campaign/${campaignId}/snapshot`);
  }

  async addQuestion(campaignId: number, question: any) {
    return this.post(`/redeem/campaign/${campaignId}/question`, { body: question });
  }

  async addCodeOption(campaignId: number, description: string, limit: number | undefined) {
    return this.post(`/redeem/campaign/${campaignId}/code/option`, {
      body: { description, limit },
    });
  }

  async updateCodeOption(
    campaignId: number,
    codeOptionId: number,
    description: string,
    limit: number | undefined,
    active: boolean
  ) {
    return this.put(`/redeem/campaign/${campaignId}/code/option/${codeOptionId}`, {
      body: { description, limit, active },
    });
  }

  async addShopifyPriceRule(campaignId: number, priceRule: Partial<ShopifyPriceRule>) {
    return this.post(`/redeem/campaign/${campaignId}/shopify/price-rule`, { body: priceRule });
  }

  async deleteShopifyPriceRule(campaignId: number) {
    return this.delete(`/redeem/campaign/${campaignId}/shopify/price-rule`, {}, 1);
  }

  async addShopifyProduct(campaignId: number, product: Partial<ShopifyProduct>) {
    return this.post(`/redeem/campaign/${campaignId}/shopify/product`, { body: product });
  }

  async deleteShopifyProduct(campaignId: number, productId: number) {
    return this.delete(`/redeem/campaign/${campaignId}/shopify/product/${productId}`, {}, 1);
  }

  async updateShopifyProduct(
    campaignId: number,
    productId: number,
    product: Partial<ShopifyProduct>
  ) {
    return this.put(`/redeem/campaign/${campaignId}/shopify/product/${productId}`, {
      body: product,
    });
  }

  async fetchCampaignRedemptions(campaignId: number | string): Promise<Redemption[]> {
    return this.fetch(`/redeem/campaign/${campaignId}/redemption/all`);
  }

  async fetchCampaignShopifyRules(
    campaignId: number | string
  ): Promise<CampaignShopifyProductRules> {
    return this.fetch(`/redeem/campaign/${campaignId}/shopify/rules`);
  }

  async deleteCampaignRule(
    campaignId: number | string,
    productId: number | string,
    ruleId: string
  ) {
    return await this.put(`/redeem/campaign/${campaignId}/shopify/product/${productId}`, {
      body: {
        ruleId,
      },
    });
  }

  async addCampaignRule(
    campaignId: number | string,
    productId: number | string,
    rule: Partial<CampaignShopifyRule>
  ) {
    return await this.put(`/redeem/campaign/${campaignId}/shopify/product/${productId}`, {
      body: {
        rule,
      },
    });
  }
}

const getCampaignStatusString = (campaign: Campaign) => {
  const now = Date.now();

  // Inactive campaigns (deleted, etc) consider to be completed.
  if (!campaign.active) {
    return statusToString(CampaignStatus.COMPLETED);
  }

  if (campaign.endDate && campaign.endDate < now) {
    return statusToString(CampaignStatus.COMPLETED);
  } else if (campaign.startDate && campaign.startDate > now) {
    return statusToString(CampaignStatus.SCHEDULED);
  }

  return statusToString(CampaignStatus.ACTIVE);
};

const getCampaignStatus = (campaign: Campaign) => {
  const now = Date.now();

  if (campaign.endDate && campaign.endDate < now) {
    return CampaignStatus.COMPLETED;
  } else if (campaign.startDate && campaign.startDate > now) {
    return CampaignStatus.SCHEDULED;
  }

  return CampaignStatus.ACTIVE;
};
