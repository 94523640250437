import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Copyright } from '../common/Copyright';

interface ConfirmResetProps {
  userPool: CognitoUserPool;
  userName: string;
}
const theme = createTheme();
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const ConfirmReset = ({ userPool, userName }: ConfirmResetProps) => {
  const navigate = useNavigate();
  const [passwordValid, setPasswordValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Prevent submit form from refreshing
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const resetCode = data.get('resetCode')!.toString();
    const newPassword = data.get('newPassword')!.toString();

    const dataUserName = {
      Username: userName,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(dataUserName);
    cognitoUser.confirmPassword(resetCode, newPassword, {
      onSuccess() {
        navigate('/');
      },
      onFailure(err) {
        setErrorMessage(err.message);
      },
    });
  };

  const handleInputValue = (e: any) => {
    const { value } = e.target;

    const valid = value.match(passwordRegex);
    setPasswordValid(valid?.length);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Typography component="h6" variant="body1">
            Please check your email for a reset code from Manifold.
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="rCode"
                  name="resetCode"
                  required
                  fullWidth
                  id="resetCode"
                  label="Reset Code"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  error={!passwordValid}
                  autoComplete="nPass"
                  name="newPassword"
                  required
                  fullWidth
                  id="newPassword"
                  label="New Password"
                  type="password"
                  helperText="Password must be 8+ chars and contain 1 of each: uppercase, lowercase, number, symbol"
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Set Password
            </Button>
            {errorMessage && (
              <Typography component="h1" variant="h5">
                {' '}
                {errorMessage}{' '}
              </Typography>
            )}
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};
