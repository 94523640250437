import { makeStyles } from '@mui/styles';

export const useDashboardCardStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 275,
    maxWidth: 300,
  },
});

export const useTiledCardStyles = makeStyles({
  cardRoot: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 300,
  },
  cardActionsRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
